import {useMutation, useQueryClient} from 'react-query'
import {useRouter} from 'next/router'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import type {Dispatch, SetStateAction} from 'react'
import {Button} from '@/_new-code/products/flexible-web-toolkit/components/button/button'
import LoadingState from '@/components/Form/LoadingState'
import {Modal} from '@/_new-code/components/modal/modal'
import type {CtaButtonContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/cta-button'
import type {ExtendedBlock} from '@/_new-code/services/kontent-ai/types'
import {deleteReminder} from '@/_new-code/products/your-pet-and-you/mutations/mutate-reminders'
import type {Pet} from '@/_new-code/models/consumer-engagement-models'

export type ModuleMedicationReminderDeleteModalContentItem = IContentItem<{
	heading: Elements.TextElement
	description: Elements.RichTextElement
	deleteReminderCta: Elements.LinkedItemsElement<CtaButtonContentItem>
	backToRemindersLink: Elements.TextElement
}>

export const ModuleMedicationReminderDeleteModalBlock: ExtendedBlock<
	ModuleMedicationReminderDeleteModalContentItem,
	{
		setShowDeleteReminderModal: Dispatch<SetStateAction<boolean>>
		selectedPet?: Pet
		remindersIds: string[]
	}
> = ({block, setShowDeleteReminderModal, selectedPet, remindersIds}) => {
	const {heading, description, deleteReminderCta, backToRemindersLink} =
		block.elements

	const router = useRouter()
	const queryClient = useQueryClient()

	const pageLocale = router.locale ?? ''

	const {mutateAsync, isLoading: isSubmittingData} = useMutation({
		mutationFn: (reminderId: string) =>
			deleteReminder(reminderId, pageLocale),
		mutationKey: 'petReminder/delete',
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: ['reminders']})
			void queryClient.invalidateQueries({queryKey: ['pets']})
			void queryClient.invalidateQueries({
				queryKey: ['petData', {id: router.query.id ?? selectedPet?.id}],
			})
			void queryClient.invalidateQueries([
				'remindersData',
				{id: router.query.id ?? selectedPet?.id},
			])
		},
	})

	const handleDeleteReminder = async (): Promise<void> => {
		if (remindersIds.length === 0) return
		await Promise.all(remindersIds.map((id: string) => mutateAsync(id)))

		setShowDeleteReminderModal(false)

		const params = new URLSearchParams(router.asPath.split('?')[1])
		params.delete('reminder')
		params.delete('edit')
		const newRouterPath = router.asPath.split('?')[0]
		void router.replace(
			{
				pathname: `/${pageLocale}${newRouterPath}`,
				query: Object.fromEntries(params),
			},
			undefined,
			{shallow: true}
		)
	}

	return (
		<Modal
			onClose={() => {
				setShowDeleteReminderModal(false)
			}}
			size="md"
		>
			<div className="relative my-5 flex flex-col items-center">
				<LoadingState isLoading={isSubmittingData} theme="green" />
				<div>
					<h2 className="mb-4 text-center font-semibold">
						{heading}
					</h2>
					<div
						dangerouslySetInnerHTML={{__html: description.value}}
					/>
				</div>
				<div className="my-4">
					{deleteReminderCta.length > 0 &&
						deleteReminderCta.map((button) => (
							<Button
								key={button.system.id}
								onClick={handleDeleteReminder}
								variant={
									button.elements.variant[0]?.codename ||
									'pet_primary_green'
								}
							>
								{button.elements.text}
							</Button>
						))}
				</div>
				<div className="cursor-pointer text-pet-green underline">
					<button
						onClick={() => {
							setShowDeleteReminderModal(false)
						}}
						type="button"
					>
						{backToRemindersLink}
					</button>
				</div>
			</div>
		</Modal>
	)
}
