import {Fragment, type JSX} from 'react'
import {clsx} from 'clsx'
import type {TListerFilterProperties} from '@/_new-code/models/global-state.ts'

interface CheckboxData {
	checked: boolean
	codename: string
	value: string
}

interface MultipleCheckboxesProps {
	checkboxesClassName?: string
	checkboxData: CheckboxData[]
	className?: string
	identifier: TListerFilterProperties
	title?: string
	updateFilterValue?: (
		value: string,
		identifier: TListerFilterProperties
	) => void
}

export const MultipleCheckboxes = ({
	checkboxesClassName = '',
	checkboxData,
	className = '',
	identifier,
	title = '',
	updateFilterValue = () => null,
}: MultipleCheckboxesProps): JSX.Element => {
	return (
		<div className={className}>
			{title !== '' && (
				<p className="mb-3 font-bold leading-none text-black">
					{title}
				</p>
			)}
			<div className={checkboxesClassName}>
				{checkboxData.map(({value, checked}, index) => {
					const id = `${value.trim()}_${index}`
					return (
						<Fragment key={id}>
							<input
								checked={checked}
								className="multipleCheckboxesImput"
								id={id}
								onChange={(event) => {
									updateFilterValue(
										event.target.value,
										identifier
									)
								}}
								type="checkbox"
								value={value}
							/>
							<label
								className="multipleCheckboxesLabel"
								htmlFor={id}
							>
								<span
									className={clsx(
										checked && 'border-b border-black'
									)}
								>
									{value}
								</span>
							</label>
						</Fragment>
					)
				})}
			</div>
		</div>
	)
}
