import Image from 'next/image'
import {clsx} from 'clsx'
import Link from 'next/link'
import type {JSX} from 'react'
import {isServer} from '@/utils/helpers'
import type {TersedAsset} from '@/_new-code/services/kontent-ai/types'
import {loadImageFromKontentAI} from '@/imageLoader'
import {Icon} from '../common/icon'

interface CarouselCTAProps {
	title: string
	link: string
	icon: TersedAsset[]
}

export const CarouselCTA = ({
	title,
	icon,
	link,
}: CarouselCTAProps): JSX.Element => {
	const selected = !isServer() && link === window.location.href
	return (
		<Link href={link} legacyBehavior>
			<div
				className={clsx(
					'h-32 w-full border-2 border-solid border-white p-5',
					selected
						? 'bg-elanco-blue hover:bg-blue-400'
						: 'bg-pet-green hover:bg-green-400'
				)}
			>
				<Icon noBackground noBorder noShadow size="sm">
					<>
						{icon[0] ? (
							<Image
								alt={icon[0].description || ''}
								className="h-full"
								height={55}
								loader={loadImageFromKontentAI}
								src={icon[0].url || ''}
								width={55}
							/>
						) : null}
					</>
				</Icon>
				<p className="truncate text-center capitalize text-white">
					{title}
				</p>
			</div>
		</Link>
	)
}
