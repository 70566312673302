import Image from 'next/image'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {Quote} from '@elanco/component-library-v2'
import {loadImageFromKontentAI} from '@/imageLoader'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {getBrandColor} from '../helpers'

export type QuoteContentItem = IContentItem<{
	quote: Elements.TextElement
	quotee: Elements.TextElement
	image: Elements.AssetsElement
	imagePlacement: Elements.MultipleChoiceElement
	imageShape: Elements.MultipleChoiceElement
	borderColor: Elements.MultipleChoiceElement
}>

export const QuoteBlock: Block<QuoteContentItem> = ({block, ...context}) => {
	const pageConfig = context.page.elements.config[0]
	const brandColor = pageConfig ? getBrandColor(pageConfig) : null
	const isDesiredBrandColor =
		brandColor && /seresto|advantix|advantage/.test(brandColor)
	const textColor = isDesiredBrandColor ? 'text-white' : ''
	const showImageOnTop = block.elements.imagePlacement[0]?.codename === 'top'

	return (
		<div data-kontent-element-codename="quote">
			<Quote
				borderColor={block.elements.borderColor[0]?.codename || ''}
				className={textColor}
				image={
					block.elements.image[0] ? (
						<Image
							alt={block.elements.image[0].description || ''}
							className="mx-auto h-44 w-auto rounded-full border-6 border-gray-500"
							height={block.elements.image[0].height || 164}
							loader={loadImageFromKontentAI}
							src={block.elements.image[0].url}
							width={block.elements.image[0].width || 306}
						/>
					) : null
				}
				imageShape={block.elements.imageShape[0]?.codename || 'oval'}
				quote={block.elements.quote}
				quotee={block.elements.quotee}
				showImageOnTop={showImageOnTop}
			/>
		</div>
	)
}
