import {useState, type JSX} from 'react'
import {EllipseButton, Button} from '@elanco/component-library-v2'
import FocusTrap from 'focus-trap-react'

interface ModalProps {
	action: string
	togglePopup: () => void
	searchPlaceholderText?: string
	searchBtnText?: string
	closeBtnText?: string
}

interface SearchTogglePopupProps {
	children: (
		togglePopup: () => void,
		isSearchMOdalOpen: boolean
	) => React.ReactNode
}

const SearchTogglePopup = ({children}: SearchTogglePopupProps): JSX.Element => {
	const [isSearchModalOpen, setIsSearchModalOpen] = useState(false)

	function togglePopup(): void {
		setIsSearchModalOpen(!isSearchModalOpen)
	}

	return <div>{children(togglePopup, isSearchModalOpen)}</div>
}

const Modal = ({
	togglePopup,
	action,
	searchPlaceholderText = 'Search',
	searchBtnText = 'Search',
	closeBtnText = 'Close',
}: ModalProps): JSX.Element => {
	return (
		<FocusTrap>
			{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events -- Not needed here */}
			<div
				className="transition-out fixed flex h-full w-full items-center bg-blue-transparent p-4"
				onClick={togglePopup}
				style={{zIndex: 100}}
			>
				<form action={action} className="mx-auto w-full max-w-md">
					{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events -- Not needed here */}
					<div
						className="relative"
						onClick={(e) => {
							e.stopPropagation()
						}}
					>
						<label htmlFor="search">
							<span className="visuallyhidden">
								{searchPlaceholderText}
							</span>
							<input
								className="mb-0 pr-16 text-black"
								id="search"
								name="q"
								placeholder={searchPlaceholderText}
								type="text"
							/>
						</label>
						<EllipseButton
							as="button"
							className="absolute inset-y-0 right-0 my-auto mr-2"
							icon={
								<svg
									height="14.14"
									viewBox="0 0 14.137 14.14"
									width="14.137"
								>
									<path d="M13.945,12.225,11.192,9.472a.662.662,0,0,0-.469-.193h-.45a5.741,5.741,0,1,0-.994.994v.45a.662.662,0,0,0,.193.469l2.753,2.753a.66.66,0,0,0,.936,0l.781-.781A.666.666,0,0,0,13.945,12.225Zm-8.2-2.946A3.535,3.535,0,1,1,9.278,5.744,3.533,3.533,0,0,1,5.744,9.278Z" />
								</svg>
							}
							iconSize="w-6 h-6"
							type="submit"
						>
							{searchBtnText}
						</EllipseButton>
					</div>
				</form>
				<Button
					as="button"
					className="absolute right-0 top-0 z-10 mr-4 mt-4 bg-blue-800 text-white hover:bg-blue-900"
					icon={
						<svg
							height="21.5"
							viewBox="0 0 21.499 21.5"
							width="21.499"
						>
							<path
								d="M-45.484,39.457l-1.973-1.973a1,1,0,0,1,0-1.415l7.07-7.07-7.069-7.07a1,1,0,0,1,0-1.414l1.973-1.973a1,1,0,0,1,1.414,0L-37,25.612l7.069-7.069a1,1,0,0,1,1.414,0l1.973,1.973a1,1,0,0,1,0,1.414L-33.613,29l7.07,7.07a1,1,0,0,1,0,1.414l-1.973,1.973a1,1,0,0,1-1.414,0L-37,32.386l-7.07,7.071a1,1,0,0,1-.707.293A1,1,0,0,1-45.484,39.457Z"
								transform="translate(47.75 -18.25)"
							/>
						</svg>
					}
					onClick={togglePopup}
					reversed
					variant=""
				>
					{closeBtnText}
				</Button>
			</div>
		</FocusTrap>
	)
}

SearchTogglePopup.Modal = Modal
export {SearchTogglePopup}
