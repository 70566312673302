import type {Tersed} from '@/_new-code/services/kontent-ai/types'
import type {CurrentUserContext} from '@/_new-code/models/current-user-context'
import type {
	NavItemLinkContentItem,
	AuthNavItemLinkContentItem,
} from '../models'

export const filterLinksByAppPermissionsAndRoles = (
	arr: Tersed<NavItemLinkContentItem | AuthNavItemLinkContentItem>[],
	authState: CurrentUserContext
): Tersed<NavItemLinkContentItem | AuthNavItemLinkContentItem>[] =>
	arr
		.filter((item) => {
			const hasPermission = authState.checkAppPermission(item)
			const hasRole = authState.checkUserRole(item)
			return hasPermission || hasRole
		})
		.map((item) => ({
			...item,
			elements: {
				...item.elements,
				sublinks:
					item.elements.sublinks.length > 0
						? filterLinksByAppPermissionsAndRoles(
								item.elements.sublinks,
								authState
							)
						: [],
			},
		}))
