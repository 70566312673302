import type {Reminder} from '@/_new-code/services/reminders/models'
import http from '@/utils/axios'

export async function deleteReminder(
	reminderId: string,
	locale: string
): Promise<Response> {
	return http(`/api/reminders/${reminderId}?locale=${locale}`, {
		method: 'DELETE',
	})
}

export async function editReminder(
	{id, petId, productId, startDate, dosesRemaining, channel}: Reminder,
	locale: string
): Promise<Response> {
	return http(`/api/reminders/${id}?locale=${locale}`, {
		method: 'PUT',
		data: {
			reminder: {
				productId,
				petId,
				channel,
				startDate,
				dosesRemaining,
			},
		},
	})
}

export const createReminder = async (
	{petId, productId, startDate, dosesRemaining, channel}: Reminder,
	locale: string
): Promise<Response> => {
	return http(`/api/reminders?locale=${locale}`, {
		method: 'POST',
		data: {
			reminder: {
				petId,
				productId,
				channel,
				startDate,
				dosesRemaining,
			},
		},
	})
}
