import Image from 'next/image'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {spacingPadding} from '@/_new-code/products/flexible-web-toolkit/styles'
import {loadImageFromKontentAI} from '@/imageLoader'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {ImageGallery as ComponentImageGallery} from '@/_new-code/products/flexible-web-toolkit/components/image-gallery/image-gallery'

export type ImageGalleryContentItem = IContentItem<{
	images: Elements.AssetsElement
	snippetSpacingSpacing: Elements.MultipleChoiceElement
}>

export const ImageGalleryBlock: Block<ImageGalleryContentItem> = ({block}) => {
	const componentSpacing =
		spacingPadding[
			(block.elements.snippetSpacingSpacing[0]?.codename ??
				'none') as keyof typeof spacingPadding
		]

	const images = block.elements.images.filter((_, index: number) => index < 5)

	return (
		<div className={componentSpacing}>
			<ComponentImageGallery>
				{images.map((image) => (
					<Image
						alt={image.description ?? ''}
						className="thumbnail object-contain"
						height={image.height || 80}
						key={image.url}
						loader={loadImageFromKontentAI}
						role="presentation"
						src={image.url}
						width={image.width || 140}
					/>
				))}
			</ComponentImageGallery>
		</div>
	)
}
