import {addRecurring} from '@/utils/YPAY/commonFunctions'
import http from '@/utils/axios'
import type {Reminder} from '@/_new-code/services/reminders/models'

export const fetchPetReminders = async (
	locale: string
): Promise<Reminder[]> => {
	const endpoint = `/api/reminders?locale=${locale}`
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- needed
	const {data} = await http(endpoint)
	// eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- needed
	const remindersData = addRecurring(data, 12, 1)

	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- needed
	return remindersData?.data
}
