import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {Bullet} from '@/_new-code/products/flexible-web-toolkit/components/bullet'
import {RTE as RichTextElement} from '@/_new-code/products/flexible-web-toolkit/components/rte'
import {LargeVisualBlock} from '../../large-visual-block/large-visual-block'
import type {BulletContentItem} from '../types'

export type ContentModuleWithBulletsContentItem = IContentItem<{
	title: Elements.TextElement
	description: Elements.TextElement
	bulletTitle: Elements.TextElement
	bullets: Elements.LinkedItemsElement<BulletContentItem>
	smallImage: Elements.AssetsElement
	largeImage: Elements.AssetsElement
	secondTitle: Elements.TextElement
	secondDescription: Elements.TextElement
	thirdTitle: Elements.TextElement
	thirdDescription: Elements.TextElement
	tabTitle: Elements.TextElement
}>

export const ContentModuleWithBulletsBlock: Block<
	ContentModuleWithBulletsContentItem
> = ({
	block: {
		elements: {
			title,
			description,
			bulletTitle,
			bullets,
			smallImage,
			largeImage,
			secondTitle,
			secondDescription,
			thirdTitle,
			thirdDescription,
		},
	},
}) => {
	return (
		<div className="container-wide">
			<RichTextElement className="mb-6">
				<h2>{title}</h2>
				<p>{description}</p>
			</RichTextElement>
			<div className="bg-gray-200 p-6 md:p-8">
				<div className="container-narrow md:flex">
					<div className="pb-4 md:w-1/2 md:pb-0">
						<RichTextElement>
							<h3>{bulletTitle}</h3>
							<Bullet>
								{bullets.map(
									({elements: {item}, system: {id}}) => (
										<li key={`${id}-${item}`}>{item}</li>
									)
								)}
							</Bullet>
						</RichTextElement>
					</div>
					<div className="md:w-1/2 md:pl-8">
						<LargeVisualBlock
							image={{
								src: smallImage[0]?.url,
								alt: smallImage[0]?.description || '',
							}}
							largeImageUrl={largeImage[0]?.url || ''}
						/>
					</div>
				</div>
			</div>
			<RichTextElement className="mt-6">
				<h3>{secondTitle}</h3>
				<p>{secondDescription}</p>
			</RichTextElement>
			<RichTextElement className="mt-6">
				<h3>{thirdTitle}</h3>
				<p>{thirdDescription}</p>
			</RichTextElement>
		</div>
	)
}
