import React, {type JSX} from 'react'

interface HtmlTextWithStylingProps {
	as?: keyof JSX.IntrinsicElements
	text?: string
	className?: string
}

export const HtmlTextWithStyling = ({
	as: Element = 'div',
	text = '',
	className = '',
}: HtmlTextWithStylingProps): JSX.Element => (
	<Element
		className={`[&>ol]:list-inside [&>ol]:list-decimal [&>ul]:list-inside [&>ul]:list-disc ${className}`}
		dangerouslySetInnerHTML={{__html: text}}
	/>
)
