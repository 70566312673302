import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {clsx} from 'clsx'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import type {CdcGigyaContentItem} from '@/_new-code/products/auth/cdc-screensets/basic'
import {
	ContentBlockTab,
	TabbedContentBlock,
} from '@/_new-code/products/flexible-web-toolkit/components/tabbed-content-block/tabbed-content-block'
import type {RichTextContentItem} from '../rich-text'
import type {VideoBlockContentItem} from '../videos/video-block'
import type {ContentModuleWithBulletsContentItem} from '../with-bullets/content-module-with-bullets'
import type {StepsContentItem} from '../steps'
import type {RichTextWithCTAContentItem} from '../rich-text-with-cta'
import type {ComparisonTableContentItem} from '../comparison-table'
import type {GovernanceBlockContentItem} from '../governance-block'
import type {WistiaContentItem} from '../videos/wistia'
import {spacing} from '../../styles'

export type TabbedBlockContentItem = IContentItem<{
	tabbedComponents: Elements.LinkedItemsElement<
		| RichTextContentItem
		| VideoBlockContentItem
		| ContentModuleWithBulletsContentItem
		| StepsContentItem
		| RichTextWithCTAContentItem
		| ComparisonTableContentItem
		| GovernanceBlockContentItem
		| CdcGigyaContentItem
		// TODO: Re-permit these blocks once they exist
		// | CdcScreenSetWrapperContentItem
		// | TableBlockWithIconsContentItem
		| WistiaContentItem
	>
	snippetSpacingSpacing: Elements.MultipleChoiceElement
}>

export const TabbedBlockBlock: Block<TabbedBlockContentItem> = ({
	block,
	...context
}) => {
	// Get the Tailwind CSS classes for the components's spacing (or default).
	const componentSpacing =
		spacing[
			(block.elements.snippetSpacingSpacing[0]?.codename ??
				'default') as keyof typeof spacing
		]

	return (
		<div className={clsx(componentSpacing, `rounded-lg shadow-md`)}>
			<TabbedContentBlock>
				{block.elements.tabbedComponents.map((tabbedComponent) => (
					<ContentBlockTab
						data-kontent-item-id={tabbedComponent.system.id}
						key={tabbedComponent.system.id}
						title={tabbedComponent.elements.tabTitle}
					>
						{context.BlockMapper({
							blocks: [tabbedComponent],
							globalConfig: context.globalConfig,
							page: context.page,
						})}
					</ContentBlockTab>
				))}
			</TabbedContentBlock>
		</div>
	)
}
