import {setupBusinessSearch, validateBusiness} from './business-search'
import {
	confirmEmailFieldsMatch,
	setupEmailConfirmation,
} from './email-confirmation'

export class CDCScreenSetValidator {
	emailMatch = false

	businessIsValid = false

	setup(locale: string, noResultsText?: string): void {
		setupEmailConfirmation()
		setupBusinessSearch(locale, noResultsText)
	}

	isValid(enableBusinessValidation = true): boolean {
		return (
			this.emailMatch &&
			(!enableBusinessValidation || this.businessIsValid)
		)
	}

	validate(): void {
		this.emailMatch = confirmEmailFieldsMatch()
		this.businessIsValid = validateBusiness()
	}
}
