import type {MouseEvent, ReactElement, PropsWithChildren, JSX} from 'react'
import {cloneElement, isValidElement} from 'react'
import {getDataAttributes} from './utils'

type Target = '_blank' | '_self' | '_parent' | '_top' | ''
type Variant = 'primary' | 'secondary'

interface TextButtonProps {
	as?: 'a' | 'button'
	children?:
		| string
		| ReactElement<
				PropsWithChildren<{
					className?: string
					dangerouslySetInnerHTML?: {__html: string}
				}>
		  >
		| ReactElement<
				PropsWithChildren<{
					className?: string
					dangerouslySetInnerHTML?: {__html: string}
				}>
		  >[]
	className?: string
	icon?:
		| ReactElement<{className?: string}>
		| {class: string; icon: ReactElement<{className?: string}>}
		| null
	href?: string
	target?: Target
	variant?: Variant
	noIconAnimation?: boolean
	onClick?: (
		event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>
	) => Promise<void> | void
	type?: 'button'
	disabled?: boolean
	title?: string
	kontentProps?: object
	routerNavigateToPath?: (
		url: string // push to new route
	) => Promise<boolean>
}

export const TextButton = (props: TextButtonProps): JSX.Element => {
	const {
		as: Tag = 'a',
		children,
		className = '',
		href,
		target,
		variant = 'primary',
		noIconAnimation = false,
		onClick,
		disabled = false,
		type = 'button',
		title = '',
	} = props
	let {icon} = props
	const dataProps = getDataAttributes(
		props as unknown as Record<string, unknown>
	)
	let newIcon
	let iconAnimClass = ''
	if (icon && typeof icon === 'object' && 'icon' in icon) {
		iconAnimClass = noIconAnimation ? '' : `btn-${icon.class}`
		icon = icon.icon
		newIcon = cloneElement(icon, {
			className: `${
				icon.props.className || ''
			} text-theme-highlight inline-block mr-2 fill-current overflow-visible`,
		})
	}

	const classes: Record<Variant, string> = {
		primary: 'text-primary hover:text-primary-dark',
		secondary: 'text-blue-900 hover:text-blue-800',
	}

	let tagAttributes: {
		href?: string
		target?: Target
		type?: 'button'
		disabled?: boolean
	} = {
		href,
	}

	if (target) {
		tagAttributes.target = target
	}

	if (Tag === 'button') {
		tagAttributes = {type, disabled}
	}

	// if data-btn-id is not set on each button, using the hyphenated button text as data-btn-id for tracking purpose
	if (
		children &&
		(dataProps['data-btn-id'] === undefined ||
			dataProps['data-btn-id'] === '')
	) {
		let dataBtnIdFromText = ''

		if (Array.isArray(children)) {
			// children is an array of text and/or elements, e.g., "lorem <span>ipsum</span>"
			let tempText = ''
			tempText = children
				.map((c) =>
					typeof c === 'string' ? c : c.props.children || ''
				)
				.join('')
			dataBtnIdFromText = tempText.replace(/,/g, '-')
		} else if (isValidElement(children)) {
			const childText = children.props.children
			dataBtnIdFromText = typeof childText === 'string' ? childText : ''
		} else if (typeof children === 'string') {
			// children is plain text
			dataBtnIdFromText = children
		}

		// Replace spaces with hyphens to create the final `data-btn-id`
		dataProps['data-btn-id'] = dataBtnIdFromText.split(' ').join('-')
	}

	return (
		<Tag
			// style={{ color: 'red' }}
			{...tagAttributes}
			{...dataProps}
			aria-label={title}
			className={`text-btn ${iconAnimClass} ${classes[variant]} cursor-pointer text-xs font-bold italic underline hover:no-underline focus:text-secondary focus:no-underline focus:outline-none ${className}`}
			onClick={onClick}
			title={title}
		>
			{newIcon}
			{children}
		</Tag>
	)
}
