/* eslint-disable @typescript-eslint/explicit-function-return-type -- Dumbest Option Ever! */
import type {
	TListerFilterProperties,
	TListFilters,
	ListerFilterMultipleCheckboxes,
} from '@/_new-code/models/global-state.ts'
import {env} from '@/utils/env/client.mjs'
import type {Tersed} from '@/_new-code/services/kontent-ai/types'
import type {FilterBarOptionContentItem, TaxonomyContentItemKeys} from '.'

export function filterBuilder(
	options: TListFilters,
	filterBarOption: Tersed<FilterBarOptionContentItem>,
	path: string
) {
	const filterBarOptionType =
		filterBarOption.elements.type[0]?.codename ?? 'dropdown'
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- allowed
	const filterBarOptionTaxonomy = filterBarOption.elements.taxonomy[0]!
	const filterBarOptionTaxonomyRawKey =
		filterBarOptionTaxonomy.system.type.replace(
			'taxonomy_',
			''
		) as TListerFilterProperties
	const filterBarOptionTaxonomyKey = filterBarOptionTaxonomyRawKey
		.toLowerCase()
		.replace(
			/(?:_\w)/g,
			(match) => match[1]?.toUpperCase() ?? ''
		) as TaxonomyContentItemKeys

	const urlSearchParams = new URL(`${env.NEXT_PUBLIC_APP_URL}/${path}`)
		.searchParams

	const query = urlSearchParams.has(filterBarOptionTaxonomyRawKey)
		? urlSearchParams.getAll(filterBarOptionTaxonomyRawKey)
		: undefined

	if (filterBarOptionType === 'dropdown') {
		return {
			...options,
			[filterBarOptionTaxonomyRawKey]: {
				label: filterBarOption.elements.allItemsTitle,
				value: query ? query.toString().toLowerCase() : '',
			},
		}
	}

	if (filterBarOptionType === 'multiselect') {
		const queryString = query?.toString().toLowerCase()
		const checkBoxItems = filterBarOptionTaxonomy.elements[
			filterBarOptionTaxonomyKey
		].map((terms) => ({
			value: terms.name,
			codename: terms.codename,
			checked: query
				? queryString?.includes(terms.name.toLowerCase())
				: false,
		}))

		const optionsKey = options[filterBarOptionTaxonomyRawKey]
		return {
			...options,
			[filterBarOptionTaxonomyRawKey]: {
				checkboxOptions:
					optionsKey &&
					'checkboxOptions' in optionsKey &&
					optionsKey.checkboxOptions.length > 0
						? [
								...(
									options[
										filterBarOptionTaxonomyRawKey
									] as ListerFilterMultipleCheckboxes
								).checkboxOptions,
								...checkBoxItems,
							]
						: checkBoxItems,
			},
		}
	}

	return undefined as never
}
