import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {useRouter} from 'next/router'
import {Breadcrumbs as ClBreadcrumbs} from '@/_new-code/products/flexible-web-toolkit/components/breadcrumbs'
import {spacing} from '@/_new-code/products/flexible-web-toolkit/styles'
import type {Block} from '@/_new-code/services/kontent-ai/types'

export type BreadcrumbsContentItem = IContentItem<{
	snippetSpacingSpacing: Elements.MultipleChoiceElement
	homeBreadcrumbs: Elements.TextElement
}>

export const BreadcrumbsBlock: Block<BreadcrumbsContentItem> = ({block}) => {
	// Split path (e.g. dog/product-directory/foo) into array of parts (e.g. ['', '/dog', '/product-directory', '/foo'])
	const {asPath, locale: pageLocale} = useRouter()
	const splitPath = asPath.split('?')[0]?.split('/') ?? []
	const componentSpacing =
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- condition is necessary, block.elements.snippetSpacingSpacing may be null
		spacing[
			block.elements.snippetSpacingSpacing[0]
				?.codename as keyof typeof spacing
		] || spacing.none
	const locale = pageLocale ? `/${pageLocale}` : ''
	// For each split path, generate appropriate breadcrumb
	const links = splitPath.flatMap((val, i, arr) => {
		// href is the concatenated string of all parts of the URL before and including the current index
		// arr is always equal to the input array, for example: ['', 'dog', 'product-directory', 'foo']
		// For example, on first iteration:
		// i = 0; So, href = '' which is falsy and so href defaults to '/'
		// On second iteration:
		// i = 1; So, href = '' + '/' + 'dog' = '/dog'.
		// On third iteration:
		// i = 2; So, href = '' + '/' + 'dog' + '/' + 'product-directory' = '/dog/product-directory'
		// (and so forth)
		const href = arr.slice(0, i + 1).join('/') || '/'

		// eslint-disable-next-line prefer-named-capture-group -- I don't even know what this regex does
		if (i === 1 && href.match(/^\/([\w]{2})-([\w]{2})/g)) {
			return []
		}

		// title takes a string value (e.g. "dog") and ensures it has a capital letter (e.g. "Dog"); if href is '/', assume the title is "Home" instead
		const homeName =
			block.elements.homeBreadcrumbs === ''
				? 'Home'
				: block.elements.homeBreadcrumbs
		const title =
			href === '/'
				? homeName
				: val.charAt(0).toUpperCase() +
					val.slice(1).replace('.html', '').replace('-', ' ')
		return (
			<a href={`${locale}${href}`} key={href}>
				{title}
			</a>
		)
	})

	return (
		<div data-kontent-element-codename="home_breadcrumbs">
			<div className="container-wide">
				<ClBreadcrumbs className={componentSpacing}>
					{links}
				</ClBreadcrumbs>
			</div>
		</div>
	)
}
