import type {QueryFunctionContext} from 'react-query'
import type {
	Pet,
	PetResponse,
} from '@/_new-code/models/consumer-engagement-models'
import http from '@/utils/axios'
import type {
	SinglePetQueryKey,
	PetFormData,
} from '@/_new-code/services/pet-profiles/models'

export const fetchPetProfiles = async (locale: string): Promise<Pet[]> => {
	const endpoint = `/api/petProfiles?locale=${locale}`
	const {data: response} = await http<PetResponse>(endpoint)

	return response.data
}

export const fetchSinglePetProfile = async (
	context: QueryFunctionContext<SinglePetQueryKey>,
	locale: string
): Promise<Pet> => {
	const [, params] = context.queryKey
	const endpoint = `/api/pets/${params.id}?locale=${locale}`
	const {data} = await http<Pet>(endpoint)

	return data
}

export const createPetProfile = async (
	petData: PetFormData,
	locale: string
): Promise<Pet> => {
	const {data} = await http<Pet>(`/api/petProfiles?locale=${locale}`, {
		method: 'POST',
		data: {
			name: petData.petName,
			gender: petData.petSex,
			breed: petData.breed,
			species: petData.petSpecies,
			birthDate: petData.petDateOfBirth,
			weight: Number(petData.petWeight),
			acquisitionSource: petData.petAcquisitionType,
			acquisitionDate: petData.petAcquisitionDate,
			petShelterId: petData.petShelter,
			petShelterName: petData.shelterName,
			vetClinicId: petData.vetClinic,
			vetClinicName: petData.clinicName,
			imageReference: petData.imageReference,
		},
	})
	return data
}
