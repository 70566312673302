import type {PropsWithChildren, ReactElement, ReactNode} from 'react'
import {
	cloneElement,
	isValidElement,
	useRef,
	useState,
	createElement,
	Children,
} from 'react'
import Image from 'next/image'
import {ScrollBlock, Faq, FaqItem} from '@elanco/component-library-v2'
import {clsx} from 'clsx'
import {isServer, getBrowser} from '@/utils/helpers'
import {ButtonIcons} from '@/_new-code/components/button-icons/button-icon'
import {loadImageFromKontentAI} from '@/imageLoader'
import {EllipseButton} from './button/ellipse-button'
import type {YoutubeVideoRef} from './you-tube-video'
import {YoutubeVideo} from './you-tube-video'
import {Button} from './button/button'

interface ChildElement {
	className?: string
	key?: string | number
}

interface VideoTranscriptObj {
	children: ReactNode | ReactElement<ChildElement>[]
	title: string
}

interface PosterImage {
	alignment: string
	children?: ReactNode | ReactElement<ChildElement>[]
	posterFrameImageUrl: string
	posterFrameImageAltText: string | null
}

const hasObjectFit = isServer() || 'objectFit' in document.documentElement.style

const PosterImage = ({
	children = [],
	alignment = 'center',
	posterFrameImageUrl = '',
	posterFrameImageAltText = '',
}: PosterImage): ReactElement | null => {
	const alignments: Record<string, string> = {
		top: 'object-top',
		center: 'object-center',
		bottom: 'object-bottom',
	}

	if (children && Array.isArray(children) && children.length > 0) {
		return (
			<picture className="block h-64 w-full sm:h-80 md:absolute md:h-full xs:h-72">
				{Array.isArray(children) &&
					children.map((child: ReactElement<ChildElement>) => {
						return isValidElement<ReactElement<ChildElement>>(
							child
						) && child.type !== 'img'
							? child
							: cloneElement<ChildElement>(child, {
									className: clsx(
										'object-cover',
										alignments[alignment],
										'w-full',
										'h-full'
									),
								})
					})}
			</picture>
		)
	}
	if (!posterFrameImageUrl) return null
	return (
		<picture className="relative block h-64 w-full sm:h-80 md:absolute md:h-full xs:h-72">
			<Image
				alt={posterFrameImageAltText ?? ''}
				className={clsx('object-cover', alignments[alignment])}
				fill
				loader={loadImageFromKontentAI}
				src={posterFrameImageUrl}
			/>
		</picture>
	)
}

const VideoTranscript = ({
	children = null,
	title = '',
}: VideoTranscriptObj): ReactElement => {
	return (
		<div className="container-narrow mt-4">
			<div className="mt-6">
				<Faq className="overflow-hidden rounded-lg shadow-secondary">
					<FaqItem className="bg-white pt-5" title={title}>
						{children}
					</FaqItem>
				</Faq>
			</div>
		</div>
	)
}

interface FullScreenVideoProps {
	backgroundColor: string
	children: ReactNode | ReactElement<PropsWithChildren>[]
	videoId: string
	posterFrameImageUrl: string
	posterFrameAlignment?: string
	posterFrameImageAltText: string | null
	id?: string
	style?: {
		backgroundColor: string
	}
	controls?: number
	videoTitle?: string
	className?: string
	closeBtnText?: string
	playBtnText?: string
	thumbnailImage?: boolean
}

export const FullscreenVideo = ({
	backgroundColor = '',
	children = [],
	videoId = '',
	posterFrameAlignment = 'center',
	posterFrameImageAltText = '',
	posterFrameImageUrl = '',
	id = '',
	style = {
		backgroundColor: '',
	},
	controls = 0,
	videoTitle = '',
	className = '',
	closeBtnText = 'Close',
	playBtnText = 'Play',
	thumbnailImage = false,
}: FullScreenVideoProps): ReactElement => {
	const alignments: Record<string, string> = {
		top: 'bg-top',
		center: 'bg-center',
		bottom: 'bg-bottom',
	}
	const alignment = alignments[posterFrameAlignment]
	const youtubeVideoRef = useRef<YoutubeVideoRef | null>(null)

	const [showVideoModal, setShowVideoModal] = useState(false)
	const [hidePosterFrame, setHidePosterFrame] = useState(false)

	const playButtonClickHandler = (): void => {
		setShowVideoModal(true)
		setHidePosterFrame(true)
		youtubeVideoRef.current?.playVideo()
	}

	const videoModalClickHandler = (): void => {
		setShowVideoModal(false)
		setHidePosterFrame(false)
		youtubeVideoRef.current?.pauseVideo()
	}

	const escButtonPressHandler = (
		e: React.KeyboardEvent<HTMLDivElement>
	): void => {
		if (e.key === 'Escape') {
			videoModalClickHandler()
		}
	}

	const childrenArray: ReactElement<PropsWithChildren>[] = Children.toArray(
		children
	).filter((item): item is ReactElement<PropsWithChildren> =>
		isValidElement<PropsWithChildren>(item)
	)

	const getBackgroundImage = (): string => {
		const browser = getBrowser()
		// Only show background image if we don't have browser info e.g. on Storybook
		// or browser request is from IE
		if (browser && browser.browser.name === 'Internet Explorer') {
			return `url('${posterFrameImageUrl}')`
		}
		return 'none'
	}

	let posterImage = childrenArray.find((child) => {
		return child.type === PosterImage
	})

	if (posterImage) {
		posterImage = cloneElement(posterImage as ReactElement<PosterImage>, {
			alignment: posterFrameAlignment,
			posterFrameImageUrl,
			posterFrameImageAltText,
		})
	} else {
		posterImage = createElement(PosterImage, {
			alignment: posterFrameAlignment,
			posterFrameImageUrl,
			posterFrameImageAltText,
		})
	}

	const transcript = childrenArray.find((child) => {
		return child.type === VideoTranscript
	})
	const cardContent = childrenArray.find((child) => {
		return child.type !== PosterImage && child.type !== VideoTranscript
	})
	const scrollBlockContent = childrenArray.find((child) => {
		return child.type === ScrollBlock
	})

	if (thumbnailImage && posterFrameImageUrl) {
		return (
			<div className="relative px-6 py-[42px]" style={{backgroundColor}}>
				<div className="md:flex md:justify-evenly">
					<div className="inline-block max-w-[34rem] md:mt-12 md:max-w-[30rem]">
						{cardContent}
					</div>
					<div className="relative mx-auto w-[calc(100%-3rem)] pt-6 md:m-0 md:h-[432px] md:min-w-[400px] md:pt-0">
						<picture className="block h-64 w-full sm:h-80 md:absolute md:h-full xs:h-72">
							<Image
								alt={posterFrameImageAltText ?? ''}
								className="h-full w-full object-cover object-center"
								height={300}
								loader={loadImageFromKontentAI}
								src={posterFrameImageUrl}
								width={300}
							/>
						</picture>
						{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions -- Not needed here */}
						<div
							className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
							onClick={playButtonClickHandler}
							onKeyDown={(
								e: React.KeyboardEvent<HTMLDivElement>
							) => {
								escButtonPressHandler(e)
							}}
						>
							<EllipseButton
								as="button"
								icon={ButtonIcons.Play}
								iconSize="w-10 h-16"
								iconText={playBtnText}
								size="lg"
							/>
						</div>
					</div>
				</div>
				{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions -- Not needed here */}
				<div
					className={clsx(
						'flex w-full overflow-hidden bg-center',
						showVideoModal
							? 'fixed top-0 z-50 h-screen items-center bg-blue-transparent p-4 pt-12 md:p-24'
							: ' absolute top-0'
					)}
					onClick={videoModalClickHandler}
					style={{
						zIndex: showVideoModal ? 100 : undefined,
					}}
				>
					{videoId ? (
						<div className="mx-auto w-full max-w-screen-xl">
							<YoutubeVideo
								className={clsx(
									hidePosterFrame ? '' : 'hidden'
								)}
								controls={controls}
								pauseHandler={videoModalClickHandler}
								privacyMode={false}
								ref={youtubeVideoRef}
								videoId={videoId}
							/>
						</div>
					) : null}

					{showVideoModal ? (
						<Button
							as="button"
							className="absolute right-0 top-0 z-10 mr-4 mt-4 bg-blue-800 text-white hover:bg-blue-900"
							icon={ButtonIcons.Close}
							onClick={videoModalClickHandler}
							reversed
							variant=""
						>
							{closeBtnText}
						</Button>
					) : null}
				</div>
			</div>
		)
	}

	return (
		<div>
			<div
				className={clsx('relative bg-cover', className)}
				style={{
					backgroundColor,
					backgroundImage: getBackgroundImage(),
				}}
			>
				<div className="flex items-center justify-center md:absolute md:inset-0">
					{hasObjectFit ? posterImage : null}
					<div
						className={clsx(
							'absolute z-10 flex justify-center md:right-0 md:w-2/5 lg:w-3/5',
							videoId ? '' : 'hidden'
						)}
					>
						{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions -- Not needed here */}
						<div
							onClick={playButtonClickHandler}
							onKeyDown={(e) => {
								escButtonPressHandler(e)
							}}
						>
							<EllipseButton
								as="button"
								icon={ButtonIcons.Play}
								iconSize="w-10 h-16"
								iconText={playBtnText}
								size="lg"
							/>
						</div>
					</div>
				</div>
				{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions -- Not needed here */}
				<div
					className={clsx(
						'flex w-full overflow-hidden',
						alignment,
						showVideoModal
							? 'fixed top-0 z-50 h-screen items-center bg-blue-transparent p-4 pt-12 md:p-24'
							: 'h-full md:absolute'
					)}
					onClick={videoModalClickHandler}
					style={{
						zIndex: showVideoModal ? 100 : undefined,
					}}
				>
					{videoId ? (
						<div className="mx-auto w-full max-w-screen-xl">
							<YoutubeVideo
								className={clsx(
									hidePosterFrame ? '' : 'hidden'
								)}
								controls={controls}
								pauseHandler={videoModalClickHandler}
								privacyMode={false}
								ref={youtubeVideoRef}
								videoId={videoId}
							/>
						</div>
					) : null}

					<Button
						as="button"
						className={clsx(
							'absolute right-0 top-0 z-10 mr-4 mt-4 bg-blue-800 text-white hover:bg-blue-900',
							showVideoModal ? '' : 'hidden'
						)}
						icon={ButtonIcons.Close}
						onClick={videoModalClickHandler}
						reversed
						variant=""
					>
						{closeBtnText}
					</Button>
				</div>

				<div
					className="relative overflow-hidden md:py-24"
					id={id}
					style={style}
				>
					<div className="mx-auto items-center justify-between md:flex md:w-9/10 md:max-w-6xl">
						<div className="md:w-3/5 lg:w-2/5">{cardContent}</div>
						{scrollBlockContent ? (
							<div className="md:w-1/3 lg:w-2/5">
								{scrollBlockContent}
							</div>
						) : null}
					</div>
				</div>
			</div>
			<div className={clsx(transcript ? '' : 'hidden')}>
				<VideoTranscript title={videoTitle}>
					{transcript ? transcript.props.children : null}
				</VideoTranscript>
			</div>
		</div>
	)
}
