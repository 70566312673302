import http from '@/utils/axios'
import type {PetFormData} from '@/_new-code/services/pet-profiles/models'
import type {Pet} from '@/_new-code/models/consumer-engagement-models'

export const updatePetProfile = async (
	updatedPetData: PetFormData,
	locale: string
): Promise<Pet> => {
	const {data} = await http<Pet>(
		`/api/pets/${updatedPetData.id}?locale=${locale}`,
		{
			method: 'PUT',
			data: {
				name: updatedPetData.petName,
				gender: updatedPetData.petSex,
				breed: updatedPetData.breed,
				species: updatedPetData.petSpecies,
				birthDate: updatedPetData.petDateOfBirth,
				weight: Number(updatedPetData.petWeight),
				acquisitionSource: updatedPetData.petAcquisitionType,
				acquisitionDate: updatedPetData.petAcquisitionDate,
				petShelterId: updatedPetData.petShelter,
				petShelterName: updatedPetData.shelterName,
				vetClinicId: updatedPetData.vetClinic,
				vetClinicName: updatedPetData.clinicName,
				imageReference: updatedPetData.imageReference,
			},
		}
	)
	return data
}
