import type {JSX} from 'react'
import {pushToDataLayer} from '@/utils/analytics'
import type {MappedResult as SearchResult} from '..'

interface SearchResultsProps {
	results: SearchResult[]
	totalCount: number
	newText: string
	resultsTitle?: string
	noResultsText: string
	resultsText?: string
	linkText: string
	searchTerm: string
}

type ObjectProperties = Record<string | number, string | number>

export const SearchResults = ({
	results = [],
	totalCount = results.length,
	resultsTitle = "{totalCount} results found for '{searchTerm}'",
	noResultsText = "No results to show for '{searchTerm}'",
	resultsText = 'Showing 1 - {pageLength} out of {totalCount} results',
	linkText,
	searchTerm = '',
}: SearchResultsProps): JSX.Element => {
	const handleSearchResultClick = (
		result: SearchResult,
		index: number
	): void => {
		pushToDataLayer({
			event: 'selected_search_results',
			search_term: searchTerm,
			search_result_selected: window.location.origin + result.url,
			search_result_position: index + 1,
		})
	}

	const replaceInString = (
		template: string,
		...origArgs: (ObjectProperties | string | number)[]
	): string => {
		let str = template

		if (origArgs.length) {
			const firstArg = origArgs[0]

			const args: ObjectProperties =
				typeof firstArg === 'object'
					? firstArg
					: origArgs.reduce((acc: ObjectProperties, arg, index) => {
							acc[index.toString()] = arg as string | number
							return acc
						}, {})

			Object.entries(args).forEach(([key, value]) => {
				str = str.replace(
					new RegExp(`\\{${key}\\}`, 'gi'),
					value.toString()
				)
			})
		}

		return str
	}

	const displayResults = results.map((result, index) => (
		<div
			className="relative mb-6 border border-gray-200 bg-white p-6 text-midnight shadow-sm md:p-7"
			key={result.title}
		>
			{result.category ? (
				<span className="inline-block border-2 border-midnight bg-white px-4 py-1 text-sm uppercase">
					{result.category}
				</span>
			) : null}
			<h3 className="mb-1 mt-4 text-lg">{result.title}</h3>
			<p className="mb-4 mt-1 line-clamp-3">{result.desc}</p>
			<a
				className="text-sm font-bold text-primary underline hover:text-primary-dark focus:text-secondary focus:outline-none"
				href={result.url}
				onClick={() => {
					handleSearchResultClick(result, index)
				}}
			>
				{linkText || result.url}
			</a>
		</div>
	))

	let showText = replaceInString(noResultsText, {searchTerm})
	if (totalCount) {
		showText = replaceInString(resultsText, {
			pageLength: results.length,
			totalCount,
		})
	}

	return (
		<div>
			<h2 className="mb-12 font-semibold">
				{replaceInString(resultsTitle, {totalCount, searchTerm})}
			</h2>
			<p className="mb-2 text-gray-600">{showText}</p>
			<div>{displayResults}</div>
		</div>
	)
}
