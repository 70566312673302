import {clsx} from 'clsx'
import type {JSX} from 'react'

interface HistoryTimelineItemProps {
	children: React.ReactNode
	circleText: string
	hasLargeCircle?: boolean
}

export const HistoryTimelineItem = ({
	children,
	circleText,
	hasLargeCircle = false,
}: HistoryTimelineItemProps): JSX.Element => {
	return (
		<section className="relative py-2 after:absolute after:left-4 after:top-0 after:h-full after:w-px after:bg-primary after:content-[''] md:flex md:items-center md:py-8 after:md:left-[52px]">
			<h2
				className={clsx(
					'relative z-20 ml-4 w-14 border-2 border-primary bg-white py-[14px] text-center text-base leading-6 md:mx-6 md:my-0 md:shrink-0 md:rounded-full',
					hasLargeCircle &&
						'text-h2 w-[104px] border-secondary bg-secondary py-[38px] leading-6 text-white md:m-0',
					circleText.length > 4 && 'text-base'
				)}
			>
				{circleText}
			</h2>

			<div
				className={clsx(
					'pl-8',
					hasLargeCircle
						? 'w-[calc(100%-104px)]'
						: 'w-[calc(100%-56px)]'
				)}
			>
				{children}
			</div>
		</section>
	)
}
