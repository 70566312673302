import {clsx} from 'clsx'
import {useQuery} from 'react-query'
import {useRouter} from 'next/router'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import type {Block, Tersed} from '@/_new-code/services/kontent-ai/types'
import {SkeletonLoader} from '@/_new-code/products/flexible-web-toolkit/components/skeleton-loader'
import {spacing} from '@/_new-code/products/flexible-web-toolkit/styles'
import type {CouponContentItem} from '@/_new-code/products/your-pet-and-you/blocks/coupon'
import type {ListerItemsQueryKey} from '@/_new-code/products/flexible-web-toolkit/blocks/module-coupon-lister/types'
import {fetchListerItems} from '@/_new-code/services/lister-items/client'

export type ModuleCouponListerContentItem = IContentItem<{
	snippetSpacingSpacing: Elements.MultipleChoiceElement
	paginationString: Elements.TextElement
}>

export const ModuleCouponListerBlock: Block<ModuleCouponListerContentItem> = ({
	BlockMapper,
	block,
	globalConfig,
	page,
}) => {
	const {locale = ''} = useRouter()
	const {data, isFetching, isLoading} = useQuery({
		queryKey: [
			'coupons',
			{codename: 'coupon', locale},
		] as ListerItemsQueryKey,
		queryFn: (opts) => fetchListerItems<Tersed<CouponContentItem>>(opts),
	})

	const componentSpacing =
		spacing[
			(block.elements.snippetSpacingSpacing[0]?.codename ??
				'none') as keyof typeof spacing
		]

	const pagination = block.elements.paginationString.replace(
		/{{X}}/,
		data?.items.length.toString() || '0'
	)

	return (
		<div className={clsx(`mx-auto max-w-screen-xl`, componentSpacing)}>
			<div className="flex flex-col items-center">
				<h3>{pagination}</h3>
				{isFetching ? (
					<div className="mt-20 w-full max-w-[764px] space-y-20 px-3 sm:w-3/4 sm:px-0">
						<SkeletonLoader amount={7} />
					</div>
				) : null}
				{!isLoading && data
					? data.items.map((coupon) => {
							return (
								<div
									className="my-2 flex w-full flex-wrap px-3 sm:w-3/4 sm:px-0"
									key={coupon.system.id}
								>
									<BlockMapper
										blocks={[coupon]}
										globalConfig={globalConfig}
										page={page}
									/>
								</div>
							)
						})
					: null}
			</div>
		</div>
	)
}
