import {UnderMaintenancePageComponent} from '@elanco/rebate-components'
import {getCookie} from 'cookies-next'
import {useQuery} from 'react-query'
import {useRouter} from 'next/router'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {useAuth} from '@/_new-code/products/auth/auth-provider'
import {populateToken} from '@/_new-code/services/populate-token/client'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {LoadingSpinner} from '@/_new-code/products/flexible-web-toolkit/components/loading-spinner'

export type EprRebateStatusContentItem = IContentItem<{
	programInfoUrl: Elements.TextElement
	qualifyingProductsUrl: Elements.TextElement
	rebateHistoryLink: Elements.TextElement
	enableGrowthIncentiveToggle: Elements.MultipleChoiceElement
	howPointsWorkUrl: Elements.TextElement
	notLoggedInText: Elements.TextElement
	noCrmIdText: Elements.TextElement
	viewModeOption: Elements.MultipleChoiceElement
}>

export const EprRebateStatusBlock: Block<EprRebateStatusContentItem> = ({
	block,
	//globalConfig
}) => {
	const {locale = 'us'} = useRouter()

	const {data, isLoading} = useQuery({
		queryFn: () => populateToken(locale),
		staleTime: Infinity,
		refetchOnWindowFocus: false,
	})
	const authState = useAuth()
	const {authenticated, userDetails} = authState
	// Extract cdc_user cookie and ensure it's a string and not null.
	const userCookie = getCookie('cdc_user')?.toString() ?? ''
	// The cookie is stored as a base64 encoded string.  Decode it.
	const strCookie = Buffer.from(userCookie, 'base64').toString()
	// The shape of the cookie is defined and populated in JWTService (triggered by the populateToken function above).
	// The token is unpacked from that jwtresponse and used in the rebates component for authentication.
	const token =
		strCookie === ''
			? '{}'
			: (JSON.parse(strCookie) as {token?: string}).token

	const config = {
		links: {
			programInfo: block.elements.programInfoUrl,
			qualifyingProducts: block.elements.qualifyingProductsUrl,
			rebateHistory: block.elements.rebateHistoryLink,
			howPointsWork: block.elements.howPointsWorkUrl,
		},
	}

	if (!authenticated) {
		return (
			<div className="m-8">
				{block.elements.notLoggedInText ||
					'Please log in to view Rebate Status'}
			</div>
		)
	}

	if (isLoading || userDetails.id === null) {
		return (
			<div className="mx-auto w-full text-center">
				<LoadingSpinner theme="blue" />
			</div>
		)
	}

	if (!userDetails.crmId) {
		return (
			<div className="m-8">
				{block.elements.noCrmIdText ||
					'Your account is not configured for the Rebate Status page.	Please contact your Rep for more information'}
			</div>
		)
	}

	const userAccessToken = token ?? data?.cdcToken

	return (
		<div>
			{userAccessToken ? (
				// Commenting the below code while the EPR dashboard is in development

				// <EprRebateStatusApp
				// 	apiUrl={env.NEXT_PUBLIC_REBATES_API_URL ?? ''}
				// 	cdcApiKey={globalConfig.elements.cdcKey}
				// 	clinicId={userDetails.crmId}
				// 	config={config}
				// 	enableGrowthIncentiveHalfToggle={
				// 		block.elements.enableGrowthIncentiveToggle[0]
				// 			?.codename === 'yes'
				// 	}
				// 	userAccessToken={userAccessToken}
				// />
				<UnderMaintenancePageComponent config={config} />
			) : null}
		</div>
	)
}
