import type {Dispatch, SetStateAction} from 'react'
import Link from 'next/link'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {useRouter} from 'next/router'
import {Button} from '@/_new-code/products/flexible-web-toolkit/components/button/button'
import {addLocalePrefix} from '@/_new-code/utilities/add-locale-prefix'
import type {LinkContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/link'
import type {ExtendedBlock} from '@/_new-code/services/kontent-ai/types'

export type ModuleRebatesHeaderSearchContentItem = IContentItem<{
	searchTitle: Elements.TextElement
	searchSubtitle: Elements.TextElement
	searchPlaceholderText: Elements.TextElement
	searchButtonText: Elements.TextElement
	rebateFaqLink: Elements.LinkedItemsElement<LinkContentItem>
	downloadRebateFormLink: Elements.LinkedItemsElement<LinkContentItem>
}>

export const ModuleRebatesHeaderSearchBlock: ExtendedBlock<
	ModuleRebatesHeaderSearchContentItem,
	{
		searchTerm: string
		setSearchTerm: Dispatch<SetStateAction<string>>
		onSearch: () => void
	}
> = ({
	block: {
		elements: {
			downloadRebateFormLink,
			rebateFaqLink,
			searchButtonText,
			searchPlaceholderText,
			searchSubtitle,
			searchTitle,
		},
	},
	searchTerm,
	setSearchTerm,
	onSearch,
}) => {
	const {locale = ''} = useRouter()
	return (
		<div className="md:mr-20 md:mt-10 md:max-w-[400px]">
			<div className="flex flex-col rounded border border-light-grey p-4">
				<h3 className="pb-2 text-base font-normal">{searchTitle}</h3>
				<h4 className="text-xs font-normal">{searchSubtitle}</h4>
				<div className="flex flex-row pt-4">
					<input
						className="text-sky-900 mb-0 h-9 rounded-l rounded-r-none border-r-0 border-light-grey !bg-white py-2 pr-8 text-sm font-light text-pet-primary placeholder:text-sm"
						name="rebate-search-input"
						onChange={(e) => {
							setSearchTerm(e.target.value)
						}}
						onKeyDown={(e) => {
							if (e.key === 'Enter') onSearch()
						}}
						placeholder={searchPlaceholderText}
						value={searchTerm}
					/>
					<Button
						className="h-9 rounded-none font-semibold"
						onClick={onSearch}
						variant="pet_primary_green"
					>
						{searchButtonText}
					</Button>
				</div>
			</div>
			<div className="mt-2 flex flex-row justify-between">
				{downloadRebateFormLink[0] ? (
					<a
						className="flex flex-row items-center gap-2 text-xs italic"
						download
						href={downloadRebateFormLink[0].elements.url}
						rel="noreferrer"
						target={
							downloadRebateFormLink[0]?.elements.openInNewTab[0]
								?.codename === 'yes'
								? '_blank'
								: '_self'
						}
					>
						<svg
							height="15.772"
							viewBox="0 0 21.09 20.772"
							width="15.09"
						>
							<g transform="translate(77.186 0.953)">
								<path d="M-66.6,9.6l5.3-5.3h-4V-1H-68v5.3h-4L-66.6,9.6z" />
								<path d="M-61.8,8.4l-1.5,1.5l5.4,2l-8.7,3.2l-8.7-3.2l5.4-2l-1.5-1.5l-5.8,2.2v5.3l10.5,4l10.5-4v-5.3 L-61.8,8.4z" />
							</g>
						</svg>
						{downloadRebateFormLink[0].elements.text}
					</a>
				) : null}
				{rebateFaqLink[0] ? (
					<Link
						className="text-xs text-pet-green"
						href={addLocalePrefix(
							rebateFaqLink[0].elements.url,
							locale
						)}
						passHref
						rel="noreferrer"
						target={
							rebateFaqLink[0].elements.openInNewTab[0]
								?.codename === 'yes'
								? '_blank'
								: '_self'
						}
					>
						{}

						{rebateFaqLink[0].elements.text}
					</Link>
				) : null}
			</div>
		</div>
	)
}
