import type {JSX} from 'react'
import {useWindowSize} from '@/_new-code/utilities/hooks/use-window-size'

interface DisplayPetNamesProps {
	petResultsList: string[]
	moreThanFortyNames: boolean
	loadMore?: boolean
}

export const DisplayPetNames = ({
	petResultsList,
	moreThanFortyNames,
	loadMore,
}: DisplayPetNamesProps): JSX.Element => {
	const size = useWindowSize()
	const {isMobile} = size
	const unfilteredNames = petResultsList
	const names = []

	if (!loadMore && moreThanFortyNames) {
		const shuffledNames = unfilteredNames
			.slice()
			.sort(() => 0.5 - Math.random())
		// Push first 40 names
		names.push(...shuffledNames.slice(0, 40).sort())
	} else {
		names.push(...unfilteredNames)
	}

	return (
		<ul style={{columns: isMobile ? 2 : 4}}>
			{names.map((name) => (
				<li key={name}>{name}</li>
			))}
		</ul>
	)
}
