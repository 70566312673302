import type {PropsWithChildren, ReactElement} from 'react'

interface TabProps extends Required<PropsWithChildren> {
	active?: boolean
	closeBtnHandler?: () => void
	expanded?: boolean
	hideCloseBtn?: boolean
	label?: string
	panel?: string
	tabContentClassName?: string
	tabPaddingClasses?: string
	title?: string
	pinned?: boolean
}

export const Tab = ({
	children,
	active = false,
	expanded = false,
	hideCloseBtn = false,
	closeBtnHandler = () => null,
	title = '',
	panel = '',
	label = '',
	tabPaddingClasses = 'py-12 px-18',
	tabContentClassName = '',
}: TabProps): ReactElement => {
	return (
		<div
			aria-labelledby={label}
			className={`content content--no-width-restriction dark:bg-highlight ${tabPaddingClasses} relative ${
				active && expanded ? 'block' : 'hidden'
			}`}
			id={panel}
			role="tabpanel"
			style={{boxShadow: '0px -6px 12px 0px rgb(0 0 0 / 6%)'}}
		>
			<div
				className={`text-sm ${
					!hideCloseBtn ? 'mx-auto w-10/12 pr-6' : ' h-full px-2'
				}`}
			>
				<div
					className={
						!hideCloseBtn
							? `max-w-7xl ${tabContentClassName}`
							: 'h-full'
					}
				>
					{children}
				</div>
			</div>
			{!hideCloseBtn && (
				<button
					aria-label={`Close ${title} panel`}
					className="absolute right-0 top-0 mr-10 mt-10 cursor-pointer"
					onClick={closeBtnHandler}
					type="button"
				>
					<svg
						className="fill-current text-blue-500 hover:text-gray-600"
						height="21.5"
						viewBox="0 0 21.5 21.5"
						width="21.5"
					>
						<path
							d="M2.265,21.707.293,19.734a1,1,0,0,1,0-1.415l7.071-7.07L.293,4.18a1,1,0,0,1,0-1.414L2.266.793a1,1,0,0,1,1.414,0l7.07,7.07L17.819.794a1,1,0,0,1,1.414,0l1.973,1.973a1,1,0,0,1,0,1.414L14.137,11.25l7.07,7.07a1,1,0,0,1,0,1.414l-1.973,1.973a1,1,0,0,1-1.414,0l-7.07-7.07L3.68,21.707a1,1,0,0,1-1.415,0Z"
							transform="translate(0 -0.5)"
						/>
					</svg>
				</button>
			)}
		</div>
	)
}
