import {cloneElement, type ReactElement, type JSX} from 'react'
import {ButtonIcons} from '@/_new-code/components/button-icons/button-icon'
import {Card} from './card'
import {CardFooter} from './card/card-footer'

export interface BuyOnlineLinkCards {
	button: ReactElement<{
		variant: string
		className: string
		icon?: {
			class: string
			icon: JSX.Element
		}
	}> | null
	image: JSX.Element | null
	title?: string
}

interface BuyOnlineLinksProps {
	children?: React.ReactNode
	className?: string
	cards?: BuyOnlineLinkCards[]
}

export const BuyOnlineLinks = ({
	cards = [],
	className = '',
	children,
}: BuyOnlineLinksProps): ReactElement => {
	const displayCards = cards.map((card) => {
		return (
			<div className="mb-3 md:w-64" key={card.title}>
				<Card
					className="mx-auto h-full w-auto rounded-md border border-gray-700 pt-0 shadow-primary md:mx-2"
					title={card.title}
				>
					{card.image
						? cloneElement(card.image, {
								className:
									'px-8 pt-8 h-40 object-contain mx-auto',
							})
						: null}
					{card.button ? (
						<CardFooter className="m-0 pt-0">
							{cloneElement(card.button, {
								variant:
									card.button.props.variant || 'inverted',
								className: `${card.button.props.className} w-full`,
								icon:
									card.button.props.icon ??
									ButtonIcons.External,
							})}
						</CardFooter>
					) : null}
				</Card>
			</div>
		)
	})

	return (
		<div className={`py-8 md:py-12 ${className}`}>
			<div className="content container-narrow text-center">
				{children}
			</div>

			<div className="container-wide mt-8">
				<div className="justify-center md:-mx-4 md:flex md:flex-wrap">
					{displayCards}
				</div>
			</div>
		</div>
	)
}
