import {useEffect, useRef, useState, type JSX} from 'react'
import type {CampaignInterestHandler} from '@/_new-code/products/campaign-forms/cdc-screen-set-wrapper/campaign-questions/campaign-interest-handler'
import {DateQuestion} from '@/_new-code/products/campaign-forms/cdc-screen-set-wrapper/campaign-questions/date-question'
import {MultiSelectQuestion} from '@/_new-code/products/campaign-forms/cdc-screen-set-wrapper/campaign-questions/multi-select-question'
import {SingleSelectionQuestion} from '@/_new-code/products/campaign-forms/cdc-screen-set-wrapper/campaign-questions/single-selection-question'
import {DATE_SELECTION} from '@/_new-code/products/campaign-forms/cdc-screen-set-wrapper/model-as-code/date-question.mac'
import {MULTIPLE_SELECTION} from '@/_new-code/products/campaign-forms/cdc-screen-set-wrapper/model-as-code/multiple-selection-question.mac'
import {SINGLE_SELECTION} from '@/_new-code/products/campaign-forms/cdc-screen-set-wrapper/model-as-code/single-selection-question.mac'
import type {
	QuestionType,
	ValidationEvent,
	SingleSelectionContentItem,
	MultipleSelectionContentItem,
	DateSelectionContentItem,
} from '@/_new-code/products/campaign-forms/cdc-screen-set-wrapper/models'
import type {Tersed} from '@/_new-code/services/kontent-ai/types'

type QuestionResponseOutput = Record<string, string[]>

interface CampaignQuestionProps {
	question: QuestionType
	handler: CampaignInterestHandler
}

export const CampaignQuestion = ({
	question,
	handler,
}: CampaignQuestionProps): JSX.Element => {
	const [selectedOptions, setSelectedOptions] = useState<string[]>([])
	const selectedOptionsRef = useRef(selectedOptions)
	const [hasErrors, setHasErrors] = useState<boolean>(false)
	const errorsRef = useRef(hasErrors)
	const [validationEvent, setValidationEvent] = useState<ValidationEvent>({
		validateNow: false,
	})

	const injectedElements: string[] = []

	const captureInjectedElements = (data: string): void => {
		injectedElements.push(data)
	}

	useEffect(() => {
		selectedOptionsRef.current = selectedOptions
	}, [selectedOptions])

	useEffect(() => {
		errorsRef.current = hasErrors
	}, [hasErrors])

	useEffect(() => {
		handler.registerQuestion(
			() => {
				if (selectedOptionsRef.current.length === 0) {
					return null
				}
				const returnValue: QuestionResponseOutput = {}
				returnValue[question.elements.questionId] =
					selectedOptionsRef.current
				return returnValue
			},
			() => {
				return injectedElements
			},
			() => errorsRef.current,
			setValidationEvent
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps -- This should only run once
	}, [])

	switch (question.system.type) {
		case SINGLE_SELECTION:
			return (
				<SingleSelectionQuestion
					errorsCallback={setHasErrors}
					injectedElementsCallback={captureInjectedElements}
					key={question.system.id}
					question={question as Tersed<SingleSelectionContentItem>}
					selectedOptionsCallback={setSelectedOptions}
					validationEvent={validationEvent}
				/>
			)
		case MULTIPLE_SELECTION:
			return (
				<MultiSelectQuestion
					errorsCallback={setHasErrors}
					injectedElementsCallback={captureInjectedElements}
					key={question.system.id}
					question={question as Tersed<MultipleSelectionContentItem>}
					selectedOptionsCallback={setSelectedOptions}
					validationEvent={validationEvent}
				/>
			)
		case DATE_SELECTION:
			return (
				<DateQuestion
					errorsCallback={setHasErrors}
					injectedElementsCallback={captureInjectedElements}
					key={question.system.id}
					question={question as Tersed<DateSelectionContentItem>}
					selectedOptionsCallback={setSelectedOptions}
					validationEvent={validationEvent}
				/>
			)
		default:
			return <div />
	}
}
