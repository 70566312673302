import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import type {Block} from '@/_new-code/services/kontent-ai/types'

export type BazaarVoiceContentItem = IContentItem<{
	productId: Elements.TextElement
	widgetPositioning: Elements.MultipleChoiceElement
}>

export const BazaarVoiceBlock: Block<BazaarVoiceContentItem> = ({block}) => {
	return (
		<div
			data-bv-product-id={block.elements.productId}
			data-bv-show="reviews"
		/>
	)
}
