import type {ReactNode, JSX} from 'react'
import React, {isValidElement} from 'react'
import Image from 'next/image'
import styles from './steps.module.css'

interface StepsProps {
	children: ReactNode[] | null
	step: string
	title: string
}

export const Step = ({children, step, title}: StepsProps): JSX.Element => {
	const image = children?.find((child) => {
		return isValidElement(child) && child.type === Image
	})
	const text = children?.filter((child) => {
		return isValidElement(child) && child.type !== Image
	})

	return (
		<div className={`${styles.StepContainer} px-2 md:w-1/2 lg:w-1/4`}>
			<div className="md:relative">
				<div
					className={`${
						styles['arrow-overlap']
					} relative inset-0 mx-auto mb-8 h-[76px] w-[76px] rounded-full bg-white md:absolute md:inset-0 md:z-10 md:mx-0 md:my-auto md:rounded-full ${
						styles['arrow-overlap']
					} ${step === '1' ? ' hidden' : ''}
					`}
				>
					<svg
						className="absolute inset-0 m-auto w-3/5"
						height="49.189"
						viewBox="0 0 50.467 49.189"
						width="50.467"
					>
						<path
							d="M21.457,5.942l2.5-2.5a2.692,2.692,0,0,1,3.818,0l21.9,21.886a2.692,2.692,0,0,1,0,3.818l-21.9,21.9a2.692,2.692,0,0,1-3.818,0l-2.5-2.5a2.706,2.706,0,0,1,.045-3.863L35.075,31.747H2.7a2.7,2.7,0,0,1-2.7-2.7v-3.6a2.7,2.7,0,0,1,2.7-2.7H35.075L21.5,9.805A2.687,2.687,0,0,1,21.457,5.942Z"
							fill="#bcbaba"
							transform="translate(0 -2.647)"
						/>
					</svg>
				</div>
				<div className="relative h-64 overflow-hidden">{image}</div>
			</div>

			<div className="p-4">
				<h4 className="pb-3">
					{`${step}. `}
					{title}
				</h4>
				{text}
			</div>
		</div>
	)
}
