import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {useRouter} from 'next/router'
import Image from 'next/image'
import {useEffect, useState} from 'react'
import {clsx} from 'clsx'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {addLocalePrefix} from '@/_new-code/utilities/add-locale-prefix'
import type {LinkContentItem} from '../link'
import type {NavItemLinkContentItem} from '../../components/header/models'

export type FilterSideSelectorContentItem = IContentItem<{
	heading: Elements.TextElement
	icon: Elements.AssetsElement
	subHeading: Elements.TextElement
	hideLinks: Elements.MultipleChoiceElement
	showMore: Elements.TextElement
	showLess: Elements.TextElement
	maxLinks: Elements.NumberElement
	links: Elements.LinkedItemsElement<LinkContentItem | NavItemLinkContentItem>
}>

export const FilterSideSelectorBlock: Block<FilterSideSelectorContentItem> = ({
	block,
}) => {
	const {locale = ''} = useRouter()
	const linksWithLocale = block.elements.links.map((link) => ({
		...link.elements,
		url: addLocalePrefix(link.elements.url, locale),
	}))

	const [showMore, setShowMore] = useState(true)
	const [activeLink, setActiveLink] = useState<string | null>(null)

	function toggleSeeMore(): null {
		setShowMore(!showMore)
		return null
	}

	useEffect(() => {
		document.querySelectorAll('.sidefilters a').forEach((anchor) => {
			anchor.addEventListener('click', () => {
				setActiveLink(anchor.getAttribute('href'))
			})
		})
	})

	useEffect(() => {
		let maxMatch = 0
		document.querySelectorAll('.sidefilters a').forEach((anchor) => {
			const href = anchor.getAttribute('href')
			const urlSegments =
				href !== null ? href.split('/').filter((u) => u !== '') : []

			if (
				urlSegments.every((u) => window.location.href.includes(u)) &&
				anchor.getAttribute('href') !== '' &&
				anchor.getAttribute('href') !== '/'
			) {
				if (urlSegments.length > maxMatch) {
					maxMatch = urlSegments.length
					setActiveLink(anchor.getAttribute('href'))
				}
			}
		})
	}, [])

	const maxLinks = block.elements.maxLinks ?? 0
	const showSeeMore =
		showMore &&
		linksWithLocale.length > maxLinks &&
		block.elements.hideLinks[0]?.codename === 'yes'
	const showSeeLess =
		!showMore &&
		linksWithLocale.length > maxLinks &&
		block.elements.hideLinks[0]?.codename === 'yes'
	const linksToDisplay = showSeeMore
		? linksWithLocale.slice(0, maxLinks)
		: linksWithLocale

	return (
		<div className="hidden md:block">
			<div className="align-center mb-4 flex">
				{block.elements.icon[0]?.url ? (
					<Image
						alt={block.elements.icon[0]?.description ?? ''}
						className="h-fit max-w-[30px] self-center"
						height={30}
						src={block.elements.icon[0]?.url}
						width={30}
					/>
				) : null}
				<h2 className="ml-1">{block.elements.heading}</h2>
			</div>

			{block.elements.subHeading ? (
				<h3 className="pb-4">{block.elements.subHeading}</h3>
			) : null}
			<ul className="sidefilters border-bottom-1 relative mb-4 list-none border-b border-gray-500 pb-4">
				{linksToDisplay.map((link) => (
					<li
						className="mb-4 text-pet-green"
						key={block.elements.heading} //may change this one
					>
						<a
							className={clsx(
								'hover:underline',
								activeLink === link.url &&
									'border-4 border-b-0 border-r-0 border-t-0 border-green-600 pl-2 font-bold text-black'
							)}
							href={link.url}
						>
							{link.text}
						</a>
					</li>
				))}
				{showSeeMore ? (
					<li className="absolute bg-white pr-2">
						<button
							className="font-bold text-pet-green"
							onClick={toggleSeeMore}
							type="button"
						>
							{block.elements.showMore}
						</button>
					</li>
				) : null}
				{showSeeLess ? (
					<li className="absolute bg-white pr-2">
						<button
							className="font-bold text-pet-green"
							onClick={toggleSeeMore}
							type="button"
						>
							{block.elements.showLess}
						</button>
					</li>
				) : null}
			</ul>
		</div>
	)
}
