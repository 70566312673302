import type {JSX} from 'react'

interface CheckboxProps {
	value: boolean
	onChange: (value: boolean) => void
	id: string
	required: boolean
	label: string
}

export const Checkbox = ({
	onChange,
	id,
	required,
	value,
	label,
}: CheckboxProps): JSX.Element => (
	<div className="relative">
		<input
			className="form-control"
			id={id}
			onChange={() => {
				onChange(!value)
			}}
			required={required}
			type="checkbox"
		/>
		<label htmlFor={id} style={{marginTop: '0', marginBottom: '0'}}>
			{label}
		</label>
	</div>
)
