import type {JSX, PropsWithChildren, ReactNode} from 'react'
import {useEffect, useState} from 'react'
import {clsx} from 'clsx'
import {pushToDataLayer} from '@/utils/analytics'

export interface FaqItemProps {
	className?: string
	expanded?: boolean
	iconColor?: string
	title?: ReactNode
}

export const FaqItem = ({
	children,
	className = '',
	expanded: expandedProp = false,
	iconColor = '#000000',
	title = '',
}: PropsWithChildren<FaqItemProps>): JSX.Element => {
	const [expanded, setExpanded] = useState(expandedProp)
	const [isContextUsed, setIsContextUsed] = useState(false)

	const determineTextColor = (): string => {
		if (expanded) {
			return isContextUsed ? 'text-main' : 'text-black'
		}

		return isContextUsed ? 'text-alternative' : ''
	}

	useEffect(() => {
		setIsContextUsed(document.querySelector('#theme') !== null)
	}, [])

	const dropDown = (titleVal: ReactNode): null => {
		pushToDataLayer({
			event: 'accordion_click',
			accordion_name: titleVal,
			accordion_status: !expanded ? 'open' : 'close',
		})
		setExpanded(!expanded)
		return null
	}

	return (
		<div className="border-b border-elanco-beige last-of-type:border-0">
			<dt>
				<button
					aria-expanded={expanded}
					className="w-full px-5 py-5 text-left text-black"
					onClick={() => {
						dropDown(title)
					}}
					type="button"
				>
					<div className="flex items-center">
						<svg
							className={clsx(
								'mr-4 h-4 w-4 shrink-0 transition-all duration-300',
								expanded && 'rotate-45'
							)}
							fill={iconColor}
							height="26.883"
							viewBox="0 0 26.883 26.883"
							width="26.883"
						>
							<path
								d="M1.9,18.729l-1.6-1.595a1,1,0,0,1,0-1.414l6.2-6.2-6.2-6.2a1,1,0,0,1,0-1.414L1.9.306a1,1,0,0,1,1.415,0l6.2,6.2,6.2-6.2a1,1,0,0,1,1.415,0l1.595,1.6a1,1,0,0,1,0,1.414l-6.2,6.2,6.2,6.2a1,1,0,0,1,0,1.414l-1.595,1.595a1,1,0,0,1-1.415,0l-6.2-6.2-6.2,6.2a1,1,0,0,1-1.414,0Z"
								transform={`translate(${
									expanded ? 23.442 : 13.442
								} ${expanded ? 3.9982 : -0.018}) rotate(${
									expanded ? 90 : 45
								})`}
							/>
						</svg>
						<h2
							className={clsx(
								`text-base font-normal`,
								determineTextColor()
							)}
						>
							{title}
						</h2>
					</div>
				</button>
			</dt>
			<dd
				className={clsx(
					'content--no-width-restriction px-5',
					className,
					expanded ? 'block pb-6' : 'hidden'
				)}
			>
				{children}
			</dd>
		</div>
	)
}
