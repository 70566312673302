/* eslint-disable no-unsafe-optional-chaining -- needed */

import http from '@/utils/axios'
import type {MapEntity} from '@/services/vetApi'
import vetApi from '@/services/vetApi'
import type {GeoResults} from '@/_new-code/services/vet-clinics/models'

async function geocode(address: string): Promise<GeoResults> {
	try {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- cannot replace
		const {data} = await http({
			method: 'POST',
			url: '/api/google/geocode',
			data: {address},
		})
		return data as GeoResults
		// eslint-disable-next-line @typescript-eslint/no-explicit-any -- any needed
	} catch (error: any) {
		throw new Error(
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- needed
			`Geocode API error status ${error.response.status}\nMessage: ${error.message}`
		)
	}
}

export const fetchVetClinics = async (
	address: string
): Promise<MapEntity[]> => {
	if (!address) {
		return []
	}
	const geo = await geocode(address)
	if (geo.status !== 'OK') {
		return []
	}
	// eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain, @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-unnecessary-condition -- works for now
	const {lat, lng} = geo?.results[0]?.geometry?.location!

	const response = await vetApi.searchLatLng(lat, lng, 'ELA_AC_061')
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- needed
	if (response?.entities !== undefined) {
		return response.entities
	}
	return []
}

export const fetchShelters = async (address: string): Promise<MapEntity[]> => {
	if (!address) {
		return []
	}
	const geo = await geocode(address)
	if (geo.status !== 'OK') {
		return []
	}
	// eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain, @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-unnecessary-condition -- works for now
	const {lat, lng} = geo?.results[0]?.geometry?.location!

	const response = await vetApi.searchLatLng(lat, lng, 'ELA_AC_003')
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- no other way
	if (response?.entities !== undefined) {
		return response.entities
	}
	return []
}
