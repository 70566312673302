import {Fragment, type SyntheticEvent, type JSX} from 'react'
import {Icon as CIcon} from '@elanco/component-library-v2'
import {useRouter} from 'next/router'
import {useQuery} from 'react-query'
import Image from 'next/image'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {fetchPetProfiles} from '@/_new-code/services/pet-profiles/client'
import {addLocalePrefix} from '@/_new-code/utilities/add-locale-prefix'
import type {CtaButtonContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/cta-button'
import {loadImageFromKontentAI} from '@/imageLoader'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import type {IconContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/icon'

const ADD_REMINDER_CODENAME = 'add_reminder'

export type AddButtonIconContentItem = IContentItem<{
	functionalityType: Elements.MultipleChoiceElement
	ctaButton: Elements.LinkedItemsElement<CtaButtonContentItem>
	icon: Elements.LinkedItemsElement<IconContentItem>
}>

export const AddButtonIconBlock: Block<AddButtonIconContentItem> = ({
	block: {
		elements: {
			ctaButton,
			functionalityType,
			icon: [icon],
		},
		system: {id},
	},
}): JSX.Element => {
	const router = useRouter()
	const {isLoading, data: pets} = useQuery({
		queryKey: ['pets'],
		queryFn: () => fetchPetProfiles(router.locale ?? 'null'),
		staleTime: Infinity,
		cacheTime: 15 * (60 * 1000),
	})

	const isAddReminder: boolean =
		functionalityType[0]?.codename === ADD_REMINDER_CODENAME
	const isOnlyPet: string =
		(!isLoading && pets?.length === 1 ? pets[0]?.id : '') ?? ''

	const petId: string | string[] = isOnlyPet || (router.query.id ?? '')

	const handleAddReminder = (e: SyntheticEvent): void => {
		e.preventDefault()
		void router.replace(
			{
				pathname: addLocalePrefix(router.asPath, router.locale ?? ''),
				query: {reminder: petId},
			},
			undefined,
			{shallow: true}
		)
	}

	return (
		<div data-kontent-item-id={id}>
			{ctaButton.length > 0 &&
				ctaButton.map((btn) => (
					<Fragment key={btn.system.id}>
						{isAddReminder ? (
							<button
								className="flex items-center justify-end space-x-2"
								onClick={handleAddReminder}
								type="button"
							>
								<span className="text-xs">
									{btn.elements.text}
								</span>
								<CIcon
									className="h-8 w-8"
									noBackground
									noBorder
								>
									{icon?.elements.icon[0] ? (
										<Image
											alt={
												icon.elements.icon[0]
													.description ?? ''
											}
											className="h-8 w-8"
											height={32}
											loader={loadImageFromKontentAI}
											src={icon.elements.icon[0].url}
											width={32}
										/>
									) : null}
								</CIcon>
							</button>
						) : (
							<a
								className="flex items-center justify-end space-x-2 text-[#333]"
								href={addLocalePrefix(
									btn.elements.url,
									router.locale ?? ''
								)}
							>
								<span className="text-xs">
									{btn.elements.text}
								</span>
								<CIcon
									className="h-8 w-8"
									noBackground
									noBorder
								>
									{icon?.elements.icon[0] ? (
										<Image
											alt={
												icon.elements.icon[0]
													.description ?? ''
											}
											className="h-8 w-8"
											height={32}
											loader={loadImageFromKontentAI}
											src={icon.elements.icon[0].url}
											width={32}
										/>
									) : null}
								</CIcon>
							</a>
						)}
					</Fragment>
				))}
		</div>
	)
}
