import type {QueryFunctionContext} from 'react-query'
import http from '@/utils/axios'
import type {
	Params,
	TBreadcrumbsResponse,
} from '@/_new-code/services/breadcrumbs/models'

export const useFetchBreadcrumbs = async (
	context: QueryFunctionContext
): Promise<TBreadcrumbsResponse | null> => {
	const {pageId, locale, pageUrl} = context.queryKey[1] as Params
	const isTaxonomyTermPage = pageUrl.includes('taxonomy/term')
	if (isTaxonomyTermPage) {
		// Return null to prevent breadcrumbs from being displayed for taxonomy term pages since they does not exist in kontent.ai
		return null
	}
	const endpoint = `/api/breadcrumbs?pageId=${pageId}&locale=${locale}&pageUrl=${pageUrl}`
	const {data} = await http<TBreadcrumbsResponse>(endpoint)
	return data
}
