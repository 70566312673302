import type {ReactNode} from 'react'
import {clsx} from 'clsx'

interface NavItemsProps {
	className?: string
	children: ReactNode
}

export const NavItems: React.FC<NavItemsProps> = ({
	className = '',
	children,
}) => {
	return (
		<div
			className={clsx(
				'fixed h-[calc(100vh-138px)] w-full border-b bg-white transition-all duration-200 ease-out lg:static lg:h-auto lg:max-h-3/4 lg:overflow-y-auto',
				className
			)}
		>
			{children}
		</div>
	)
}
