import type {ModelAsCode} from '@elanco/model-as-code'

export const NEW_PARASITE_TRACKER_MAP_CODENAME = 'new_parasite_tracker_map'
export const HEATMAP = 'heatmap'
export const MARKERS = 'markers'
export const HEATMAP_AND_MARKERS = 'heatmap_and_markers'

const modelParasiteTrackerMap: ModelAsCode = (builder) => ({
	name: 'Component - New Parasite Tracker Map',
	codename: NEW_PARASITE_TRACKER_MAP_CODENAME,
	modelType: 'contentType',
	content_groups: [],
	elements: [
		builder.guidelinesElement({
			codename: 'usage_guidance',
			type: 'guidelines',
			guidelines:
				'<p>The Parasite Map is a service created to track cases of Lungworm, Blowfly and Parvo. To propose new disease integrations, please contact respective lifecycle engineers.</p>' +
				'<p>Available Setups:</p>' +
				'<ul><li>Radius Map - Radius capability is enabled, displaying a set circumference and the respective amount of cases identified within its border, alongside a toggleable Pins feature that identifies the exact location of each individual case.</li> <li>Heatmap - Heatmap capability is enabled, displaying a heat based map, with darkening colours as the amount of cases increases in any given location.</li></ul>' +
				'<p>Note: Both functionalities can be enabled in conjuction as "Heatmap and Markers" if use case demands.</p>',
		}),
		builder.multipleChoiceElement({
			mode: 'single',
			options: [
				{codename: 'blowfly', name: 'Blowfly'},
				{codename: 'lungworm', name: 'Lungworm'},
				{codename: 'parvo', name: 'Parvo'},
			],
			name: 'Parasite Type',
			guidelines:
				'This is used to retrieve the correct parasite case data',
			is_required: true,
			type: 'multiple_choice',
			codename: 'parasite_type',
		}),
		builder.multipleChoiceElement({
			mode: 'single',
			options: [
				{codename: HEATMAP_AND_MARKERS, name: 'Heatmap and Markers'},
				{codename: MARKERS, name: 'Markers'},
				{codename: HEATMAP, name: 'Heatmap'},
			],
			name: 'Map Type',
			guidelines:
				'Selecting "Heatmap" will only show the heatmap overlay and no markers or info windows',
			is_required: true,
			type: 'multiple_choice',
			codename: 'map_type',
		}),
		builder.numberElement({
			name: 'Heatmap Opacity',
			guidelines: 'The opacity of the heatmap overlay, value range 0-1',
			is_required: true,
			type: 'number',
			codename: 'heatmap_opacity',
		}),
		builder.multipleChoiceElement({
			mode: 'multiple',
			options: [
				{
					codename: 'administrative_layer_1',
					name: 'Layer 1 (States)',
				},
				{
					codename: 'administrative_layer_2',
					name: 'Layer 2 (Counties)',
				},
			],
			name: 'Layers',
			guidelines:
				'The administrative layers which will be rendered. By default, both layers will be shown if no value is specified.',
			is_required: false,
			type: 'multiple_choice',
			codename: 'layers',
		}),
		// builder.textElement({
		// 	name: 'Marker Accent Colour',
		// 	guidelines:
		// 		'Hex accent colour of markers, clusters and search location icon',
		// 	is_required: false,
		// 	type: 'text',
		// 	codename: 'marker_accent_colour',
		// }),
		builder.numberElement({
			name: 'Latitude',
			guidelines:
				'The latitude for the initial map load should be in range -90 to 90',
			is_required: true,
			type: 'number',
			codename: 'latitude',
		}),
		builder.numberElement({
			name: 'Longitude',
			guidelines:
				'The longitude for the initial map load should be in range -180 to 180',
			is_required: true,
			type: 'number',
			codename: 'longitude',
		}),
		builder.numberElement({
			name: 'Zoom Level',
			guidelines:
				'The zoom level for initial map load the maximum zoom level is 22 and minimum of 0 and in general default zoom level would be around 10',
			is_required: true,
			type: 'number',
			codename: 'zoom_level',
		}),
		builder.dateTimeElement({
			name: 'Start date',
			guidelines:
				'Lungworm map only: Specify the start date to be used. If no start date is supplied it will default to 1 year back from today. (Start date should be less than end date)',
			is_required: false,
			type: 'date_time',
			codename: 'start_date',
		}),
		builder.dateTimeElement({
			name: 'End date',
			guidelines:
				"Lungworm map only: Specify the end date to be used. If no end date is supplied it will default to today's date. (End date should be greater than start date)",
			is_required: false,
			type: 'date_time',
			codename: 'end_date',
		}),
		builder.textElement({
			name: 'Single Case Title',
			guidelines:
				'Single case title for info window (only used on heatmap and markers map)',
			is_required: false,
			type: 'text',
			codename: 'single_case_title',
		}),
		builder.textElement({
			name: 'Single Case Subtitle',
			guidelines:
				'Single case subtitle for info window (only used on heatmap and markers map)',
			is_required: false,
			type: 'text',
			codename: 'single_case_subtitle',
		}),
		builder.textElement({
			name: 'Multiple Case Title',
			guidelines:
				'Multiple case title for info window (only used on heatmap and markers map)',
			is_required: false,
			type: 'text',
			codename: 'multiple_case_title',
		}),
		builder.textElement({
			name: 'Multiple Case Subtitle',
			guidelines:
				'Multiple case subtitle for info window (only used on heatmap and markers map)',
			is_required: false,
			type: 'text',
			codename: 'multiple_case_subtitle',
		}),
		builder.textElement({
			name: 'Search Placeholder Text',
			guidelines: 'The text that appears in the map search input',
			is_required: false,
			type: 'text',
			codename: 'placeholder_text',
		}),
		builder.linkedItemsElement({
			item_count_limit: {value: 1, condition: 'exactly'},
			allowed_content_types: [{codename: 'legends'}],
			name: 'Map Legend',
			is_required: true,
			type: 'modular_content',
			codename: 'map_legend',
		}),
		builder.textElement({
			name: 'Error Message',
			guidelines:
				'An error message displayed to the user if the map fails',
			is_required: false,
			type: 'text',
			codename: 'error_message',
		}),
		builder.numberElement({
			name: 'County border width',
			guidelines: 'The width of the border of a county',
			is_required: false,
			type: 'number',
			codename: 'county_border_width',
		}),
		builder.textElement({
			name: 'County border color',
			guidelines: 'The color of the border of a county',
			is_required: false,
			type: 'text',
			codename: 'county_border_color',
		}),
		builder.numberElement({
			name: 'State border width',
			guidelines: 'The width of the border of a state',
			is_required: false,
			type: 'number',
			codename: 'state_border_width',
		}),
		builder.textElement({
			name: 'State border color',
			guidelines: 'The color of the border of a state',
			is_required: false,
			type: 'text',
			codename: 'state_border_color',
		}),
		builder.numberElement({
			name: 'Radius (miles)',
			guidelines: 'The radius of the search result in miles',
			is_required: false,
			type: 'number',
			codename: 'radius',
		}),
		builder.multipleChoiceElement({
			mode: 'single',
			options: [
				{codename: 'yes', name: 'Yes'},
				{codename: 'no', name: 'No'},
			],
			name: 'Hide component on login',
			guidelines:
				'Select the option to hide the component if the user is logged in.',
			is_required: false,
			type: 'multiple_choice',
			codename: 'hide_component_on_login_enabled',
		}),
	],
})

export default modelParasiteTrackerMap
