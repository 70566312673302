import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import Image from 'next/image'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import type {RichTextContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/rich-text'
import type {CtaButtonContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/cta-button'
import {ReusableCTAButtonComponent} from '@/_new-code/products/flexible-web-toolkit/blocks/cta-button'
import {RichTextBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/rich-text'
import {loadImageFromKontentAI} from '@/imageLoader'
import {logMessage} from '@/services/client-logger'

type CountryFlagItemContentItem = IContentItem<{
	flagImage: Elements.AssetsElement
	localeUrl: Elements.TextElement
	localeName: Elements.TextElement
}>

export type CountrySelectorContentItem = IContentItem<{
	countryFlagItems: Elements.LinkedItemsElement<CountryFlagItemContentItem>
	towerLinks: Elements.LinkedItemsElement<CtaButtonContentItem>
	disclaimer: Elements.LinkedItemsElement<RichTextContentItem>
}>

export const CountrySelectorBlock: Block<CountrySelectorContentItem> = ({
	block,
	...context
}) => {
	const flags = block.elements.countryFlagItems.map(
		({elements: {flagImage, localeName, localeUrl}}) => {
			const image = flagImage[0]
			return (
				image &&
				localeName &&
				localeUrl && (
					<a
						className="relative w-full hover:bg-gray-200"
						href={localeUrl}
						onClick={() => {
							logMessage(
								`Flag clicked: {locale: "${localeName}", codename: "${localeUrl}"}`
							)
						}}
					>
						<div className="flex flex-nowrap items-center justify-start gap-3 p-2">
							<Image
								alt={localeName}
								className="flex-start flex-shrink-0"
								height={50}
								loader={loadImageFromKontentAI}
								src={image.url}
								width={50}
							/>
							<span className="break-words">{localeName}</span>
						</div>
					</a>
				)
			)
		}
	)

	return (
		<div className="my-6 flex flex-col items-center justify-center">
			<div className="mx-auto flex max-w-[1024px] flex-col items-center justify-center">
				<hr className="h-px w-full border-0 bg-gray-400 dark:bg-gray-700" />
				<div
					className="my-6 grid auto-rows-fr grid-cols-2 items-center justify-items-start gap-3 gap-y-4 px-5 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5"
					data-kontent-element-codename="flags"
				>
					{flags}
				</div>
				<hr className="h-px w-full border-0 bg-gray-400 dark:bg-gray-700" />
				<div className="my-6 grid w-full items-center justify-items-center gap-2 sm:grid-cols-1 md:grid-cols-2">
					{block.elements.towerLinks.map((button) => (
						<div
							className="w-content my-3 flex flex-row items-center justify-center"
							data-kontent-item-id={button.system.id}
							key={button.system.id}
						>
							<ReusableCTAButtonComponent
								block={button}
								{...context}
							/>
						</div>
					))}
				</div>
				<div
					className="mt-5 text-lg sm:mx-5"
					data-kontent-element-codename="disclaimer"
				>
					{block.elements.disclaimer[0] ? (
						<RichTextBlock
							block={block.elements.disclaimer[0]}
							richTextRendererClassName="container-narrow"
							{...context}
						/>
					) : null}
				</div>
			</div>
		</div>
	)
}
