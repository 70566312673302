import type {JSX} from 'react'
import type {Tersed} from '@/_new-code/services/kontent-ai/types'
import type {
	SharedQuestionProps,
	SingleSelectionContentItem,
} from '@/_new-code/products/campaign-forms/cdc-screen-set-wrapper/models'
import {insertAsterisk} from '../../../../../utils/helpers'
import {useCampaignQuestion} from './hooks'

interface SingleSelectRadioButtonsProps extends SharedQuestionProps {
	question: Tersed<SingleSelectionContentItem>
}

export const SingleSelectRadioButtons = ({
	question,
	selectedOptionsCallback,
	injectedElementsCallback,
	errorsCallback,
	validationEvent,
}: SingleSelectRadioButtonsProps): JSX.Element => {
	const {required, questionKontentItemId, displayError, setSelectedItems} =
		useCampaignQuestion({
			question,
			selectedOptionsCallback,
			injectedElementsCallback,
			errorsCallback,
			validationEvent,
		})

	const radioButtonName = `radio-${questionKontentItemId}`
	injectedElementsCallback(radioButtonName)

	return (
		<div
			className="gigya-composite-control gigya-composite-control-multi-choice opt-group"
			id={questionKontentItemId}
		>
			<span
				className="gigya-label-text"
				id={`span-${questionKontentItemId}`}
			>
				<div
					dangerouslySetInnerHTML={{
						__html: insertAsterisk(
							question.elements.questionText.value,
							required
						),
					}}
				/>
			</span>
			{required ? (
				// eslint-disable-next-line jsx-a11y/label-has-associated-control -- necessary
				<label aria-hidden="true" className="hidden">
					*
				</label>
			) : null}
			{question.elements.selections.map((selection) => {
				const labelId = `label-${selection.system.id}`
				const radioButtonId = `radio-${selection.system.id}`
				injectedElementsCallback(labelId)
				injectedElementsCallback(radioButtonId)
				return (
					<div
						className="gigya-multi-choice-item"
						key={selection.system.id}
					>
						<input
							className="gigya-input-radio fd-radio"
							id={radioButtonId}
							name={radioButtonName}
							onChange={(evt) => {
								const {checked} = evt.target
								if (checked) {
									setSelectedItems([evt.target.value])
								}
							}}
							type="radio"
							value={selection.elements.selectionValue}
						/>
						<label htmlFor={radioButtonId} id={labelId}>
							{selection.elements.displayValue}
						</label>
					</div>
				)
			})}
			{displayError ? (
				<span className="gigya-error-msg gigya-error-msg-active">
					This field is required
				</span>
			) : null}
		</div>
	)
}
