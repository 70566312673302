import type {SyntheticEvent} from 'react'
import {useEffect, useState} from 'react'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {useRouter} from 'next/router'
import {EllipseButton} from '@/_new-code/products/flexible-web-toolkit/components/button/ellipse-button'
import {ButtonIcons} from '@/_new-code/components/button-icons/button-icon'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {Header} from '@/_new-code/products/flexible-web-toolkit/components/block-header'

export type SearchHeaderBlockContentItem = IContentItem<{
	title: Elements.TextElement
	description: Elements.TextElement
	placeholder: Elements.TextElement
	searchResultsPagePath: Elements.TextElement
}>

export const SearchHeaderBlockBlock: Block<SearchHeaderBlockContentItem> = ({
	block,
}) => {
	const [searchQuery, setSearchQuery] = useState('')
	const {locale = ''} = useRouter()

	const handleSubmit = (e: SyntheticEvent): void => {
		e.preventDefault()
		if (block.elements.searchResultsPagePath !== '') {
			const url = new URL(
				`${locale}/${
					block.elements.searchResultsPagePath
				}?q=${encodeURIComponent(searchQuery)}`,
				`${window.location.protocol}//${window.location.host}`
			)
			window.location.replace(url)
		} else {
			const url = new URL(window.location.href)
			const params = url.searchParams
			params.set('q', searchQuery)
			window.history.pushState(null, '', url)
			window.dispatchEvent(new Event('popstate'))
		}
	}

	useEffect(() => {
		const params = new URL(window.location.href).searchParams
		const query = params.get('q')
		setSearchQuery(query ?? '')
	}, [])

	return (
		<Header className="text-center" noTheme>
			<h1>{block.elements.title}</h1>
			{block.elements.description.length > 0 ? (
				<p className="max-w-2xl pt-4">{block.elements.description}</p>
			) : null}

			<form
				className="relative mx-auto mt-16 min-w-[90%] max-w-full md:w-2/5"
				onSubmit={(e) => {
					handleSubmit(e)
				}}
			>
				<label htmlFor="search-header-input">
					<span className="sr-only">Search</span>
					<input
						className="mb-0 text-black"
						id="search-header-input"
						onChange={(e) => {
							setSearchQuery(e.target.value)
						}}
						placeholder={block.elements.placeholder}
						type="text"
						value={searchQuery}
					/>
				</label>
				<EllipseButton
					as="button"
					className="absolute inset-y-0 right-0 my-auto mr-2"
					icon={ButtonIcons.Search}
					iconSize="w-6 h-6"
					size="sm"
					type="submit"
				>
					Search
				</EllipseButton>
			</form>
		</Header>
	)
}
