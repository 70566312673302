import type {ReactElement, ReactNode} from 'react'
import {Children, cloneElement, useEffect, useRef, useState} from 'react'
import {UIDConsumer, UIDFork} from 'react-uid'
import Sticky from 'react-stickynode'
import {EllipseButton, ButtonIcons} from '@elanco/component-library-v2'
import styles from './tabs.module.css'

interface ChildrenProps {
	title: string
	id: string
	pinned: boolean
	active: boolean
	expanded: boolean
	closeBtnHandler: () => void
	label: string
	panel: string
	headerHeight: number
	changeTab: () => void
}
interface TabsProps {
	children: ReactElement[]
	expanded?: boolean
	selected?: number
	headerHeight?: number
	className?: string
	tabClasses?: string
	ariaLabel?: string
	id?: string
	name?: string
	isGrowable?: boolean
}

export const Tabs = ({
	expanded: expand = true,
	selected = 0,
	tabClasses = 'mx-2 px-4 py-4 rounded-t-lg text-center max-w-1/2 md:px-6 md:w-1/3 lg:w-3/12',
	ariaLabel = '',
	className = '',
	id: elementId = '',
	headerHeight = 0,
	isGrowable = false,
	name: tabListName = '',
	children,
}: TabsProps): ReactElement => {
	const childrenArray = Children.toArray(
		children
	) as ReactElement<ChildrenProps>[]
	const [expanded, setExpanded] = useState<boolean>(expand)
	const [active, setActive] = useState<string | number>(
		!expanded || selected ? -1 : childrenArray[selected]?.props?.title || ''
	)
	const [tabFocus, setTabFocus] = useState<number>(!expanded ? -1 : selected)
	const [spacerHeight, setSpacerHeight] = useState<number>(0)
	const [width, setWidth] = useState<number>(0)
	const [height, setHeight] = useState<number>(0)
	const [tabsList, setTabsList] = useState<HTMLDivElement>()
	const [leftArrowBlock, setLeftArrowBlock] = useState<HTMLElement>()
	const [rightArrowBlock, setRightArrowBlock] = useState<HTMLElement>()
	const tabWidth = 275
	const node = useRef<HTMLDivElement | null>(null)
	const tabs: ReactNode[] = []
	const panels: ReactNode[] = []
	const pinned: ReactNode[] = []
	const tabBarHeight = 0 // 58 If you only want pinned tabs to hide when main tabs are 100% visible
	const stickyTop = height - tabBarHeight - spacerHeight
	const tabsListRef = useRef<HTMLDivElement | null>(null)
	const leftArrowBlockRef = useRef<HTMLDivElement | null>(null)
	const rightArrowBlockRef = useRef<HTMLDivElement | null>(null)

	const onHandleTabClick = (
		title: string,
		index: number,
		callback?: () => void
	): void => {
		if (childrenArray.find((child) => child.props.title === title)) {
			setActive(title)
		}
		setTabFocus(index)
		setExpanded(true)
		if (callback) callback()
	}

	const changeTab = (title: string, index: number): void => {
		setExpanded(true)
		setTabFocus(index)
		setActive(title)
	}

	const closeBtnHandler = (): void => {
		setExpanded(false)
	}

	const updateDimensions = (): void => {
		setWidth(window.innerWidth || 0)
		setHeight(window.innerHeight || 0)
	}

	const scrollToTab = (id: string): void => {
		// Get pixel offset of tab block
		const currentElement = document.getElementById(id)
		const el: HTMLElement | null = currentElement
			? currentElement.closest('.js-tabs-container')
			: document.querySelector('.js-tabs-container')
		if (!el) return

		// Get pixel height of header element, plus 10px to account for it's margin
		const header = document.querySelector('header')
		const hh = (header?.offsetHeight || 0) + 10 || 0

		const scrollSettings = {
			top: el.offsetTop - hh,
			left: 0,
			behavior: 'smooth' as ScrollBehavior,
		}
		window.scroll(scrollSettings)
	}

	const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>): void => {
		let tempTabFocus = tabFocus
		if (e.key === 'ArrowRight' || e.key === 'ArrowLeft') {
			if (e.key === 'ArrowRight') {
				tempTabFocus += 1
				// If we're at the end, go to the start
				if (tempTabFocus >= tabs.length) {
					tempTabFocus = 0
				}
				// Move left
			} else {
				tempTabFocus -= 1
				// If we're at the start, move to the end
				if (tempTabFocus < 0) {
					tempTabFocus = tabs.length - 1
				}
			}
			setTabFocus(tempTabFocus)
		}
	}

	const onRightArrowClicked = (): void => {
		if (tabsList) tabsList.scrollLeft += 100
		if (leftArrowBlock) {
			leftArrowBlock.classList.remove('hidden')
			leftArrowBlock.classList.add('block')
			leftArrowBlock.style.background =
				'linear-gradient(to right, #FFFFFF 75%, transparent)'
		}
		if (
			tabsList &&
			tabsList.scrollLeft >=
				tabsList.scrollWidth - tabsList.offsetWidth - 100
		) {
			if (rightArrowBlock) {
				rightArrowBlock.classList.remove('block')
				rightArrowBlock.classList.add('hidden')
				rightArrowBlock.style.removeProperty('background')
			}
		}
	}

	const onLeftArrowClicked = (): void => {
		if (tabsList) tabsList.scrollLeft -= 100
		if (rightArrowBlock) {
			rightArrowBlock.classList.remove('hidden')
			rightArrowBlock.classList.add('block')
			rightArrowBlock.style.background =
				'linear-gradient(to left, #FFFFFF 75%, transparent)'
		}
		if (tabsList && tabsList.scrollLeft <= 100 && leftArrowBlock) {
			leftArrowBlock.classList.remove('block')
			leftArrowBlock.classList.add('hidden')
			leftArrowBlock.style.removeProperty('background')
		}
	}

	childrenArray.forEach((child, index) => {
		const tabIsActive = active === child.props.title
		let tabId = ''

		tabs.push(
			// eslint-disable-next-line react/no-array-index-key -- need to have key mentioned below
			<UIDConsumer key={`${child.props.title}-tabs-${index}`}>
				{(id, uid) => {
					tabId = id // Note: tabId scope is outer function
					return (
						<button
							aria-controls={child.props.id || `panel${id}`}
							aria-selected={tabIsActive}
							className={`mt-5 ${tabClasses} ${
								tabIsActive
									? 'text-main bg-main font-bold '
									: 'bg-gray-200 text-blue-900 hover:bg-white'
							}`}
							id={`tab${id}`}
							key={uid(child)}
							onClick={() => {
								onHandleTabClick(child.props.title, index)
							}}
							role="tab"
							style={{
								flexShrink: 0,
								flexGrow: isGrowable ? 1 : undefined,
								boxShadow: '0px -6px 12px 0px rgb(0 0 0 / 6%)',
							}}
							tabIndex={tabIsActive ? 0 : -1}
							type="button"
						>
							{child.props.title}
						</button>
					)
				}}
			</UIDConsumer>
		)

		panels.push(
			// eslint-disable-next-line react/no-array-index-key -- need key in that way
			<UIDConsumer key={`${child.props.title}-tabs-${index}`}>
				{(id, uid) => {
					return (
						<>
							{cloneElement(child, {
								key: uid(child),
								active: String(active) === child.props.title,
								expanded,
								closeBtnHandler,
								label: child.props.id || `tab${tabId}`,
								panel: child.props.id || `panel${tabId}`,
								headerHeight,
								changeTab: () => {
									changeTab(child.props.title, index)
								},
							})}
						</>
					)
				}}
			</UIDConsumer>
		)

		if (child.props.pinned) {
			pinned.push(
				// eslint-disable-next-line react/no-array-index-key -- need to have key mentioned below
				<UIDConsumer key={`${child.props.title}-tabs-${index}`}>
					{(id, uid) => {
						return (
							<>
								{width > tabWidth * (index + 1) && (
									<button
										className="mx-2 w-60 cursor-default rounded-t-lg bg-gray-200 py-4 text-center text-blue-900 hover:bg-gray-300 lg:w-80"
										id={`pinnedTab${id}`}
										key={uid(child)}
										onClick={() => {
											onHandleTabClick(
												child.props.title,
												index,
												() => {
													scrollToTab(
														child.props.id ||
															`panel${tabId}`
													)
												}
											)
										}}
										style={{
											boxShadow:
												'0px -6px 12px 0px rgb(0 0 0 / 6%)',
										}}
										type="button"
									>
										{child.props.title}
									</button>
								)}
							</>
						)
					}}
				</UIDConsumer>
			)
		}
	})

	useEffect(() => {
		// Set initial state on mount
		setWidth(window.innerWidth || 0)
		setHeight(window.innerHeight || 0)
		setTabsList(tabsListRef.current || undefined)
		setLeftArrowBlock(leftArrowBlockRef.current || undefined)
		setRightArrowBlock(rightArrowBlockRef.current || undefined)

		// Handle window resize
		window.addEventListener('resize', updateDimensions)

		// Check if spacer node exists and observe it
		const spacerNode =
			document.getElementById('sticky_bottom_nav_spacer') ||
			document.getElementById('our_sticky_bottom_nav_spacer')
		if (spacerNode) {
			const resizeObserver = new ResizeObserver((entries) => {
				const rect = entries[0]?.contentRect
				if (rect && height !== spacerHeight) {
					const {height: h} = rect
					setSpacerHeight(h)
				}
			})
			resizeObserver.observe(spacerNode)
		}

		// Cleanup on component unmount
		return () => {
			window.removeEventListener('resize', updateDimensions)
		}
	}, [tabListName, spacerHeight, height])

	useEffect(() => {
		if (
			tabsList &&
			rightArrowBlock &&
			tabsList.offsetWidth < tabsList.scrollWidth
		) {
			rightArrowBlock.classList.remove('hidden')
			rightArrowBlock.classList.add('block')
			rightArrowBlock.style.background =
				'linear-gradient(to left, #FFFFFF 75%, transparent)'
		}
	}, [rightArrowBlock, tabsList])

	return (
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions -- need this for strict migration
		<div
			className={`js-tabs-container flex flex-col ${className}`}
			id={elementId}
			onKeyDown={handleKeyPress}
			ref={node}
		>
			<UIDFork>
				{Boolean(pinned.length) && (
					<Sticky enableTransforms={false} top={stickyTop}>
						{({status}) => {
							return (
								<>
									{status === Sticky.STATUS_ORIGINAL && (
										<div
											className="fixed z-50 bg-transparent"
											id="pinnedTabsBar"
											style={{
												bottom: `${spacerHeight}px`,
											}}
										>
											{pinned}
										</div>
									)}
								</>
							)
						}}
					</Sticky>
				)}
				<div
					className="absolute mt-5 hidden h-9 w-10"
					id={`leftArrowBlock_${tabListName}`}
					ref={leftArrowBlockRef}
				>
					<EllipseButton
						className="ml-2 mt-2"
						// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- need to have icon like that
						icon={ButtonIcons.ArrowLeft}
						iconSize="w-3 h-3"
						onClick={onLeftArrowClicked}
						size="w-4 h-4"
					/>
				</div>
				<div
					aria-label={ariaLabel}
					className={`${styles.scrollBar} flex overflow-x-auto scroll-smooth pb-0`}
					id={tabListName}
					ref={tabsListRef}
					role="tablist"
				>
					{tabs}
				</div>
				<div
					className="absolute right-0 mt-5 hidden h-9 w-10"
					id={`rightArrowBlock_${tabListName}`}
					ref={rightArrowBlockRef}
				>
					<EllipseButton
						className="ml-4 mt-2"
						// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- need to have icon like that
						icon={ButtonIcons.ArrowRight}
						iconSize="w-3 h-3"
						onClick={onRightArrowClicked}
						size="w-4 h-4"
					/>
				</div>
				{panels}
			</UIDFork>
		</div>
	)
}
