import Image from 'next/image'
import React, {type JSX} from 'react'
import {loadImageFromKontentAI} from '@/imageLoader'

interface TableMetaIconProps {
	iconAlt?: string
	iconUrl?: string
	text?: string
}

export const TableMetaIcon: React.FC<TableMetaIconProps> = ({
	iconAlt = '',
	iconUrl = '',
	text,
}: TableMetaIconProps): JSX.Element => (
	<span className="flex-col items-center justify-end md:flex">
		{iconUrl ? (
			<Image
				alt={iconAlt}
				className="hidden aspect-square max-w-[130px] md:block"
				height={100}
				loader={loadImageFromKontentAI}
				src={iconUrl}
				width={100}
			/>
		) : null}
		<span className={`inline-block ${iconUrl && 'mt-2'}`}>{text}</span>
	</span>
)
