import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {clsx} from 'clsx'
import Image from 'next/image'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {loadImageFromKontentAI} from '@/imageLoader'
import {RichTextRenderer} from '@/_new-code/products/flexible-web-toolkit/components/rich-text-renderer'
import {Visual} from '@/_new-code/products/flexible-web-toolkit/components/visual/visual'
import {
	ReusableCTAButtonComponent,
	type CtaButtonContentItem,
} from '../cta-button'

export type SimpleListerBlockContentItem = IContentItem<{
	title: Elements.TextElement
	description: Elements.RichTextElement
	image: Elements.AssetsElement
	button: Elements.LinkedItemsElement<CtaButtonContentItem>
	imageAlignment: Elements.MultipleChoiceElement
	textAlignment: Elements.MultipleChoiceElement
}>

export const SimpleListerBlockBlock: Block<SimpleListerBlockContentItem> = ({
	block,
	...context
}) => {
	const textAlignment = block.elements.textAlignment[0]?.codename === 'center'
	return (
		<Visual
			buttonSection={
				block.elements.button[0] ? (
					<div className="flex flex-wrap">
						{block.elements.button.map((button, index) => (
							<div className="flex" key={button.system.id}>
								<div
									className={clsx(
										`mb-2 flex flex-shrink flex-col justify-center lg:mb-0`,
										index === 0 ? 'mr-1' : 'lg:ml-1'
									)}
								>
									<ReusableCTAButtonComponent
										block={button}
										{...context}
									/>
								</div>
							</div>
						))}
					</div>
				) : (
					// eslint-disable-next-line react/jsx-no-useless-fragment -- Design System expects a fragment, not null
					<></>
				)
			}
			image={
				block.elements.image[0] ? (
					<Image
						alt={block.elements.image[0].description || ''}
						height={block.elements.image[0].height || 300}
						loader={loadImageFromKontentAI}
						src={block.elements.image[0].url}
						width={block.elements.image[0].width || 500}
					/>
				) : null
			}
			infoSection={
				<RichTextRenderer
					className={clsx(
						'content m-0 py-6',
						textAlignment && 'md:pb-0 md:pt-6'
					)}
					data-kontent-element-codename="description"
					element={block.elements.description}
					{...context}
				/>
			}
			reversed={block.elements.imageAlignment[0]?.codename === 'left'}
			textAlignmentCenter={textAlignment}
			title={<h3>{block.elements.title}</h3>}
		/>
	)
}
