import React, {
	useEffect,
	useState,
	useRef,
	isValidElement,
	cloneElement,
	Children,
	type JSX,
} from 'react'
import type {TabItem} from 'react-responsive-tabs'
import Tabs from 'react-responsive-tabs'
import {EllipseButton} from '@elanco/component-library-v2'
import {ButtonIcons} from '@/_new-code/components/button-icons/button-icon'
import styles from './tabbed.content-block.module.css'

interface TabProps {
	className?: string
	children: React.ReactNode
	icon?: React.ReactNode | null
	title: React.ReactNode
	disableOverflow?: boolean
	color?: {
		choice?: string
	}
}

const Tab = ({
	/* eslint-disable @typescript-eslint/no-unused-vars -- For markup */
	className = '',
	children,
	icon = null,
	title,
	disableOverflow,
	color = {},
	/* eslint-enable @typescript-eslint/no-unused-vars -- Enable rule */
}: TabProps): JSX.Element => {
	// This doesn't return anything, just used solely for passing in props etc in the markup
	return <> </>
}

interface TabbedContentBlockProps {
	className?: string
	children: React.ReactElement<TabProps>[]
	populateOverviewTab?: boolean
	overviewTabTitle?: string
	onChange?: (index: number | string) => void
	activeTabIndex?: number
	tabMobileView?: 'stack' | 'scroll'
	tabColorStyles?: Record<string, Color>
}

const TabbedContentBlock = ({
	className = '',
	children,
	populateOverviewTab = false,
	overviewTabTitle = 'Overview',

	activeTabIndex = 0,
	onChange = () => undefined,
	tabMobileView = 'stack',
	tabColorStyles = {},
}: TabbedContentBlockProps): JSX.Element => {
	const leftArrowRef = useRef<HTMLDivElement>(null)
	const rightArrowRef = useRef<HTMLDivElement>(null)
	const isTabScrollable = tabMobileView === 'scroll'
	const [currentTab, setCurrentTab] = useState(activeTabIndex)
	const [expandedTab, setExpandedTab] = useState<number | null>(0)

	useEffect(() => {
		document.querySelector('.RRT__tabs')?.setAttribute('role', 'tablist')
	})

	const handleOnChange = (index: number | string): void => {
		if (expandedTab === index) {
			setExpandedTab(null)
		} else {
			setExpandedTab(Number(index))
		}
		setCurrentTab(Number(index))
		onChange(index)
	}

	const getTitleWithIcon = (
		icon: React.ReactNode | null,
		title: React.ReactNode,
		index: number
	): JSX.Element => {
		const getStyle = (): Record<string, string> => {
			if (expandedTab === index) {
				return {
					transform: 'rotate(45deg)',
				}
			}
			return {
				transform: 'rotate(0)',
			}
		}

		return (
			<>
				<svg
					className="mr-4 h-5 w-5 fill-current transition-transform duration-300 md:hidden lg:hidden"
					height="26.883"
					style={getStyle()}
					viewBox="0 0 26.883 26.883"
					width="26.883"
				>
					<path
						d="M1.9,18.729l-1.6-1.595a1,1,0,0,1,0-1.414l6.2-6.2-6.2-6.2a1,1,0,0,1,0-1.414L1.9.306a1,1,0,0,1,1.415,0l6.2,6.2,6.2-6.2a1,1,0,0,1,1.415,0l1.595,1.6a1,1,0,0,1,0,1.414l-6.2,6.2,6.2,6.2a1,1,0,0,1,0,1.414l-1.595,1.595a1,1,0,0,1-1.415,0l-6.2-6.2-6.2,6.2a1,1,0,0,1-1.414,0Z"
						transform="translate(13.442 -0.018) rotate(45)"
					/>
				</svg>
				{/* This is an icon that could be passed from story/module call */}
				{icon && isValidElement(icon)
					? cloneElement(
							icon as React.ReactElement<{className: string}>,
							{
								className: 'mr-4 shrink-0',
							}
						)
					: null}
				<p>{title}</p>
			</>
		)
	}

	// Dynamically create an overview tab using the content from other tabs passed from the module call
	const getOverviewTab = (): React.ReactElement<TabProps> => {
		return (
			<Tab title={overviewTabTitle}>
				{children.map(
					(tab, index) =>
						isValidElement(tab) && (
							<div key={tab.key}>
								<h3 className={index > 0 ? 'mt-6' : ''}>
									{tab.props.title}
								</h3>
								{Children.toArray(
									tab.props.children as React.ReactNode
								).map((child) => child)}
							</div>
						)
				)}
			</Tab>
		)
	}

	// Getting tabs content to pass to <Tabs> as data
	const getTabs = (): (TabItem & {
		tabClassNames: Record<string, string>
	})[] => {
		// Append the generated overview tab content based on the populateOverviewTab property
		const clonedChildren = populateOverviewTab
			? [getOverviewTab()].concat(children as JSX.Element[])
			: children

		return clonedChildren.map((tab, index) => {
			const colorChoice = tab.props.color?.choice as keyof Color
			const colorStyles = tabColorStyles[colorChoice] || {
				defaultStyle: 'bg-gray-400 text-gray-900 hover:bg-gray-300',
				collapsed: 'bg-gray-300 text-blue-900',
				selected: 'bg-gray-200 text-blue-900',
			}

			const tabClassNames = {
				default: `${colorStyles.defaultStyle} text-sm py-4 px-6 flex-grow flex-1 border-l-2 border-gray-500 cursor-pointer flex items-center`,
				collapsed: `${colorStyles.collapsed} border-l-0 border-b`,
				selected: `${colorStyles.selected} font-bold`,
			}

			return {
				title: getTitleWithIcon(tab.props.icon, tab.props.title, index),
				getContent: () => tab.props.children,
				tabClassNames,
				panelClassName: tab.props.disableOverflow
					? styles.panelNoOverflow
					: styles.panel,
				disableOverflow: tab.props.disableOverflow,
			}
		})
	}

	useEffect(() => {
		if (isTabScrollable) {
			const tabContainer = document.getElementsByClassName(
				'RRT__tabs'
			)[0] as HTMLElement

			const checkScrollPosition = (): void => {
				const {scrollLeft, clientWidth, scrollWidth} = tabContainer
				if (scrollLeft === 0) {
					leftArrowRef.current?.classList.add('hidden')
				}
				if (scrollLeft !== 0 && leftArrowRef.current) {
					leftArrowRef.current.classList.remove('hidden')
					leftArrowRef.current.style.background =
						'linear-gradient(to right, #FFFFFF 75%, transparent)'
				}
				if (scrollWidth > clientWidth && rightArrowRef.current) {
					rightArrowRef.current.classList.remove('hidden')
					rightArrowRef.current.style.background =
						'linear-gradient(to left, #FFFFFF 75%, transparent)'
				}
				if (Math.ceil(scrollLeft) + clientWidth >= scrollWidth) {
					rightArrowRef.current?.classList.add('hidden')
				}
			}

			// Initial check
			checkScrollPosition()

			// Add scroll event listener
			tabContainer.addEventListener('scroll', checkScrollPosition)

			// Cleanup event listener on component unmount
			return () => {
				tabContainer.removeEventListener('scroll', checkScrollPosition)
			}
		}
	}, [isTabScrollable])

	const onRightArrowClicked = (): void => {
		const tabs = document.getElementsByClassName(
			'RRT__tabs'
		)[0] as HTMLElement
		tabs.scrollLeft += 100
	}

	const onLeftArrowClicked = (): void => {
		const tabs = document.getElementsByClassName(
			'RRT__tabs'
		)[0] as HTMLElement
		tabs.scrollLeft -= 100
	}

	return (
		<div>
			<div
				className="absolute left-0 mt-2 hidden h-9 w-10 md:hidden lg:hidden"
				id="leftArrowBlock"
				ref={leftArrowRef}
			>
				<EllipseButton
					className="ml-2 mt-2"
					icon={ButtonIcons.ArrowLeft}
					iconSize="w-3 h-3"
					onClick={onLeftArrowClicked}
					size="w-4 h-4"
				/>
			</div>
			<div
				className="absolute right-0 mt-2 hidden h-9 w-10 md:hidden lg:hidden"
				ref={rightArrowRef}
			>
				<EllipseButton
					className="ml-4 mt-2"
					icon={ButtonIcons.ArrowRight}
					iconSize="w-3 h-3"
					onClick={onRightArrowClicked}
					size="w-4 h-4"
				/>
			</div>
			<Tabs
				containerClass={`${className} overflow-hidden w-full container-wide shadow-secondary rounded-lg`}
				items={getTabs().map((tab, index) => ({
					title: tab.title,
					getContent: tab.getContent,
					tabClassName: `${tab.tabClassNames.default} ${
						index === currentTab ? tab.tabClassNames.selected : ''
					} ${currentTab !== index && tab.tabClassNames.collapsed}`,
					panelClassName: tab.panelClassName,
				}))}
				name="TabbedContentBlock"
				onChange={handleOnChange}
				selected={currentTab}
				showMore={false}
				tabClassName="ABCD"
				tabsWrapperClass={
					isTabScrollable ? styles.tabsScroll : styles.tabsStack
				}
				transform={!isTabScrollable}
			/>
			<section className={styles.tabContent}>
				{getTabs().map((tab, index) => {
					const isActive = index === currentTab
					const tabId = tab.key
					return (
						<div
							aria-hidden={!isActive}
							aria-labelledby={`tab-${tabId}`}
							className={`${
								tab.disableOverflow
									? styles.panelNoOverflow
									: styles.panel
							} ${isActive ? '' : styles.inactiveTab}`}
							key={tabId}
						>
							{!isActive && tab.getContent?.()}
						</div>
					)
				})}
			</section>
		</div>
	)
}

export {TabbedContentBlock, Tab as ContentBlockTab}
