import type {ReactNode, ReactElement} from 'react'
import {cloneElement} from 'react'
import {clsx} from 'clsx'

interface ArticleListerProps {
	authorImage?: ReactElement<{
		className: string
		size?: string
	}>
	bodyMetaBlock?: ReactElement<{
		className: string
		aligned: string
		shadow: boolean
	}>
	children?: ReactNode
	className?: string
	description?: ReactNode
	imageMetablock: ReactElement<{
		className: string
		blur: boolean
	}> | null
	imageUrl?: string | null
	subtitle?: ReactNode
	title: ReactNode
}

export const ArticleLister: React.FC<ArticleListerProps> = ({
	authorImage = null,
	bodyMetaBlock = null,
	children = null,
	className = '',
	description = null,
	imageMetablock = null,
	imageUrl = null,
	subtitle = '',
	title,
}) => {
	const hasBodyMetaBlock = bodyMetaBlock !== null
	return (
		<div
			className={clsx(
				'rounded-lg shadow-secondary md:flex md:flex-row',
				className
			)}
		>
			<div
				className="relative h-56 shrink-0 rounded-t-lg bg-cover bg-center bg-no-repeat sm:h-64 md:h-auto md:w-2/5 md:rounded-l-lg md:rounded-t-none"
				style={{
					backgroundImage: `url(${imageUrl})`,
				}}
			>
				{imageMetablock
					? cloneElement(imageMetablock, {
							className: clsx(
								imageMetablock.props.className,
								'absolute left-0 top-0 rounded-tl-lg overflow-hidden'
							),
							blur: imageMetablock.props.blur,
						})
					: null}
				{authorImage
					? cloneElement(authorImage, {
							className: clsx(
								authorImage.props.className,
								'absolute bottom-0 left-0 transform translate-x-4 translate-y-1/2 md:-translate-x-4 md:translate-y-4'
							),
							size: authorImage.props.size ?? 'md',
						})
					: null}
			</div>
			<div className="h-full">
				<div className="top-0 flex flex-col-reverse flex-wrap items-start md:flex-row md:flex-nowrap md:items-center">
					{subtitle ? (
						<p
							className={clsx(
								'px-4 text-xs',
								authorImage && 'mt-6',
								!authorImage && 'mt-0',
								hasBodyMetaBlock || authorImage
									? 'pt-6 md:pt-0'
									: 'pt-3',
								'md:pl-6'
							)}
						>
							{subtitle}
						</p>
					) : null}
					{hasBodyMetaBlock
						? cloneElement(bodyMetaBlock, {
								className: clsx(
									bodyMetaBlock.props.className,
									'right-0 shrink-0 ml-auto'
								),
								aligned: 'right',
								shadow: false,
							})
						: null}
				</div>
				<div
					className={clsx(
						'p-4 pb-8 md:pl-6',
						hasBodyMetaBlock && 'pt-4'
					)}
				>
					<h3 className="mb-4 text-xl">{title}</h3>
					{description ? (
						<div className="content content--no-width-restriction mb-4 md:mb-8">
							{description}
						</div>
					) : null}
					{children}
				</div>
			</div>
		</div>
	)
}
