import type {QueryFunctionContext} from 'react-query'
import type {FetchBusinessMapDataResponse} from '@/services/FetchBusinessMapData'
import http from '@/utils/axios'
import type {TBusinessMapDataQueryKey} from '@/_new-code/services/business-map-markers/models'
import type {Marker} from '@/_new-code/models/page-props'

export const useFetchBusinessMapMarkers = async (
	context: QueryFunctionContext<TBusinessMapDataQueryKey>
): Promise<Marker[]> => {
	const [, params] = context.queryKey
	const {tagIdsString, typeIdsString, lat, lon, radius} = params

	let offset = 0

	const limitPerPage = 10000
	let hasNextPage = true

	const out = [] as FetchBusinessMapDataResponse['activeMarkers']

	while (hasNextPage) {
		// eslint-disable-next-line no-await-in-loop -- await for response
		const {data} = await http<
			undefined,
			{data: FetchBusinessMapDataResponse}
		>(
			`/api/find-a-business?tagIdsString=${tagIdsString}&typeIdsString=${encodeURIComponent(
				typeIdsString
			)}&limit=${limitPerPage}&offset=${offset}&lat=${lat}&lon=${lon}&radius=${radius}`,
			{timeout: 180000}
		)
		out.push(...data.activeMarkers)

		offset += limitPerPage
		hasNextPage = data.hasNextPage
	}

	return out
}
