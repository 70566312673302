/* eslint-disable jsx-a11y/no-static-element-interactions -- Don't use <div> elements as buttons! */
/* eslint-disable jsx-a11y/click-events-have-key-events -- Don't use <div> elements as buttons! */
import {clsx} from 'clsx'
import type {JSX} from 'react'
import {ButtonIcons} from '@/_new-code/components/button-icons/button-icon'
import {Button} from '@/_new-code/products/flexible-web-toolkit/components/button/button'
import {pushToDataLayer} from '@/utils/analytics'
import type {FilterBarChildProps} from './index'

export const MobileView = (props: FilterBarChildProps): JSX.Element => {
	const {
		setShowMobileFilters,
		showMobileFilters,
		title,
		globalState,
		filterBarKey,
		filterBarOptions,
		additionalFilterBarOptions,
		renderFilterOptions,
		toggleAdditionalFilters,
		showAdditionalFilters,
		additionalFilterTitle,
		checkShowAdditionalFilters,
		resetButtonTitle,
		resetAllFilters,
		closeButtonTitle,
		className,
	} = props

	return (
		<div className={className}>
			<div
				className="z-[1001] mx-auto mb-8 flex max-w-screen-xl flex-col justify-between bg-white xl:fixed xl:left-2/4
				xl:top-2/4 xl:w-3/12 xl:-translate-x-2/4 xl:-translate-y-2/4 xl:transform xl:rounded"
			>
				<div
					className={clsx(
						'bg-gray-100 px-3 py-5 xl:rounded-t',
						showAdditionalFilters ? 'block' : 'xl:hidden'
					)}
				>
					<div className="relative mx-auto w-full max-w-[285px] sm:max-w-screen-md">
						<h3 className="text-black xl:text-center">
							{title || 'Filters'}
						</h3>
						<button
							className="absolute right-0 top-0"
							onClick={() => {
								toggleAdditionalFilters()
								setShowMobileFilters(!showMobileFilters)
							}}
							type="button"
						>
							<svg
								className="hidden h-8 w-8 text-black xl:block"
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M6 18L18 6M6 6l12 12"
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
								/>
							</svg>
							<svg
								className="h-8 w-8 transform text-black xl:hidden"
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
								/>
							</svg>
						</button>
					</div>
				</div>
				<div className="p-4">
					<div
						className={clsx(
							'flex-no-wrap mx-auto max-h-[350px] w-full max-w-[285px] flex-col justify-start space-y-4 overflow-y-scroll px-3 pr-6 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-blue-500 sm:max-w-screen-md xl:px-6',
							showMobileFilters ? 'flex' : 'hidden xl:flex'
						)}
					>
						{globalState?.[filterBarKey]
							? filterBarOptions.map(renderFilterOptions)
							: null}

						{globalState?.[filterBarKey] &&
						showAdditionalFilters &&
						checkShowAdditionalFilters
							? additionalFilterBarOptions.map(
									renderFilterOptions
								)
							: null}
					</div>
				</div>
				<div
					className={clsx(
						'bg-gray-100 py-5 xl:rounded-b',
						showMobileFilters ? 'block' : 'hidden xl:block'
					)}
				>
					<div className="mx-auto grid max-w-[300px] grid-cols-2 items-center gap-4">
						{checkShowAdditionalFilters ? (
							<Button
								as="button"
								className="col-span-full bg-white py-2 xl:hidden"
								onClick={toggleAdditionalFilters}
								title={additionalFilterTitle}
								variant="inverted"
							>
								{additionalFilterTitle}
							</Button>
						) : null}
						<Button
							as="button"
							className="bg-white py-2"
							icon={ButtonIcons.Load}
							onClick={() => {
								resetAllFilters()
								pushToDataLayer({
									event: 'filter_clear',
									filter_name: 'reset',
								})
							}}
							title={resetButtonTitle}
							variant="inverted"
						>
							{resetButtonTitle}
						</Button>
						<Button
							as="button"
							className="inline-block bg-white py-2"
							onClick={() => {
								toggleAdditionalFilters()
								setShowMobileFilters(false)
							}}
							title={closeButtonTitle}
							variant="inverted"
						>
							{closeButtonTitle}
						</Button>
					</div>
				</div>
			</div>
			<div
				className="fixed bottom-0 left-0 right-0 top-0 z-[1000] hidden bg-black/50 xl:block"
				onClick={toggleAdditionalFilters}
			/>
		</div>
	)
}
