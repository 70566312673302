import type {PropsWithChildren, ReactElement} from 'react'
import React, {cloneElement} from 'react'

interface MetaBlockProps {
	// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents -- need to add this
	aligned?: 'left' | 'right' | string
	bgColor?: string
	className?: string
	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Had to due to usage of cloneElement
	image?: ReactElement<any> | null
	isExternalLink?: boolean
	metaBlockHref?: string
	blur: boolean
	metaText: string
}

export const MetaBlock = ({
	aligned = 'left',
	bgColor = '',
	children,
	className = '',
	image,
	isExternalLink = false,
	metaBlockHref,
}: PropsWithChildren<MetaBlockProps>): React.JSX.Element => {
	const styles = {minWidth: '120px'}
	const contentToRender = (
		<div
			className={`text-white ${
				bgColor || 'bg-theme-main'
			} inline-flex items-center justify-center px-4 py-2 text-center ${
				aligned === 'left'
					? 'rounded-br-lg'
					: 'rounded-bl-lg md:rounded-tr-lg'
			} `}
			style={styles}
		>
			{image ? (
				<span className="mr-4 inline-block">
					{cloneElement(image, {
						// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- classname needed
						className: `${image.props.className} inline-block fill-current`,
					})}
				</span>
			) : null}
			{children}
		</div>
	)

	return (
		<div className={className}>
			{metaBlockHref ? (
				<a
					href={metaBlockHref}
					rel="noreferrer"
					target={isExternalLink ? '_blank' : '_self'}
				>
					{contentToRender}
				</a>
			) : (
				contentToRender
			)}
		</div>
	)
}
