import {clsx} from 'clsx'
import Image from 'next/image'
import type {JSX, ReactElement} from 'react'
import {useWindowSize} from '@/_new-code/utilities/hooks/use-window-size'
import {loadImageFromKontentAI} from '@/imageLoader'

interface PackshotImageProps {
	alignment?: 'top' | 'center' | 'bottom'
	alt?: string | null
	className?: string
	mediaAlign?: boolean
	mediaRightPosition?: string
	mediaTopPosition?: string
	src: string
	sizes?: string
	hasMobileImage?: boolean
}

export const PackshotImage = ({
	alignment = 'top',
	alt = '',
	className = '',
	mediaAlign = false,
	mediaRightPosition = '',
	mediaTopPosition = '',
	src,
	hasMobileImage,
}: PackshotImageProps): JSX.Element => {
	const alignments = {
		top: 'top-0',
		center: 'inset-y-0',
		bottom: 'bottom-0',
	}

	const size = useWindowSize()

	const getMediaAlign = (value: string): string => {
		const isNumberBetween0And100 = (number: number): boolean =>
			number >= 0 && number <= 100
		const numberRegex = /^\d+$/
		if (value && mediaAlign)
			return numberRegex.test(value) &&
				isNumberBetween0And100(Number(value))
				? `${value}%`
				: '0%'
		return '0%'
	}

	const getChild = (): ReactElement => {
		const videoClasses = clsx(
			'w-full',
			'min-h-full',
			'object-contain',
			'md:absolute',
			'inset-x-0',
			'm-auto',
			alignments[alignment],
			className
		)
		if (['mp4'].includes(src.split('.').pop() || ''))
			return (
				<video
					autoPlay
					className={videoClasses}
					loop
					muted
					preload="true"
					src={src}
				/>
			)
		return (
			<Image
				alt={alt || ''}
				className={clsx(
					hasMobileImage ? 'absolute' : 'relative md:absolute',
					'z-20',
					'h-auto'
				)}
				height={300}
				loader={loadImageFromKontentAI}
				src={src}
				style={{
					top: !size.isMobile
						? getMediaAlign(mediaTopPosition)
						: '0%',
					right: !size.isMobile
						? getMediaAlign(mediaRightPosition)
						: '0%',
				}}
				width={400}
			/>
		)
	}

	return getChild()
}
