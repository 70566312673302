import type {QueryFunctionContext} from 'react-query'
import {env} from '@/utils/env/client.mjs'
import http from '@/utils/axios'
import type {
	TFaqCodenamesFromCognitiveSearchQueryKey,
	FaqCodenamesFromCognitiveSearchApiOutput,
} from '@/_new-code/services/faq-codenames-from-cognitive-search/models'

export const useFetchFaqCodenamesFromCognitiveSearch = async (
	context: QueryFunctionContext<TFaqCodenamesFromCognitiveSearchQueryKey>
): Promise<string[]> => {
	const [, params] = context.queryKey
	const {searchEndpoint, searchQuery} = params
	const endpoint = searchEndpoint + searchQuery
	const {data} = await http<FaqCodenamesFromCognitiveSearchApiOutput>(
		endpoint,
		{
			headers: {
				'api-key': env.NEXT_PUBLIC_FAQ_COGNITIVE_SEARCH_API_KEY,
			},
		}
	)
	return data.value.map((faq) => faq.id)
}
