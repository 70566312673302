import {clsx} from 'clsx'
import type {JSX, ReactElement, ReactNode} from 'react'
import React, {Children} from 'react'
import Image from 'next/image'
import {loadImageFromKontentAI} from '@/imageLoader'
import {HeaderImage} from './image'
import {PackshotImage} from './packshot-image'

interface HeaderProps {
	bgColor?: string
	bgImageSrc?: string
	children?: ReactNode[] | null
	className?: string
	contain?: boolean
	containerSize?: 'sm' | 'lg' | ''
	fixedBlockHeight?: boolean
	fixedInfoWidth?: boolean
	fullBackgroundImage?: boolean
	hasWhiteInfoContainerBg?: boolean
	infoSectionTopStack?: boolean
	leftAlignContentBlock?: boolean
	light?: boolean
	logoImgAlt?: string | null
	logoImgSrc?: string
	noTheme?: boolean
	reversed?: boolean
	reversedMobile?: boolean
	widget?: React.ReactNode
}

export const Header = ({
	bgColor = 'bg-transparent',
	bgImageSrc = '',
	children = null,
	className = '',
	contain = false,
	containerSize = '',
	fixedBlockHeight = false,
	fixedInfoWidth = false,
	fullBackgroundImage = false,
	hasWhiteInfoContainerBg = false,
	infoSectionTopStack = false,
	leftAlignContentBlock = false,
	light = false,
	logoImgAlt = '',
	logoImgSrc = '',
	noTheme = false,
	reversed = false,
	reversedMobile = false,
	widget = null,
}: HeaderProps): JSX.Element => {
	const childNodes = Children.toArray(children)
	let containerClasses = ''
	const image = childNodes.find(
		(child) => (child as ReactElement).type === HeaderImage
	) as ReactElement | undefined // Type assertion to ReactElement

	const infoSection = childNodes.filter(
		(child) =>
			(child as ReactElement).type !== HeaderImage &&
			(child as ReactElement).type !== PackshotImage
	)

	switch (containerSize) {
		case 'sm':
			containerClasses = 'container-narrow'
			break
		case 'lg':
			containerClasses = 'w-100 pt-0 md:pt-0'
			break
		default:
			containerClasses = `${
				image ? 'max-w-7xl' : 'max-w-5xl'
			} container-wide`
			break
	}

	const infoSectionClassNoImage = leftAlignContentBlock ? 'w-full' : 'mx-auto'

	return (
		<div
			className={clsx(
				{
					[bgColor]: noTheme,
					[light
						? 'bg-gray-200 text-blue-900'
						: 'bg-blue-900 text-white']: !noTheme,
				},
				className
			)}
			style={{
				backgroundImage:
					bgImageSrc !== '' ? `url(${bgImageSrc})` : 'none',
				backgroundPosition: 'center',
				backgroundSize: 'cover',
			}}
		>
			<div
				className={clsx({
					container: contain,
					[containerClasses]: !contain,
					'relative justify-between md:flex md:min-h-[380px] md:py-8':
						true,
					'md:flex-row-reverse': reversed,
					'flex flex-col-reverse md:flex-row': reversedMobile,
					'md:h-[450px]': fixedBlockHeight,
				})}
			>
				<div
					className={clsx('text-main p-8', {
						'md:px-12': bgColor,
						'break-word rounded-lg text-black': true, // These classes are always applied
						'md:max-w-[450px] lg:max-w-[500px]': fixedInfoWidth,
						'md:w-1/2 lg:w-2/5': image,
						[infoSectionClassNoImage]: !fixedInfoWidth && !image,
						'bg-white': !(noTheme && bgColor !== 'bg-transparent'),
						'md:bg-white':
							fullBackgroundImage && hasWhiteInfoContainerBg,
						'md:bg-transparent':
							fullBackgroundImage && !hasWhiteInfoContainerBg,
						'md:relative md:left-12 md:top-14 md:mb-8 xl:left-20 xlg:left-35':
							fullBackgroundImage,
						'md:pl-0': contain,
						'md:z-50': infoSectionTopStack,
					})}
				>
					{logoImgSrc ? (
						<div className="relative h-12">
							<Image
								alt={logoImgAlt || ''}
								className="object-contain object-left"
								fill
								loader={loadImageFromKontentAI}
								src={logoImgSrc}
							/>
						</div>
					) : null}
					{infoSection}
				</div>

				{image ? (
					<div
						className={clsx(
							'relative',
							{
								'md:bottom-auto md:mt-0': reversedMobile,
								'-bottom-28 -mt-16 md:bottom-auto md:mt-0':
									!reversedMobile,
								'left-0': reversed,
								'right-0': !reversed,
							},
							'z-20 h-full md:absolute',
							{
								'md:w-full': fullBackgroundImage,
								'md:w-1/2': !fullBackgroundImage,
							},
							!contain && 'overflow-hidden'
						)}
					>
						{image}
					</div>
				) : null}
			</div>
			{widget ? (
				<div
					className={clsx(
						contain ? 'container' : containerClasses,
						'flex justify-center md:justify-end'
					)}
				>
					{widget}
				</div>
			) : null}
		</div>
	)
}
