import {MapLegends} from '@elanco/component-library-v2'
import {useRef} from 'react'
import type {ExtendedBlock} from '@/_new-code/services/kontent-ai/types'
import {LoadingSpinner} from '@/_new-code/products/flexible-web-toolkit/components/loading-spinner'
import {LoadingOverlay} from '../../loading-overlay'
import {Search} from './search'
import {useParasiteMap} from './use-parasite-map'
import type {
	NewParasiteTrackerMapContentItem,
	ParasiteTrackerMapProps,
} from './types'

export const ParasiteTrackerMap: ExtendedBlock<
	NewParasiteTrackerMapContentItem,
	ParasiteTrackerMapProps
> = (props) => {
	const mapRef = useRef<HTMLDivElement | null>(null)

	const {
		mapLegend,
		setError,
		isLevel1Fetching,
		isLevel1Loading,
		level1ApiError,
		error,
		setShowLoadingOverlay,
		showLoadingOverlay,
		searchHandler,
		errorMessage,
		theme,
		placeholderText,
	} = useParasiteMap({...props, mapRef})

	return (
		<div
			className="relative"
			data-kontent-element-codename="parasite_tracker_map"
		>
			{isLevel1Loading || isLevel1Fetching ? (
				<div className="flex h-[350px] w-full animate-pulse items-center justify-center rounded bg-elanco-blue bg-opacity-20 p-5 sm:h-[700px]">
					<LoadingSpinner theme="blue" />
				</div>
			) : null}
			{!isLevel1Fetching && !isLevel1Loading && (
				<>
					{error || Boolean(level1ApiError) ? (
						<div className="absolute bottom-0 left-0 right-0 top-0 z-50 flex justify-center bg-black bg-opacity-50">
							<div className="m-5 flex self-center rounded-md border border-red-500 bg-red-300 p-3">
								<span className="text-s text-center font-semibold">
									{errorMessage}
								</span>
							</div>
						</div>
					) : null}
					{showLoadingOverlay ? (
						<LoadingOverlay color="#ffffff" />
					) : null}
					<div className="flex justify-center">
						{searchHandler ? (
							<Search
								onError={() => {
									setError(true)
								}}
								onLoading={(value) => {
									setShowLoadingOverlay(value)
								}}
								onSearchApi={searchHandler}
								placeholderText={placeholderText || 'Search'}
								theme={theme}
							/>
						) : null}
					</div>
					<div
						className="h-full min-h-[700px] w-full"
						id="parvomap"
						ref={mapRef}
					/>
					{mapLegend[0] ? (
						<div className="absolute bottom-5 left-5 z-40 w-80">
							<div data-kontent-element-codename="parasite_tracker_legend">
								<MapLegends
									legends={mapLegend[0].elements.legend.map(
										({elements: {color, legendName}}) => ({
											color,
											legend_name: legendName,
										})
									)}
									subTitle={mapLegend[0].elements.subTitle}
								/>
							</div>
						</div>
					) : null}
				</>
			)}
		</div>
	)
}
