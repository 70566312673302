import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {useContext} from 'react'
import {useRouter} from 'next/router'
import {useQuery} from 'react-query'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {FaqBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/faq-block'
import {LoadingSpinner} from '@/_new-code/products/flexible-web-toolkit/components/loading-spinner'
import {GlobalContext} from '@/_new-code/services/global-client-state'
import {useFetchFaqsByCodenames} from '@/_new-code/services/faqs-by-codenames/client'
import {useFetchFaqCodenamesFromCognitiveSearch} from '@/_new-code/services/faq-codenames-from-cognitive-search/client'

export type FaqSearchResultsContentItem = IContentItem<{
	noResultsTitle: Elements.TextElement
	resultsForTitle: Elements.TextElement
}>

export const FaqSearchResultsBlock: Block<FaqSearchResultsContentItem> = ({
	block,
	globalConfig,
	...context
}) => {
	const {locale = ''} = useRouter()
	const gs = useContext(GlobalContext)

	const {data: faqCodenames} = useQuery({
		queryFn: useFetchFaqCodenamesFromCognitiveSearch,
		queryKey: [
			'faqCognitiveSearchResults',
			{
				searchEndpoint: globalConfig.elements.faqSearchIndexUrl,
				searchQuery: gs?.[0]?.faqSearchQuery ?? '',
			},
		],
	})

	const {isLoading, isFetching, isError, data} = useQuery({
		queryKey: ['faqSearchResults', {locale, codenames: faqCodenames}],
		queryFn: useFetchFaqsByCodenames,
		enabled: faqCodenames && faqCodenames.length > 0,
	})

	const formattedResultsForTitle = block.elements.resultsForTitle.replace(
		/{{X}}/,
		gs?.[0]?.faqSearchQuery ?? ''
	)

	if (isLoading || isFetching) {
		return <LoadingSpinner theme="blue" />
	}

	if (isError) {
		return (
			<p>
				We were unable to retrieve FAQs at this time. Try again later.
			</p>
		)
	}

	if (!data || data.items.length === 0) {
		return (
			<p className="mb-5 text-lg font-bold text-black">
				{block.elements.noResultsTitle}
			</p>
		)
	}

	return (
		<>
			{gs?.[0]?.faqSearchQuery ? (
				<p className="mb-5 text-lg font-bold text-black">
					{formattedResultsForTitle}
				</p>
			) : null}

			<FaqBlock
				block={{
					elements: {
						authenticationGateComponentUserRoles:
							block.elements.authenticationGateComponentUserRoles,
						faqItems: data.items,
						expandIconColor: [],
						snippetSpacingSpacing: [],
					},
					system: block.system,
				}}
				globalConfig={globalConfig}
				{...context}
			/>
		</>
	)
}
