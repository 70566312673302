import type {SetStateAction} from 'react'
import {useState, useEffect} from 'react'
import {useRouter} from 'next/router'
import {Image, Dropdown} from '@elanco/component-library-v2'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {Button} from '@/_new-code/products/flexible-web-toolkit/components/button/button'
import type {LinkContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/link'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {useAuth} from '@/_new-code/products/auth/auth-provider'
import {LoadingSpinner} from '@/_new-code/products/flexible-web-toolkit/components/loading-spinner'
import Form from '@/components/Form'

export type VetRebatePermissionContentItem = IContentItem<{
	image: Elements.AssetsElement
	vrpFormTitle: Elements.TextElement
	vrpFormSubTitle: Elements.TextElement
	vrpDistributorList: Elements.MultipleChoiceElement
	vrpInvoiceIdPlaceholder: Elements.TextElement
	vrpSubmitButtonText: Elements.TextElement
	vrpInvalidInvoiceNumberMessage: Elements.TextElement
	vrpSupportLink: Elements.LinkedItemsElement<LinkContentItem>
	vrpSubmitSuccessMessage: Elements.TextElement
	vrpSubmitSfailureMessage: Elements.TextElement
	vrpInvalidInputText: Elements.TextElement
	vrpInvoiceNumberExceeded: Elements.TextElement
	errorPageUrl: Elements.TextElement
	successPageUrl: Elements.TextElement
	vrpDropdownText: Elements.TextElement
	vrpInvoicefieldText: Elements.TextElement
	vrpDropdownPlaceholder: Elements.TextElement
}>

export const VetRebatePermissionBlock: Block<
	VetRebatePermissionContentItem
> = ({block}) => {
	const authState = useAuth()
	const {userDetails} = authState
	const {locale: pageLocale = '', ...router} = useRouter()
	const [invoiceId, setInvoiceId] = useState('')
	const [message, setMessage] = useState<string | null>(null)
	const [isError, setIsError] = useState(false)
	const [loader, setLoader] = useState(false)
	const [selectedDistributor, setSelectedDistributor] = useState('')

	const [isAccountInfoLoaded, setIsAccountInfoLoaded] =
		useState<boolean>(false)

	const dropdownOptions = block.elements.vrpDistributorList.map((item) => {
		return {
			text: item.name,
			value: item.codename,
		}
	})

	const getInvoicePermission = async (): Promise<void> => {
		const permissionsUrl = '/api/vet/rebate'
		const requestBody = {
			CRM_ID: userDetails.crmId,
			INVC_NBR: invoiceId,
			ELA_DVSN: pageLocale,
			UID: userDetails.id,
			DISTRIBUTOR_CODE: selectedDistributor,
			EMAIL: userDetails.emailAddress,
		}
		await fetch(permissionsUrl, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(requestBody),
		}).then((response) => {
			setLoader(false)
			if (response.status === 200) {
				setMessage(block.elements.vrpSubmitSuccessMessage)
				setIsError(false)
				setTimeout(
					() => void router.push(block.elements.successPageUrl),
					2000
				)
			} else if (response.status === 406) {
				setMessage(block.elements.vrpInvoiceNumberExceeded)
				setIsError(true)
			} else {
				setMessage(block.elements.vrpSubmitSfailureMessage)
				setIsError(true)
			}
		})
	}
	function handleDistributorChange(
		distributorValue: SetStateAction<string>
	): void {
		setSelectedDistributor(distributorValue)
	}

	function handleSubmit(
		event:
			| React.FormEvent<HTMLFormElement>
			| React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>
	): void {
		event.preventDefault()
		if (invoiceId === '') {
			setMessage(block.elements.vrpInvalidInputText)
			setIsError(true)
		} else {
			setLoader(true)
			void getInvoicePermission()
		}
	}

	useEffect(() => {
		if (userDetails.id) {
			if (!userDetails.crmId)
				void router.push(block.elements.errorPageUrl)
			else setIsAccountInfoLoaded(true)
		}
	}, [userDetails.id, userDetails.crmId, router, block.elements.errorPageUrl])

	function handleInputChange(
		event: React.ChangeEvent<HTMLInputElement>
	): void {
		const inputValue = event.target.value.toUpperCase()
		const invalidChars = /[*;<>&=]/
		if (invalidChars.test(inputValue)) {
			setInvoiceId(inputValue)
			setMessage(block.elements.vrpInvalidInvoiceNumberMessage)
			setIsError(true)
		} else {
			setInvoiceId(inputValue)
			setMessage(null)
			setIsError(false)
		}
	}
	return (
		<div>
			{isAccountInfoLoaded ? (
				<div className="flex h-screen items-center justify-center">
					<div className="ml-4 mr-4 w-full p-4 text-center lg:w-[60%]">
						<Image
							alt="Icon"
							className="relative mx-auto mb-8 mt-2 h-1/3 w-1/3 p-1 lg:top-8  lg:h-[12%] lg:w-[12%]"
							height={20}
							src={block.elements.image[0]?.url}
							width={20}
						/>
						<h2>{block.elements.vrpFormTitle}</h2>
						<p className="mt-2 text-gray-600">
							{block.elements.vrpFormSubTitle}
						</p>
						<Form action="" className="mt-2">
							<label
								className="pb-1 text-left text-base font-semibold "
								htmlFor="label"
							>
								{block.elements.vrpDropdownText}
							</label>
							<Dropdown
								dropdownData={[
									{
										text: block.elements
											.vrpDropdownPlaceholder,
										value: '',
									},
									...dropdownOptions,
								]}
								updateFilterValue={handleDistributorChange}
								value={selectedDistributor}
							/>
							<label
								className="text-left text-base font-semibold "
								htmlFor="label"
							>
								{block.elements.vrpInvoicefieldText}
							</label>
							<input
								className="mt-1 w-full rounded-md border border-gray-500 p-4"
								disabled={!selectedDistributor}
								maxLength={40}
								onChange={handleInputChange}
								placeholder={
									block.elements.vrpInvoiceIdPlaceholder
								}
								style={{textTransform: 'uppercase'}}
								type="text"
								value={invoiceId}
							/>
							{loader ? (
								<div>
									<LoadingSpinner theme="blue" />
								</div>
							) : (
								''
							)}
							<div
								className={`mt-2 text-sm ${
									isError ? 'text-red-500' : 'text-green-400'
								}`}
							>
								{message}
							</div>
							<Button
								as="button"
								className="mt-4 rounded-[0.3rem] pl-[7.25rem] pr-[7.25rem]"
								disabled={isError || !selectedDistributor}
								onClick={(
									e:
										| React.FormEvent<HTMLFormElement>
										| React.MouseEvent<
												| HTMLAnchorElement
												| HTMLButtonElement
										  >
								) => {
									handleSubmit(e)
								}}
								type="submit"
								variant="primary"
							>
								{block.elements.vrpSubmitButtonText}
							</Button>
						</Form>
						<p className="pb-14 pt-3">
							<a
								className="font-bold text-blue-400"
								href="mailto: myelanco@elancoah.com?subject=Rebate%20Access%20Support"
							>
								Contact Support
							</a>
						</p>
					</div>
				</div>
			) : (
				<div className="mx-auto w-full text-center">
					<LoadingSpinner theme="blue" />
				</div>
			)}
		</div>
	)
}
