import type {z} from 'zod'
import type {Cluster, Marker} from '@googlemaps/markerclusterer'
import {MarkerClusterer} from '@googlemaps/markerclusterer'
import type {ElementModels} from '@kontent-ai/delivery-sdk'
import type {RefObject, Dispatch, MutableRefObject, SetStateAction} from 'react'
import {Loader} from '@googlemaps/js-api-loader'
import type {NextRouter} from 'next/router'
import {convertMilesToMeters} from '@/_new-code/utilities/distance'
import {logError, logMessage} from '@/services/client-logger'
import type {GetData, GetReturnType} from '@/_new-code/services/disease-api/api'
import type {Tersed} from '@/_new-code/services/kontent-ai/types'
import {toYYYYMMDD} from '@/_new-code/utilities/dates'
import {env} from '@/utils/env/client.mjs'
import http from '@/utils/axios'
import {
	FILL_OPACITY_MAP_CIRCLE,
	MAP_TYPE,
	MAP_TYPE_ID_ROADMAP,
	MAP_TYPE_ID_TERRAIN,
	MARKER_COLOR_MAP_CIRCLE,
	STROKE_OPACITY_MAP_CIRCLE,
	STROKE_WEIGHT_MAP_CIRCLE,
} from '../../utils/constants'
import {
	getClusterSvgString,
	getInfoWindowContent,
	getPinSvgString,
} from '../../utils/assets-helper'
import type {LatLng} from '../parasite-tracker-module'
import type {
	AdvancedMarkerElementType,
	CaseData,
	GroupedCases,
	SearchLocation,
	NewParasiteTrackerMapContentItem,
} from './types'
import type {PlaceType, SearchHandler} from './search'
import {HEATMAP, HEATMAP_AND_MARKERS, MARKERS} from './new-parasite-map.mac'

export class ParasiteMapController {
	private map: google.maps.Map | null
	private geocoder: google.maps.Geocoder | null
	private hoveredPlaceId: string | null = null
	private selectedLevel1Id: string | null = null
	private selectedLevel2Id: string | null = null
	private readonly block: Tersed<NewParasiteTrackerMapContentItem>
	private readonly isMobile: boolean
	private readonly loadData: GetReturnType | undefined
	private level2Data: GetData | null
	private readonly setErrorMessage: Dispatch<SetStateAction<string>>
	private readonly setError: Dispatch<SetStateAction<boolean>>
	private readonly setShowLoadingOverlay: Dispatch<SetStateAction<boolean>>
	private readonly setShowSummaryText: Dispatch<
		SetStateAction<boolean | undefined>
	>
	private readonly setReportedCases: Dispatch<SetStateAction<string>>
	private readonly setLocationName: Dispatch<SetStateAction<string>>
	private readonly setSearchLocation: Dispatch<
		SetStateAction<SearchLocation | null>
	>
	private readonly setSearchHandler: Dispatch<
		SetStateAction<SearchHandler | undefined>
	>
	private readonly mapRef: RefObject<HTMLDivElement | null>
	private readonly setMapState: Dispatch<
		SetStateAction<google.maps.Map | null>
	>
	private readonly router: NextRouter
	private readonly DEFAULT_LAYERS = [
		'administrative_layer_1',
		'administrative_layer_2',
	] as const

	constructor({
		block,
		isMobile,
		loadData,
		setErrorMessage,
		setError,
		setShowLoadingOverlay,
		setShowSummaryText,
		setReportedCases,
		setLocationName,
		setSearchLocation,
		setSearchHandler,
		mapRef,
		setMapState,
		router,
	}: {
		block: Tersed<NewParasiteTrackerMapContentItem>
		isMobile: boolean
		loadData: GetReturnType | undefined
		setErrorMessage: Dispatch<SetStateAction<string>>
		setError: Dispatch<SetStateAction<boolean>>
		setShowLoadingOverlay: Dispatch<SetStateAction<boolean>>
		setShowSummaryText: Dispatch<SetStateAction<boolean | undefined>>
		setReportedCases: Dispatch<SetStateAction<string>>
		setLocationName: Dispatch<SetStateAction<string>>
		setSearchLocation: Dispatch<SetStateAction<SearchLocation | null>>
		setSearchHandler: Dispatch<SetStateAction<SearchHandler | undefined>>
		mapRef: RefObject<HTMLDivElement | null>
		setMapState: Dispatch<SetStateAction<google.maps.Map | null>>
		router: NextRouter
	}) {
		this.map = null
		this.geocoder = null
		this.block = block
		this.isMobile = isMobile
		this.loadData = loadData
		this.level2Data = null
		this.setErrorMessage = setErrorMessage
		this.setError = setError
		this.setShowLoadingOverlay = setShowLoadingOverlay
		this.setShowSummaryText = setShowSummaryText
		this.setReportedCases = setReportedCases
		this.setLocationName = setLocationName
		this.setSearchLocation = setSearchLocation
		this.mapRef = mapRef
		this.setSearchHandler = setSearchHandler
		this.setMapState = setMapState
		this.router = router
	}

	/**
	 * Creates, or provides access to an instance of google.maps.Map and google.maps.Geocoder
	 *
	 * @example
	 * ```
	 * const {map, geocoder} = await this.getGoogleMaps()
	 *
	 * // You can now access map safely here!
	 * map.yourCode()
	 * ```
	 */
	private async getGoogleMaps(): Promise<{
		map: google.maps.Map
		geocoder: google.maps.Geocoder
	} | null> {
		if (this.map && this.geocoder)
			return {map: this.map, geocoder: this.geocoder}

		const parvoMap = this.mapRef.current
		if (!parvoMap) {
			return null
		}

		const loader = new Loader({
			apiKey: env.NEXT_PUBLIC_MAP_API_KEY,
			version: 'weekly',
		})

		const {Map: GoogleMap} = await loader.importLibrary('maps')

		const parseResult = MAP_TYPE.safeParse(
			this.block.elements.mapType[0]?.codename ?? ''
		)
		const validatedMapType = parseResult.success ? parseResult.data : null

		this.map = new GoogleMap(parvoMap, {
			mapId: env.NEXT_PUBLIC_PARASITE_MAP_STYLE_ID,
			center: {
				lat: this.block.elements.latitude ?? 52.5555,
				lng: this.block.elements.longitude ?? 0,
			},
			zoom: this.block.elements.zoomLevel ?? 8,
			streetViewControl: false,
			mapTypeControl: false,
			clickableIcons: false,
			keyboardShortcuts: false,
			mapTypeId:
				validatedMapType === MARKERS
					? MAP_TYPE_ID_TERRAIN
					: MAP_TYPE_ID_ROADMAP,
		})

		this.geocoder = new google.maps.Geocoder()

		return {map: this.map, geocoder: this.geocoder}
	}

	getMapType(mapType: string): z.infer<typeof MAP_TYPE> | null {
		const parseResult = MAP_TYPE.safeParse(mapType)
		return parseResult.success ? parseResult.data : null
	}

	sortCasesByPostCode(cases: CaseData[]): GroupedCases {
		return cases.reduce<GroupedCases>((prevCase, currCase) => {
			const tmpCase = {...prevCase}
			const postCode = currCase.location.postalCode
			if (postCode) {
				if (!tmpCase[postCode]) {
					tmpCase[postCode] = []
				}
				tmpCase[postCode]?.push(currCase)
			}
			return tmpCase
		}, {})
	}

	async fetchLevel2Data(placeId: string): Promise<GetReturnType> {
		const startDate = this.block.elements.startDate
		const endDate = this.block.elements.endDate
		const endDateLiteral = endDate
			? `&endDate=${toYYYYMMDD(new Date(endDate))}`
			: ''
		const startDateLiteral = startDate
			? `&startDate=${toYYYYMMDD(new Date(startDate))}`
			: ''

		const countryCode = this.router.locale
		const endpoint = `/api/disease/group-by-administrative-area?countryCodes=${countryCode?.toLowerCase()}&diseases=${this.block.elements.parasiteType[0]?.codename.toUpperCase()}&groupBy=administrativeAreaLevel2&placeId=${placeId}${startDateLiteral}${endDateLiteral}`
		const {data} = await http<GetReturnType>(endpoint)
		return data
	}

	async createMarker(
		casesData: GroupedCases,
		mapState: google.maps.Map | null,
		singleCaseTitle: string,
		singleCaseSubtitle: string,
		multipleCaseTitle: string,
		multipleCaseSubtitle: string
	): Promise<AdvancedMarkerElementType[]> {
		const {AdvancedMarkerElement} = (await google.maps.importLibrary(
			'marker'
		)) as google.maps.MarkerLibrary
		const {InfoWindow} = (await google.maps.importLibrary(
			'maps'
		)) as google.maps.MapsLibrary

		const locations = Object.entries(casesData)
		const parser = new DOMParser()
		const infoWindow = new InfoWindow()

		const totalCases = locations.reduce(
			(acc, [, cases]) => acc + cases.length,
			0
		)
		this.setReportedCases(String(totalCases))

		return locations.map((item) => {
			const caseData: CaseData = item[1][0] || ({} as CaseData)
			const {location} = caseData
			const numOfCases: number = item[1].length

			const pinSvgString: string = getPinSvgString(
				numOfCases,
				MARKER_COLOR_MAP_CIRCLE
			)

			const pinSvg = parser.parseFromString(
				pinSvgString,
				'image/svg+xml'
			).documentElement
			const marker: AdvancedMarkerElementType = new AdvancedMarkerElement(
				{
					position: {lat: location.latitude, lng: location.longitude},
					map: mapState,
					content: pinSvg,
				}
			) as AdvancedMarkerElementType

			marker.addListener('click', () => {
				infoWindow.close()
				infoWindow.setContent(
					getInfoWindowContent(
						numOfCases,
						singleCaseTitle,
						singleCaseSubtitle,
						multipleCaseTitle,
						multipleCaseSubtitle,
						item[0]
					)
				)
				infoWindow.open(marker.map, marker)
			})
			marker.caseCount = numOfCases
			return marker
		})
	}

	async createNewMarkerCluster(
		markers: AdvancedMarkerElementType[] | undefined
	): Promise<MarkerClusterer> {
		const parser = new DOMParser()
		const {AdvancedMarkerElement} = (await google.maps.importLibrary(
			'marker'
		)) as google.maps.MarkerLibrary
		return new MarkerClusterer({
			map: this.map,
			markers,
			renderer: {
				render: (cluster: Cluster) => {
					type MarkerOrNumber =
						| AdvancedMarkerElementType
						| Marker
						| number

					const clusterCount =
						cluster.markers?.reduce(
							(a: MarkerOrNumber, b: MarkerOrNumber) => {
								// If both `a` and `b` are objects and have `caseCount`
								if (
									typeof a === 'object' &&
									'caseCount' in a &&
									typeof a.caseCount === 'number' &&
									typeof b === 'object' &&
									'caseCount' in b &&
									typeof b.caseCount === 'number'
								) {
									return a.caseCount + b.caseCount
								}

								// If `a` is a number and `b` has `caseCount`
								if (
									typeof a === 'number' &&
									typeof b === 'object' &&
									'caseCount' in b &&
									typeof b.caseCount === 'number'
								) {
									return a + b.caseCount
								}

								// If `a` has `caseCount` and `b` is a number
								if (
									typeof a === 'object' &&
									'caseCount' in a &&
									typeof a.caseCount === 'number' &&
									typeof b === 'number'
								) {
									return a.caseCount + b
								}

								return a // Default return value if none of the conditions are met
							},
							0
						) || 0

					const clusterSvgString = getClusterSvgString(
						MARKER_COLOR_MAP_CIRCLE,
						clusterCount as number
					)
					const clusterSvg = parser.parseFromString(
						clusterSvgString,
						'image/svg+xml'
					).documentElement
					return new AdvancedMarkerElement({
						position: cluster.position,
						map: this.map,
						content: clusterSvg,
					})
				},
			},
		})
	}

	renderRadiusCircle(
		center: SearchLocation | null,
		radiusInMiles: number
	): google.maps.Circle {
		if (!this.map) {
			throw new Error('Map is not set.')
		}

		return new window.google.maps.Circle({
			map: this.map,
			center,
			radius: convertMilesToMeters(radiusInMiles),
			strokeColor: MARKER_COLOR_MAP_CIRCLE,
			strokeOpacity: STROKE_OPACITY_MAP_CIRCLE,
			strokeWeight: STROKE_WEIGHT_MAP_CIRCLE,
			fillColor: MARKER_COLOR_MAP_CIRCLE,
			fillOpacity: FILL_OPACITY_MAP_CIRCLE,
		})
	}

	/**
	 * Zooms the map to the location specified by the given `placeId`.
	 *
	 * @returns - Returns `false` if the method completes successfully without errors.
	 * 			  Returns `true` if there is an error during geocoding or map zooming.
	 * **/
	zoomToPlace(placeId: string, isMobile: boolean): boolean {
		if (!this.geocoder) throw new Error('Geocoder not set.')
		this.geocoder
			.geocode({placeId})
			.then(({results}: {results: google.maps.GeocoderResult[]}) => {
				const bound = results[0]?.geometry.viewport || null
				const padding = isMobile ? 55 : 155
				if (!this.map) throw new Error('Map not set.')
				if (bound) this.map.fitBounds(bound, padding)
			})
			.catch(() => {
				logError(`Could not zoom to the place ID ${placeId}`)
				return true
			})
		return false
	}

	getHeatmapStyles(
		data: GetData,
		placeId: string,
		placeType: PlaceType
	): google.maps.FeatureStyleOptions {
		const numOfCases = data[placeId]?.worstAffectedArea ?? 0
		const legendData =
			this.block.elements.mapLegend[0]?.elements.legend || []
		let fillColor
		for (const legend of legendData) {
			if (
				numOfCases >= (legend.elements.lowerBound ?? 0) &&
				numOfCases < (legend.elements.upperBound ?? 0)
			) {
				fillColor = legend.elements.color
			}
		}

		// Add hover effect and ignore the selected level2 placeId, since it will have a border around it
		if (
			this.hoveredPlaceId !== this.selectedLevel2Id &&
			this.hoveredPlaceId === placeId &&
			numOfCases > 0
		) {
			return {
				strokeColor: '#2772ce',
				strokeOpacity: 1,
				strokeWeight: 3,
				fillColor,
				fillOpacity: 1,
			}
		}

		let strokeColor
		let strokeWeight
		if (placeType === 'administrative_area_level_1') {
			strokeColor = this.block.elements.stateBorderColor || undefined
			strokeWeight = this.block.elements.stateBorderWidth ?? undefined
		} else if (placeId === this.selectedLevel2Id) {
			strokeColor = 'black'
			strokeWeight = 2
		} else {
			strokeColor = this.block.elements.countyBorderColor || undefined
			strokeWeight = this.block.elements.countyBorderWidth ?? undefined
		}

		return {
			fillColor,
			fillOpacity: this.block.elements.heatmapOpacity ?? 1,
			strokeColor,
			strokeOpacity: 1,
			strokeWeight,
		}
	}

	getCleanAddress(
		addressComponents: google.maps.GeocoderAddressComponent[]
	): string {
		return addressComponents
			.filter((item) => !item.types.includes('country'))
			.map((item, index) =>
				index === 0 ? item.long_name : item.short_name
			)
			.join(', ')
	}

	async renderCircleAndMarkers(
		searchedLocation: SearchLocation | null,
		renderLocationMarker: () => Promise<void>,
		radiusInMiles: number,
		mapType: string,
		setShowMarkersAndPins: Dispatch<SetStateAction<boolean>>,
		mapCircle: MutableRefObject<google.maps.Circle | null>
	): Promise<void> {
		const googleMaps = await this.getGoogleMaps()
		if (!googleMaps) return
		const {map} = googleMaps

		await renderLocationMarker()
		const center = {
			lat: searchedLocation?.lat ?? 52.5555,
			lng: searchedLocation?.lng ?? 0,
		}
		mapCircle.current = this.renderRadiusCircle(center, radiusInMiles)
		const validatedMapType = this.getMapType(mapType)
		if (validatedMapType === HEATMAP_AND_MARKERS) {
			this.map?.setMapTypeId(MAP_TYPE_ID_TERRAIN)
		}
		if (searchedLocation) {
			map.panTo(searchedLocation)
		}
		map.setZoom(8)

		if (validatedMapType === HEATMAP_AND_MARKERS && this.map) {
			window.google.maps.event.addListener(
				this.map,
				'zoom_changed',
				() => {
					const zoomLevel = this.map?.getZoom() ?? 0
					if (zoomLevel > 6) {
						this.map?.setMapTypeId(MAP_TYPE_ID_TERRAIN)
						setShowMarkersAndPins(true)
					} else {
						this.map?.setMapTypeId(MAP_TYPE_ID_ROADMAP)
						setShowMarkersAndPins(false)
					}
				}
			)
		}
	}

	render(
		layers: ElementModels.MultipleChoiceOption[],
		map: google.maps.Map,
		loadData: GetReturnType | undefined,
		level2Data: GetData | null
	): void {
		let level1LoadedData: GetReturnType | undefined
		let level2LoadedData: GetReturnType | undefined
		const renderedLayers =
			layers.length > 0
				? layers.map((e) => e.codename)
				: this.DEFAULT_LAYERS
		const featureLayerLevel1 = map.getFeatureLayer(
			google.maps.FeatureType.ADMINISTRATIVE_AREA_LEVEL_1
		)
		const featureLayerLevel2 = map.getFeatureLayer(
			google.maps.FeatureType.ADMINISTRATIVE_AREA_LEVEL_2
		)
		if (renderedLayers.includes('administrative_layer_1')) {
			level1LoadedData = loadData
		} else {
			level2LoadedData = loadData
			this.level2Data = loadData?.success ? loadData.data : {}
		}
		// Render level 1
		featureLayerLevel1.style = (featureStyleFunctionOptions) => {
			// Check if level 1 data is provided and that the layer is toggled
			if (
				!level1LoadedData?.success ||
				(renderedLayers.length > 0 &&
					!renderedLayers.includes('administrative_layer_1'))
			)
				return

			const data = level1LoadedData.data
			const {placeId} =
				featureStyleFunctionOptions.feature as google.maps.PlaceFeature

			// If a level 1 place is selected, make it invisible so that level 2 heatmap can be rendered
			if (placeId === this.selectedLevel1Id) {
				return {
					fillColor: undefined,
					fillOpacity: undefined,
					strokeColor: 'black',
					strokeOpacity: 1,
					strokeWeight: 2,
				}
			}

			// Paint heatmap according to worstAffectedArea
			return this.getHeatmapStyles(
				data,
				placeId,
				'administrative_area_level_1'
			)
		}

		if (renderedLayers.includes('administrative_layer_1')) {
			// Render level 2
			featureLayerLevel2.style = (featureStyleFunctionOptions) => {
				const {placeId} =
					featureStyleFunctionOptions.feature as google.maps.PlaceFeature

				// If there is no level 2 data, or if we have not selected a level 1 place, or if the layer is not enabled, exit
				if (
					!level2Data ||
					!this.selectedLevel1Id ||
					!level2Data[placeId] ||
					(renderedLayers.length > 0 &&
						!renderedLayers.includes('administrative_layer_2'))
				) {
					return {
						fillColor: undefined,
						fillOpacity: undefined,
						strokeColor: undefined,
						strokeOpacity: undefined,
						strokeWeight: undefined,
					}
				}

				return this.getHeatmapStyles(
					level2Data,
					placeId,
					'administrative_area_level_2'
				)
			}
		} else {
			// Render level 2
			featureLayerLevel2.style = (featureStyleFunctionOptions) => {
				// Check if level 2 data is provided and that the layer is toggled
				if (
					!level2LoadedData?.success ||
					(renderedLayers.length > 0 &&
						!renderedLayers.includes('administrative_layer_2'))
				)
					return

				const data = level2LoadedData.data
				const {placeId} =
					featureStyleFunctionOptions.feature as google.maps.PlaceFeature

				// Paint heatmap according to worstAffectedArea
				return this.getHeatmapStyles(
					data,
					placeId,
					'administrative_area_level_2'
				)
			}
		}
	}

	/**
	 * Display error message if map falls back to Raster rendering
	 *
	 * See: https://developers.google.com/maps/documentation/javascript/webgl/support
	 * */
	private onRenderTypeChanged(map: google.maps.Map): void {
		const renderingType = map.getRenderingType()

		if (renderingType === google.maps.RenderingType.RASTER) {
			this.setError(true)
			const errorMessage =
				'Sorry, we cannot display the ParvoTrack Map, as your device or browser is not supported. Try updating your device and browser and try again.'
			this.setErrorMessage(errorMessage)
			logMessage(errorMessage)
		}
	}

	private onZoom(map: google.maps.Map): void {
		const currentZoomLevel = map.getZoom()
		if (!currentZoomLevel) return

		if (currentZoomLevel <= (this.block.elements.zoomLevel ?? 8)) {
			this.selectedLevel1Id = null
			this.setShowSummaryText(false)
			this.setReportedCases('')
			this.render(
				this.block.elements.layers,
				map,
				this.loadData,
				this.level2Data
			)
		}
	}

	private onHover(event: {features: {placeId: string}[]}): void {
		const placeId = event.features[0]?.placeId
		if (!placeId) {
			logMessage('PlaceId not found for handleHover function!')
			return
		}

		this.hoveredPlaceId = placeId
		if (this.map) {
			this.render(
				this.block.elements.layers,
				this.map,
				this.loadData,
				this.level2Data
			)
		}
	}

	private async onFeatureLevel1Click(event: {
		features: {
			placeId: string
		}[]
	}): Promise<void> {
		const placeId = event.features[0]?.placeId

		if (!placeId) {
			logMessage('PlaceId not found for feature layer 1 click lister!')
			return
		}

		if (!this.loadData?.success) {
			return
		}

		const numberOfCases = this.loadData.data[placeId]?.numberOfCases

		if (!numberOfCases) {
			logMessage('No cases found for feature layer 1 click lister!')
			return
		}

		this.setShowLoadingOverlay(true)
		const res = await this.fetchLevel2Data(placeId)

		this.level2Data = res.success ? res.data : {}
		this.selectedLevel1Id = placeId
		this.selectedLevel2Id = null

		// Make rendering changes
		this.setShowSummaryText(true)
		this.setReportedCases(String(numberOfCases))
		this.zoomToPlace(placeId, this.isMobile)
		const googleMaps = await this.getGoogleMaps()
		if (!googleMaps) return
		const {map, geocoder} = googleMaps

		void geocoder.geocode({placeId}).then(({results}) => {
			const address = this.getCleanAddress(
				results[0]?.address_components ?? []
			)
			this.setLocationName(address || 'the selected area')
		})
		this.render(
			this.block.elements.layers,
			map,
			this.loadData,
			this.level2Data
		)
		this.setShowLoadingOverlay(false)
	}

	private async onFeatureLevel2Click(event: {
		features: {
			placeId: string
		}[]
	}): Promise<void> {
		const placeId = event.features[0]?.placeId

		if (!placeId) {
			logMessage('PlaceId not found for feature layer 2 click lister!')
			return
		}

		this.setShowSummaryText(true)
		this.setReportedCases(
			this.level2Data
				? String(this.level2Data[placeId]?.numberOfCases ?? '0')
				: '0'
		)
		this.selectedLevel2Id = placeId
		const googleMaps = await this.getGoogleMaps()
		if (!googleMaps) return
		const {map, geocoder} = googleMaps

		void geocoder.geocode({placeId}).then(({results}) => {
			const address = this.getCleanAddress(
				results[0]?.address_components ?? []
			)
			this.setLocationName(address || 'the selected area')
		})
		this.render(
			this.block.elements.layers,
			map,
			this.loadData,
			this.level2Data
		)
	}

	private initEventListeners(map: google.maps.Map): void {
		const featureLayerLevel1 = map.getFeatureLayer(
			google.maps.FeatureType.ADMINISTRATIVE_AREA_LEVEL_1
		)

		const featureLayerLevel2 = map.getFeatureLayer(
			google.maps.FeatureType.ADMINISTRATIVE_AREA_LEVEL_2
		)

		map.addListener('renderingtype_changed', () => {
			this.onRenderTypeChanged(map)
		})

		featureLayerLevel1.addListener(
			'click',
			(event: {
				features: {
					placeId: string
				}[]
			}) => this.onFeatureLevel1Click(event)
		)

		// Setup listener for clicking on a level 2 place
		featureLayerLevel2.addListener(
			'click',
			(event: {
				features: {
					placeId: string
				}[]
			}) => this.onFeatureLevel2Click(event)
		)

		// Setup zoom listener to go back to feature layer level 1 ("state level") after zooming out
		map.addListener('zoom_changed', () => {
			this.onZoom(map)
		})

		// Setup mouse listeners for hover highlighting effect
		const handleHover = (event: {features: {placeId: string}[]}): void => {
			this.onHover(event)
		}

		featureLayerLevel1.addListener('mousemove', handleHover)
		featureLayerLevel2.addListener('mousemove', handleHover)
	}

	async renderMap(): Promise<void> {
		const googleMaps = await this.getGoogleMaps()
		if (!googleMaps) return
		const {map, geocoder} = googleMaps

		const parseResult = MAP_TYPE.safeParse(
			this.block.elements.mapType[0]?.codename
		)
		const validatedMapType = parseResult.success ? parseResult.data : null

		// initialize event listeners
		this.initEventListeners(map)

		const handleSearch = async (
			location: LatLng,
			placeType: PlaceType
		): Promise<void> => {
			const {results} = await geocoder.geocode({location})

			const place = results.find(({types}) => types.includes(placeType))

			if (!place) {
				logMessage(
					`Place not found for ${placeType} in handleSearch function!`
				)
				return
			}

			switch (placeType) {
				case 'administrative_area_level_1': {
					if (!this.loadData?.success) return

					const numberOfCases =
						this.loadData.data[place.place_id]?.numberOfCases ?? 0

					this.setShowLoadingOverlay(true)
					const res = await this.fetchLevel2Data(place.place_id)

					this.level2Data = res.success ? res.data : {}
					this.selectedLevel1Id = place.place_id

					// Make rendering changes
					this.setShowSummaryText(true)
					this.setReportedCases(String(numberOfCases))
					if (validatedMapType === HEATMAP) {
						const isError = this.zoomToPlace(
							place.place_id,
							Boolean(this.isMobile)
						)
						this.setError(isError)
					}
					this.setLocationName(
						this.getCleanAddress(place.address_components)
					)
					this.map = map
					this.setMapState(map)
					this.setSearchLocation({
						lat: place.geometry.location.lat(),
						lng: place.geometry.location.lng(),
					})
					this.setShowLoadingOverlay(false)
					break
				}

				case 'administrative_area_level_2': {
					if (!this.loadData?.success) return
					const level1Place = results.find(({types}) =>
						types.includes('administrative_area_level_1')
					)

					const level2Place = results.find(({types}) =>
						types.includes('administrative_area_level_2')
					)

					const numberOfCases =
						this.loadData.data[place.place_id]?.numberOfCases ?? 0

					if (!level1Place) return

					this.setShowLoadingOverlay(true)
					const res = await this.fetchLevel2Data(level1Place.place_id)

					this.level2Data = res.success ? res.data : {}
					this.selectedLevel1Id = level1Place.place_id
					this.selectedLevel2Id = level2Place?.place_id ?? null

					// Make rendering changes
					this.setShowSummaryText(true)
					this.setReportedCases(String(numberOfCases))
					if (validatedMapType === HEATMAP) {
						const isError = this.zoomToPlace(
							place.place_id,
							Boolean(this.isMobile)
						)
						this.setError(isError)
					}
					this.setLocationName(
						this.getCleanAddress(place.address_components)
					)
					this.map = map
					this.setMapState(map)
					this.setSearchLocation({
						lat: place.geometry.location.lat(),
						lng: place.geometry.location.lng(),
					})
					this.setShowLoadingOverlay(false)
					break
				}

				default: {
					this.selectedLevel1Id = null
					this.setShowSummaryText(false)
					this.setReportedCases('')
					const isError = this.zoomToPlace(
						place.place_id,
						Boolean(this.isMobile)
					)
					this.setError(isError)
					break
				}
			}

			this.render(
				this.block.elements.layers,
				map,
				this.loadData,
				this.level2Data
			)
		}
		this.setSearchHandler(() => handleSearch)

		// Do initial render
		this.render(
			this.block.elements.layers,
			map,
			this.loadData,
			this.level2Data
		)
	}
}
