import type {JSX} from 'react'
import React, {useState, useRef, useEffect} from 'react'
import {Button} from '@/_new-code/products/flexible-web-toolkit/components/button/button'
import {ButtonIcons} from '@/_new-code/components/button-icons/button-icon'

const pushToDataLayer = (...data: Record<string, unknown>[]): void => {
	window.dataLayer = window.dataLayer || []
	window.dataLayer.push(...data)
}

interface PaginationProps {
	className?: string
	activeIndex: number
	amountOfPages: number
	onPageChange: (newPageNum: number) => unknown
	pagesToShow?: number
	showLastPage?: boolean
	prevButtonText?: JSX.Element | string
	nextButtonText?: JSX.Element | string
}

export const Pagination = ({
	className,
	activeIndex: propActiveIndex,
	amountOfPages,
	onPageChange,
	pagesToShow = 3,
	showLastPage = true,
	prevButtonText = <span>Prev</span>,
	nextButtonText = <span>Next</span>,
}: PaginationProps): JSX.Element => {
	const prevPropActiveIndex = useRef<number>(0)
	const [activeIndex, setActiveIndex] = useState<number>(propActiveIndex - 1)
	const listItems: JSX.Element[] = []

	const availablePages: number = Math.min(pagesToShow, amountOfPages)

	const pageList: number = Math.min(
		Math.max(activeIndex - Math.floor(availablePages / 2), 0),
		Math.max(amountOfPages - availablePages, 0)
	)

	const pageIndexChange = (
		e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>,
		newPageNum: number
	): void => {
		e.preventDefault()
		if ('button' in e) {
			onPageChange(newPageNum)
			setActiveIndex(newPageNum - 1)
		}
	}

	const pushDatalayerEvent = (text: JSX.Element | string): void => {
		pushToDataLayer({
			event: 'cta_click',
			cta_name: text,
			cta_category: 'button',
		})
	}

	useEffect(() => {
		if (prevPropActiveIndex.current !== propActiveIndex) {
			setActiveIndex(propActiveIndex - 1)
		}

		if (
			amountOfPages > 0 &&
			(propActiveIndex > amountOfPages || Number.isNaN(propActiveIndex))
		) {
			const pageSlugUrl = window.location.pathname
			const locale = pageSlugUrl.split('/')[1]
			window.location.href = `/${locale}/404`
		}

		prevPropActiveIndex.current = propActiveIndex
	}, [propActiveIndex, amountOfPages])

	const renderItem = (i: number): JSX.Element => {
		return (
			<li key={i}>
				<a
					aria-current={activeIndex === i}
					aria-label={`Page ${i + 1}${
						activeIndex === i ? ', current page' : ''
					}`}
					className={`flex h-12 w-12 items-center justify-center ${
						activeIndex === i
							? 'bg-primary text-white'
							: 'hover:bg-gray-400 focus:bg-gray-400'
					}`}
					href={`?page=${i + 1}`}
					onClick={(e) => {
						pageIndexChange(e, i + 1)
					}}
				>
					{i + 1}
				</a>
			</li>
		)
	}

	for (let i: number = pageList; i < pageList + availablePages; i += 1) {
		listItems.push(renderItem(i))
	}

	if (showLastPage && pageList + availablePages < amountOfPages) {
		if (pageList + availablePages < amountOfPages - 1) {
			listItems.push(<li key="seperator">...</li>)
		}
		listItems.push(renderItem(amountOfPages - 1))
	}

	if (
		activeIndex === amountOfPages - 1 ||
		activeIndex === amountOfPages - 2
	) {
		const addnum =
			activeIndex -
			(activeIndex === amountOfPages - 1 ? pagesToShow : pagesToShow - 1)

		if (
			!listItems.includes(renderItem(addnum)) &&
			amountOfPages > pagesToShow
		) {
			listItems.unshift(renderItem(addnum))
		}
	}

	return (
		<nav
			aria-label="Pagination"
			className={`mt-10 flex items-center justify-center font-bold ${className}`}
		>
			{activeIndex > 0 && (
				<Button
					as="a"
					className="mr-8 shrink-0"
					href={`?page=${activeIndex}`}
					icon={ButtonIcons.ArrowLeft}
					onClick={(e) => {
						pageIndexChange(
							e as React.MouseEvent<
								HTMLAnchorElement | HTMLButtonElement
							>,
							activeIndex
						)
						pushDatalayerEvent(prevButtonText)
					}}
					reversed
					variant="secondary"
				>
					{prevButtonText}
				</Button>
			)}
			<ul className="hidden items-center md:flex md:flex-wrap">
				{listItems}
			</ul>
			{activeIndex < amountOfPages - 1 && (
				<Button
					as="a"
					className="ml-8 shrink-0"
					href={`?page=${activeIndex + 2}`}
					icon={ButtonIcons.ArrowRight}
					onClick={(
						e:
							| React.MouseEvent<
									HTMLAnchorElement | HTMLButtonElement
							  >
							| React.FormEvent<HTMLFormElement>
					) => {
						if ('preventDefault' in e) {
							e.preventDefault()
						}
						pageIndexChange(
							e as React.MouseEvent<
								HTMLAnchorElement | HTMLButtonElement
							>,
							activeIndex + 2
						)
						pushDatalayerEvent(nextButtonText)
					}}
					variant="secondary"
				>
					{nextButtonText}
				</Button>
			)}
		</nav>
	)
}
