import type {ReactNode, ReactElement, JSX} from 'react'
import {EllipseButton} from '@/_new-code/products/flexible-web-toolkit/components/button/ellipse-button'

interface RTEProps {
	children?: ReactNode
	className?: string
	scrollToRef?: string
	scrollBtnIcon?:
		| ReactElement<{className?: string}>
		| {class: string; icon: ReactElement<{className?: string}>}
		| null
	navHeight?: number
	goToSectionAriaLabelText?: string
}

export const RTE = ({
	children = null,
	className = '',
	scrollToRef = '',
	scrollBtnIcon = null,
	navHeight = 0,
	goToSectionAriaLabelText = 'Go to below section',
}: RTEProps): JSX.Element => {
	const scrollToSection = (): void => {
		const targetBlock: HTMLElement | null = document.querySelector(
			`#${scrollToRef}`
		)
		if (targetBlock) {
			const targetBlockTopPos: number = targetBlock.offsetTop - navHeight
			window.scroll({top: targetBlockTopPos, behavior: 'smooth'})
		}
	}

	return (
		<div className={`content ${className}`}>
			{children}
			{scrollToRef ? (
				<EllipseButton
					as="button"
					icon={scrollBtnIcon}
					iconSize="w-6"
					onClick={scrollToSection}
				>
					{goToSectionAriaLabelText}
				</EllipseButton>
			) : null}
		</div>
	)
}
