import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import type {ReactElement} from 'react'
import React, {useState, useEffect} from 'react'
import type {Block} from '@/_new-code/services/kontent-ai/types'

export type ShareBlockStaticContentItem = IContentItem<{
	title: Elements.TextElement
	urlToShare: Elements.TextElement
	emailSubject: Elements.TextElement
	emailBody: Elements.TextElement
	showFacebook: Elements.MultipleChoiceElement
	showTwitter: Elements.MultipleChoiceElement
	showLinkedIn: Elements.MultipleChoiceElement
	showEmail: Elements.MultipleChoiceElement
}>

interface AnchorProps {
	target: string
	rel: string
}

export const ShareBlockStaticBlock: Block<ShareBlockStaticContentItem> = ({
	block,
}): ReactElement => {
	const {
		emailSubject: mailSubject,
		emailBody: mailBody,
		urlToShare,
		title,
	} = block.elements
	const showFacebook = block.elements.showFacebook[0]?.codename === 'yes'
	const showTwitter = block.elements.showTwitter[0]?.codename === 'yes'
	const showLinkedIn = block.elements.showLinkedIn[0]?.codename === 'yes'
	const showEmail = block.elements.showEmail[0]?.codename === 'yes'

	const anchorProps: AnchorProps = {
		target: '_blank',
		rel: 'noreferrer',
	}
	const [url, setUrl] = useState('')
	const [body, setBody] = useState('')

	useEffect(() => {
		const newUrl: string = urlToShare || window.location.href
		const newBody: string = mailBody.replace(/{{url}}/g, newUrl)
		setUrl(newUrl)
		setBody(newBody)
	}, [mailBody, urlToShare])

	return (
		<div>
			<div className="flex items-center">
				<p className="text-md mr-2">{title || 'Share on'}</p>
				<ul className="flex p-0 text-primary">
					{showFacebook ? (
						<li className="mr-1 shrink-0">
							<a
								href={`https://www.facebook.com/sharer/sharer.php?kid_directed_site=0&u=${url}&display=popup&ref=plugin&src=share_button`}
								title="Share on Facebook"
								{...anchorProps}
							>
								<svg
									className="m-auto h-5 fill-current"
									height="32.748"
									viewBox="0 0 15.115 32.748"
									width="15.115"
								>
									<path
										d="M60.73,87.464H57.161c-1.26,0-1.523.516-1.523,1.819v3.149H60.73l-.49,5.528h-4.6v16.514H49.044V98.031H45.615v-5.6h3.429V88.025c0-4.137,2.213-6.3,7.119-6.3H60.73v5.738Z"
										transform="translate(-45.615 -81.727)"
									/>
								</svg>
							</a>
						</li>
					) : null}

					{showTwitter ? (
						<li className="mx-1 shrink-0">
							<a
								href={`https://twitter.com/share?url=${url}`}
								title="Share on Twitter"
								{...anchorProps}
							>
								<svg
									className="m-auto h-5 fill-current"
									height="24.612"
									viewBox="0 0 1200 1227"
									width="30.285"
								>
									<path d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z" />
								</svg>
							</a>
						</li>
					) : null}

					{showLinkedIn ? (
						<li className="mx-1 shrink-0">
							<a
								href={`https://www.linkedin.com/sharing/share-offsite/?url=${url}`}
								title="Share on LinkedIn"
								{...anchorProps}
							>
								<svg
									className="m-auto h-5 fill-current"
									height="26.464"
									viewBox="0 0 26.452 26.464"
									width="26.452"
								>
									<path
										d="M421.615,96.922h5.461v17.643h-5.461Zm2.7-2.31a3.255,3.255,0,1,0-3.229-3.255A3.243,3.243,0,0,0,424.319,94.612ZM435.806,105.3c0-2.481,1.142-3.958,3.327-3.958,2.009,0,2.974,1.417,2.974,3.958v9.261h5.435V103.394c0-4.725-2.678-7.01-6.419-7.01a6.153,6.153,0,0,0-5.317,2.915V96.922h-5.238v17.643h5.238Z"
										transform="translate(-421.09 -88.101)"
									/>
								</svg>
							</a>
						</li>
					) : null}

					{showEmail ? (
						<li className="ml-2 shrink-0">
							<a
								href={`mailto:?subject=${
									mailSubject || 'subject'
								}&body=${body || url}`}
								title="Share via Email"
								{...anchorProps}
							>
								<svg
									className="m-auto h-5 fill-current"
									viewBox="0 0 1792 1792"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path d="M1792 710v794q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-794q44 49 101 87 362 246 497 345 57 42 92.5 65.5t94.5 48 110 24.5h2q51 0 110-24.5t94.5-48 92.5-65.5q170-123 498-345 57-39 100-87zm0-294q0 79-49 151t-122 123q-376 261-468 325-10 7-42.5 30.5t-54 38-52 32.5-57.5 27-50 9h-2q-23 0-50-9t-57.5-27-52-32.5-54-38-42.5-30.5q-91-64-262-182.5t-205-142.5q-62-42-117-115.5t-55-136.5q0-78 41.5-130t118.5-52h1472q65 0 112.5 47t47.5 113z" />
								</svg>
							</a>
						</li>
					) : null}
				</ul>
			</div>
		</div>
	)
}
