import type {ErrorResponse} from '@/_new-code/models/response-type'
import http from '@/utils/axios'
import type {LocalisedDate} from '@/_new-code/services/localised-date/model'

const isLocalisedDate = (
	data: LocalisedDate | ErrorResponse
): data is LocalisedDate => {
	return Object.prototype.hasOwnProperty.call(data, 'localisedDate')
}

export const fetchLocalisedDate = async (
	date: string,
	locale: string
): Promise<string> => {
	const dateFormatted = date.split('T')[0]
	const endpoint = `/api/getLocalisedDate?date=${dateFormatted}&locale=${locale}`
	const {data} = await http<LocalisedDate | ErrorResponse>(endpoint)

	if (isLocalisedDate(data)) {
		return data.localisedDate
	}

	throw new Error(data.error)
}
