import {textChecker} from '@/utils/rich-text-helper'
import type {Tersed} from '@/_new-code/services/kontent-ai/types'
import type {ListerSortingContentItem, SortingCriterion} from './lister-model'

export function sortItems<
	T extends {elements: {title: string; listerOrderNumber?: number | null}},
>(items: T[], criterion?: SortingCriterion): T[] {
	if (criterion === 'aZ') {
		return items.toSorted((a, b) => {
			const titleA = a.elements.title.toLowerCase()
			const titleB = b.elements.title.toLowerCase()
			return titleA.localeCompare(titleB)
		})
	} else if (criterion === 'zA') {
		return items.toSorted((a, b) => {
			const titleA = a.elements.title.toLowerCase()
			const titleB = b.elements.title.toLowerCase()
			return titleB.localeCompare(titleA)
		})
	} else if (criterion === 'recommended') {
		return items.toSorted((a, b) => {
			const orderA = a.elements.listerOrderNumber ?? Infinity
			const orderB = b.elements.listerOrderNumber ?? Infinity
			return orderA - orderB
		})
	}

	return items
}

export function dropDownItems(
	sortingElements: Tersed<ListerSortingContentItem>
): {value: string; label: string}[] {
	return [
		{
			value: 'aZ',
			label: textChecker(sortingElements.elements.aZ.value, 'A-Z'),
		},
		{
			value: 'zA',
			label: textChecker(sortingElements.elements.zA.value, 'Z-A'),
		},
		{
			value: 'recommended',
			label: textChecker(
				sortingElements.elements.recommended.value,
				'Recommended'
			),
		},
	]
}
