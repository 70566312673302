import type {JSX, ReactElement, ReactNode} from 'react'
import {cloneElement, isValidElement, Children} from 'react'
import {clsx} from 'clsx'
import Image from 'next/image'
import {loadImageFromKontentAI} from '@/imageLoader'

interface ImageProps {
	alignment?: 'top' | 'center' | 'bottom'
	alt?: string | null
	children?:
		| ReactElement<{
				className: string
		  }>[]
		| null
	className?: string
	contain?: boolean
	mediaAlignRight?: boolean
	mediaLeftPosition?: string
	mediaTopPosition?: string
	src?: string
	sizes?: string
	packshotImage?: ReactNode
	isMobile?: boolean
}

export const HeaderImage = ({
	alignment = 'top',
	alt = '',
	children = null,
	className = '',
	contain = false,
	mediaAlignRight = false,
	mediaLeftPosition = '',
	mediaTopPosition = '',
	src = '',
	packshotImage = null,
	isMobile = false,
}: ImageProps): JSX.Element => {
	let child: ReactNode = null

	const alignments: Record<string, string> = {
		top: 'top-0',
		center: 'inset-y-0',
		bottom: 'bottom-0',
	}

	const getMediaAlign = (): string => {
		const isNumberBetween0And100 = (number: number): boolean =>
			number >= 0 && number <= 100
		const numberRegex = /^\d+$/
		if (mediaAlignRight) {
			return `${
				numberRegex.test(mediaLeftPosition) &&
				isNumberBetween0And100(Number(mediaLeftPosition))
					? mediaLeftPosition
					: '90'
			}% ${
				numberRegex.test(mediaTopPosition) &&
				isNumberBetween0And100(Number(mediaTopPosition))
					? mediaTopPosition
					: '10'
			}%`
		}
		return ''
	}

	const classes = clsx(
		'w-full',
		contain
			? 'object-contain object-top max-h-[380px]'
			: 'min-h-full md:object-cover',
		'md:absolute inset-x-0 m-auto',
		contain && !packshotImage ? 'mt-12 md:mt-0' : '',
		alignments[alignment],
		className
	)

	if (children) {
		child = (
			<picture>
				{Children.map(children, (el) => {
					if (isValidElement(el) && el.type === 'img') {
						return cloneElement(el, {
							className: classes,
						})
					}
					return el
				})}
			</picture>
		)
	} else if (src) {
		const videoClasses = clsx(
			'w-full min-h-full object-contain md:absolute inset-x-0 m-auto',
			alignments[alignment],
			className
		)

		if (['mp4'].includes(src.split('.').pop() || '')) {
			child = (
				<video
					autoPlay
					className={videoClasses}
					loop
					muted
					playsInline
					preload="true"
					src={src}
				/>
			)
		} else {
			let imgStyle = {}
			if (getMediaAlign()) {
				imgStyle = {objectPosition: getMediaAlign()}
			}
			child = (
				<Image
					alt={alt || ''}
					className={classes}
					height={391}
					loader={loadImageFromKontentAI}
					src={src}
					style={imgStyle}
					width={isMobile || contain ? 375 : 2000}
				/>
			)
		}
	}

	return (
		<div className={clsx('h-full', {'mt-12': !contain, true: contain})}>
			{child}
			{packshotImage}
		</div>
	)
}
