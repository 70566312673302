import http from '@/utils/axios'
import type {
	MarketingBrandResponse,
	MarketingBrand,
} from '@/_new-code/models/consumer-engagement-models'

export const fetchMarketingBrands = async (
	locale: string
): Promise<MarketingBrand[]> => {
	const endpoint = `/api/species/all/marketingbrands?locale=${locale}`
	const {data: response} = await http<MarketingBrandResponse>(endpoint)
	const {data} = response

	return data
}
