import type {ReactNode, JSX} from 'react'

interface KeyInfoModuleColumnsProps {
	children: ReactNode
	className?: string
	title?: string
}

export const KeyInfoModuleColumns = ({
	children,
	className,
	title,
}: KeyInfoModuleColumnsProps): JSX.Element => {
	return (
		<div
			className={`${className} align-center mx-auto mt-5 flex justify-center`}
			title={title}
		>
			<div className="mx-auto flex w-[95vw] max-w-[900px]">
				{title ? (
					<h2 className="mb-4 text-center md:mb-8">{title}</h2>
				) : null}
				<div className="mx-auto grid grid-cols-1 gap-y-5 px-2 md:grid-cols-2 md:gap-x-8 md:px-5 lg:px-6">
					{children}
				</div>
			</div>
		</div>
	)
}
