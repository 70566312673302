import type {QueryFunctionContext} from 'react-query'
import type {IPagination} from '@kontent-ai/delivery-sdk'
import http from '@/utils/axios'
import type {FaqsOutput} from '@/pages/api/v2/faqs-by-codenames'
import type {FaqItemContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/faq-block'
import type {Tersed} from '@/_new-code/services/kontent-ai/types'
import type {
	FaqsByCodenamesQueryKey,
	FaqsByCodenameError,
} from '@/_new-code/services/faqs-by-codenames/models'

export const useFetchFaqsByCodenames = async (
	context: QueryFunctionContext<FaqsByCodenamesQueryKey>
): Promise<{
	items: Tersed<FaqItemContentItem>[]
	success: true
	status: number
	statusText: string
	pagination: IPagination
}> => {
	const [, params] = context.queryKey
	const searchParams = new URLSearchParams({
		locale: params.locale,
	})

	if (params.codenames) {
		searchParams.set(
			'codenames',
			encodeURIComponent(params.codenames.join())
		)
	}

	const {data} = await http<FaqsOutput<Tersed<FaqItemContentItem>>>(
		`/api/v2/faqs-by-codenames?${searchParams.toString()}`
	)

	if (!data.success) {
		// eslint-disable-next-line @typescript-eslint/no-throw-literal -- no alternative
		throw new Error(data.message) as FaqsByCodenameError
	}

	// We filter out any faqs that have zero content in them (empty rich text always includes '<p><br></p>')
	return {
		...data,
		items: data.items.filter(
			(item) =>
				item.elements.title.trim().length > 0 &&
				item.elements.content.value !== '<p><br></p>'
		),
	}
}
