import type {JSX} from 'react'
import type {Tersed} from '@/_new-code/services/kontent-ai/types'
import type {
	DateSelectionContentItem,
	SharedQuestionProps,
} from '@/_new-code/products/campaign-forms/cdc-screen-set-wrapper/models'
import {DatePicker} from './date-picker'

interface DateQuestionProps extends SharedQuestionProps {
	question: Tersed<DateSelectionContentItem>
}

export const DateQuestion = ({
	question,
	selectedOptionsCallback,
	injectedElementsCallback,
	errorsCallback,
	validationEvent,
}: DateQuestionProps): JSX.Element => {
	return (
		<DatePicker
			errorsCallback={errorsCallback}
			injectedElementsCallback={injectedElementsCallback}
			question={question}
			selectedOptionsCallback={selectedOptionsCallback}
			validationEvent={validationEvent}
		/>
	)
}
