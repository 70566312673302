import type {ReactNode, JSX} from 'react'

interface SmallPromotionalModuleProps {
	className: string
	title: string
	text: string
	promoButton: ReactNode
	termsText: string
	termsLink: string
	endDate: string
	horizontal?: boolean
}

export const SmallPromotionalModule = ({
	className,
	title,
	text,
	promoButton,
	termsText,
	termsLink,
	endDate,
	horizontal = false,
}: SmallPromotionalModuleProps): JSX.Element => {
	return (
		<div
			className={`container-narrow rounded-lg border-2 border-dashed p-4 shadow-secondary md:px-8 md:py-6 ${className}`}
		>
			<div
				className={`${
					horizontal
						? 'flex flex-col items-center md:flex-row'
						: 'md:flex'
				} justify-between`}
			>
				{horizontal ? (
					<div className="flex flex-col items-center justify-center md:w-2/3 md:flex-row md:pr-6 ">
						<p className="mb-4 md:mb-0 md:mr-6 md:w-2/3">{text}</p>
						<h2 className="mb-4 text-4xl md:mb-0 md:ml-6 md:w-1/3">
							{title}
						</h2>
					</div>
				) : (
					<div className="md:w-2/3 md:pr-6">
						<h2 className="pb-2 text-4xl">{title}</h2>
						<p>{text}</p>
					</div>
				)}
				<div className="md:w-1/3 md:pl-6 md:text-right">
					{promoButton}
				</div>
			</div>
			<div
				className={`${
					horizontal ? 'pt-0' : 'pt-8'
				} justify-between italic md:flex`}
			>
				<a
					className="underline"
					href={termsLink}
					rel="noopener noreferrer"
					target="_blank"
				>
					{termsText}
				</a>
				<p className="pt-2 md:pt-0">{endDate} </p>
			</div>
		</div>
	)
}
