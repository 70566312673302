import type {ReactElement} from 'react'

interface CardFooterProps {
	children: ReactElement
	className?: string
	isFooterCard?: boolean
}

export const CardFooter = ({
	children,
	className,
	isFooterCard,
}: CardFooterProps): ReactElement => {
	return (
		<div className={`mt-auto ${isFooterCard ? '' : 'pt-8'} ${className}`}>
			{children}
		</div>
	)
}
