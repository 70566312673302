import type {ReactElement} from 'react'
import {useEffect, useState} from 'react'
import {clsx} from 'clsx'
import type {FaqItemProps} from '../faq-item/index'

type FaqItem = ReactElement<FaqItemProps>

interface FaqProps {
	children?: FaqItem | FaqItem[]
	className?: string
}

export const Faq = ({children, className = ''}: FaqProps) => {
	const [isContextUsed, setIsContextUsed] = useState<boolean>()

	useEffect(() => {
		setIsContextUsed(Boolean(document.querySelector('#theme')))
	}, [])

	return (
		<dl
			className={clsx(
				isContextUsed ? 'bg-alternative' : 'bg-white',
				'shadow-tertiary rounded-lg',
				className
			)}
		>
			{children}
		</dl>
	)
}
