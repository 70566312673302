import type {QueryFunctionContext, UseQueryResult} from 'react-query'
import {useQuery} from 'react-query'
import type {DiseaseByPlaceIdResponse} from '@/_new-code/products/disease-map/fetch-disease'
import type {GetCaseArgs} from '@/_new-code/services/disease-api/models'
import http from '@/utils/axios'
import type {GetReturnType} from '@/_new-code/services/disease-api/api'
import type {
	NearbyCasesResponse,
	NearbyDisease,
} from '@/_new-code/products/disease-map/parasite-tracker/new-parasite-map/types'
import {toYYYYMMDD} from '@/_new-code/utilities/dates'

async function fetchDiseaseByPlaceId({
	queryKey: [, args],
}: QueryFunctionContext<[string, GetCaseArgs]>): Promise<GetReturnType> {
	const ENDPOINT = '/api/disease/group-by-administrative-area'
	const params = new URLSearchParams(
		Object.entries(args)
			.map<[string, string] | null>(
				([key, value]: [string, GetCaseArgs[keyof GetCaseArgs]]) => {
					if (
						typeof value === 'undefined' ||
						(typeof value === 'string' && value === '')
					)
						return null
					if (typeof value === 'string') return [key, value]
					if (typeof value === 'number') return [key, String(value)]
					return [key, value.join(',')]
				}
			)
			.filter(Boolean)
	)

	if (args.startDate)
		params.set('startDate', args.startDate.split('T')[0] ?? '')

	if (args.endDate) params.set('endDate', args.endDate.split('T')[0] ?? '')

	const endpoint = `${ENDPOINT}?${params.toString()}`
	const {data} = await http<DiseaseByPlaceIdResponse>(endpoint)
	return data
}

async function fetchNearbyCases({
	queryKey: [, args],
}: QueryFunctionContext<
	[string, NearbyDisease]
>): Promise<NearbyCasesResponse> {
	const {lat, lng, selectedRadius, diseases, startDate, endDate} = args
	const endDateLiteral = endDate
		? `&endDate=${toYYYYMMDD(new Date(endDate))}`
		: `&endDate=${toYYYYMMDD(new Date())}`
	const startDateLiteral = startDate
		? `&startDate=${toYYYYMMDD(new Date(startDate))}`
		: ''
	const ENDPOINT = `/api/disease/nearby-cases?lat=${lat}&lng=${lng}&radius=${selectedRadius}&diseases=${diseases}&statuses=New,Approved${startDateLiteral}${endDateLiteral}`
	const endpoint = ENDPOINT
	const {data} = await http<NearbyCasesResponse>(endpoint)
	return data
}

export function useDiseaseData(
	args: GetCaseArgs
): UseQueryResult<GetReturnType, {success: false; error: unknown}> {
	return useQuery(['DiseaseCasesByPlaceId', args], fetchDiseaseByPlaceId, {
		refetchOnWindowFocus: false,
		cacheTime: 15 * (60 * 1000),
	})
}

export function useNearbyDiseaseData(
	args: NearbyDisease
): UseQueryResult<NearbyCasesResponse, {success: false; error: unknown}> {
	return useQuery(['NearbyDiseaseCases', args], fetchNearbyCases, {
		refetchOnWindowFocus: false,
		cacheTime: 15 * (60 * 1000),
	})
}
