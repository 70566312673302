import Link from 'next/link'
import NextImage from 'next/image'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {clsx} from 'clsx'
import {useEffect, useState} from 'react'
import {getValidSpacingOrNone} from '@/_new-code/products/flexible-web-toolkit/styles'
import {loadImageFromKontentAI} from '@/imageLoader'
import type {Block, TersedAsset} from '@/_new-code/services/kontent-ai/types'
import {useWindowSize} from '@/_new-code/utilities/hooks/use-window-size'
import {getContainerClass} from '../helpers'

enum ImageWidth {
	Small = 'w-20',
	Medium = 'w-40',
	Large = 'w-80',
	Full = 'w-full',
}

export type ImageContentItem = IContentItem<{
	image: Elements.AssetsElement
	mobileImage: Elements.AssetsElement
	imageWidth: Elements.MultipleChoiceElement
	snippetSpacingSpacing: Elements.MultipleChoiceElement
	snippetContainerResize: Elements.MultipleChoiceElement
	imageLink: Elements.TextElement
	openInNewTab: Elements.MultipleChoiceElement
	removeHyperlink?: Elements.MultipleChoiceElement
	effectCls?: Elements.TextElement
}>

export const ImageBlock: Block<ImageContentItem> = ({block}) => {
	// The string replace is used to remove the top & bottom margin on mobile (without editing the highly reused spacing const)

	const spacingChoice = getValidSpacingOrNone(
		block.elements.snippetSpacingSpacing[0]?.codename || 'default'
	).replace('my', 'my-0 sm:my')
	const imageWidth = block.elements.imageWidth[0]?.codename

	const sizes: Record<string, string> = {
		small: ImageWidth.Small,
		medium: ImageWidth.Medium,
		large: ImageWidth.Large,
		full: ImageWidth.Full,
	}

	const {isMobile} = useWindowSize()

	const imgSize =
		sizes[imageWidth as keyof typeof imageWidth] || ImageWidth.Medium
	const containerSize = getContainerClass(
		block.elements.snippetContainerResize[0]?.codename ?? '',
		'container-narrow'
	)

	const [image, setImage] = useState<TersedAsset | null>(null)

	useEffect(() => {
		if (isMobile && block.elements.mobileImage[0]) {
			setImage(block.elements.mobileImage[0])
		} else {
			setImage(block.elements.image[0] ?? null)
		}
	}, [block.elements.image, block.elements.mobileImage, isMobile])

	return (
		<div
			className={clsx(
				`flex rounded-md sm:px-8 sm:py-4`,
				containerSize,
				spacingChoice
			)}
			data-kontent-element-codename="card_image"
		>
			{image ? (
				<>
					{block.elements.imageLink &&
					!block.elements.removeHyperlink ? (
						<Link
							className="block h-full w-full"
							href={block.elements.imageLink || ''}
							rel="noreferrer"
							target={
								block.elements.openInNewTab[0]?.codename ===
								'yes'
									? '_blank'
									: '_self'
							}
						>
							{}

							<NextImage
								alt={image.description || ''}
								className={clsx(
									imgSize,
									block.elements.effectCls as string
								)}
								height={image.height || 300}
								loader={loadImageFromKontentAI}
								src={image.url}
								width={image.width || 500}
							/>
						</Link>
					) : (
						<NextImage
							alt={image.description || ''}
							className={clsx(
								imgSize,
								block.elements.effectCls as string
							)}
							height={image.height || 300}
							loader={loadImageFromKontentAI}
							src={image.url}
							width={image.width || 500}
						/>
					)}
				</>
			) : null}
		</div>
	)
}
