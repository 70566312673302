import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {spacingPadding} from '@/_new-code/products/flexible-web-toolkit/styles'
import type {ArticleListerBlockContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/article-lister-block'
import type {Block} from '@/_new-code/services/kontent-ai/types'

export type ColumnCardListerContentItem = IContentItem<{
	title: Elements.TextElement
	articleBlocks: Elements.LinkedItemsElement<ArticleListerBlockContentItem> // TODO: add enhanced card
	snippetSpacingSpacing: Elements.MultipleChoiceElement
}>

export const ColumnCardListerBlock: Block<ColumnCardListerContentItem> = ({
	block,
	BlockMapper,
	...context
}) => {
	const componentSpacing = (() => {
		switch (block.elements.snippetSpacingSpacing[0]?.codename) {
			case 'default': {
				return spacingPadding.default
			}
			case 'top': {
				return spacingPadding.top
			}
			case 'bottom': {
				return spacingPadding.bottom
			}
			case 'none': {
				return spacingPadding.none
			}
			default: {
				return spacingPadding.none
			}
		}
	})()

	const {title} = block.elements

	return (
		<div className={componentSpacing}>
			<h2 className="pb-1 pt-2 text-center">{title}</h2>
			<div className="mx-auto flex flex-col items-center">
				{block.elements.articleBlocks.map((articleBlock) => {
					return (
						<div
							className="article-block my-4 !p-0 p-4"
							key={articleBlock.system.id}
						>
							<BlockMapper
								blocks={[articleBlock]}
								key={articleBlock.system.id}
								{...context}
								className="h-full"
							/>
						</div>
					)
				})}
			</div>
		</div>
	)
}
