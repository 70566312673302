import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {defaultMargins} from '@/_new-code/products/flexible-web-toolkit/styles'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {FindYourRep} from '@/_new-code/products/flexible-web-toolkit/components/find-your-rep'

type RepresentativeContentItem = IContentItem<{
	name: Elements.TextElement
	jobTitle: Elements.TextElement
	phoneNumber: Elements.TextElement
	email: Elements.TextElement
	thumbnailUrl: Elements.AssetsElement
}>

export type FindYourRepContentItem = IContentItem<{
	reps: Elements.LinkedItemsElement<RepresentativeContentItem>
}>

export const FindYourRepBlock: Block<FindYourRepContentItem> = ({
	block: {
		elements: {reps},
	},
}) => {
	const allReps = reps.map(({elements: rep}) => ({
		name: rep.name,
		jobTitle: rep.jobTitle,
		phoneNumber: rep.phoneNumber,
		email: rep.email,
		thumbnailUrl: rep.thumbnailUrl[0]?.url ?? undefined,
	}))

	return (
		<div className={defaultMargins} data-kontent-element-codename="reps">
			<FindYourRep
				className="m-2 mx-auto"
				desc="Select a rep from the dropdown to display their details"
				reps={allReps}
				title="Find Your Representative"
			/>
		</div>
	)
}
