import type {PropsWithChildren, JSX} from 'react'

export interface AccordionItemProps {
	hideTitle?: boolean
	title?: string
	key?: string
}

export const AccordionItem = ({
	children,
	hideTitle = false,
	title = '',
}: PropsWithChildren<AccordionItemProps>): JSX.Element => {
	return (
		<>
			{!hideTitle && <h2>{title}</h2>}
			{children}
		</>
	)
}
