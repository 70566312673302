import type {YouTubeEvent} from 'react-youtube'
import YouTube from 'react-youtube'
import type {ReactElement} from 'react'
import {useEffect, useState, useRef} from 'react'
import {clsx} from 'clsx'
import {pushToDataLayer} from '@/utils/analytics'

export interface YoutubeVideoRef {
	playVideo: () => void
	pauseVideo: () => void
}

interface YoutubeVideoProps {
	className?: string
	videoId: string
	controls?: number
	isPlaying?: (play: boolean) => void
	playAt?: number
	isNotSkippable?: boolean
	closeVideoOnPause?: boolean
	pauseHandler: (data?: boolean) => void
	endVideoHandler?: () => void
	aspectRatio?: string
	privacyMode: boolean
	ref?: React.RefObject<YoutubeVideoRef | null>
}

interface Video {
	target: {
		getCurrentTime: () => number
	}
}

interface PlayerEvent {
	target: Player
}

interface Player {
	seekTo: (playAt: number) => void
	unMute: () => void
	pauseVideo: () => void
	mute: () => void
	getDuration: () => number
	videoTitle: string
	playerInfo: {
		videoUrl: string
	}
}

export const YoutubeVideo = ({
	className = '',
	videoId = '',
	isPlaying = () => null,
	playAt = 0,
	isNotSkippable = false,
	pauseHandler = () => null,
	endVideoHandler = () => null,
	aspectRatio = '16:9',
	privacyMode = false,
}: YoutubeVideoProps): ReactElement => {
	const calculateAspectPercentage = (aspectRatioVal: string): number => {
		const [widthString, heightString]: string[] = aspectRatioVal.split(':')
		const width: number = parseInt(widthString ?? '1', 10)
		const height: number = parseInt(heightString ?? '1', 10)

		return (height / width) * 100
	}

	const [hasRequestedPlayback, setHasRequestedPlayback] = useState(false)
	const [player, setPlayer] = useState<Player>()
	const aspectPercentage = calculateAspectPercentage(aspectRatio)
	const [initialPlay, setInitialPlay] = useState(true)
	const [duration, setDuration] = useState(1)
	const [videoTitle, setVideoTitle] = useState('')
	const [videoURL, setVideoURL] = useState('')
	const [progressTargets, setProgressTargets] = useState([10, 25, 50, 75])
	const [video, setVideo] = useState<Video | null>(null)

	// Using useRef for pollingTimerId to avoid re-renders
	const pollingTimerIdRef = useRef<NodeJS.Timer | null>(null)

	useEffect(() => {
		const handlePollingTick = (): void => {
			if (video?.target.getCurrentTime()) {
				const watched = video.target.getCurrentTime()
				const watchedPercentage = (watched / duration) * 100
				const numberOfTargets = progressTargets.length

				const progressTargetsFiltered = [...progressTargets].filter(
					(targetPercentage) => {
						const hit = watchedPercentage >= targetPercentage
						if (hit) {
							pushToDataLayer({
								event: 'video_progress',
								video_percent: `${targetPercentage}%`,
								video_title: videoTitle,
								video_url: videoURL,
								video_duration: duration,
							})
						}
						return !hit
					}
				)
				const progressMade =
					progressTargetsFiltered.length < numberOfTargets
				if (progressMade) setProgressTargets(progressTargetsFiltered)
			}
		}

		pollingTimerIdRef.current = setInterval(handlePollingTick, 2000)

		return () => {
			if (pollingTimerIdRef.current) {
				clearInterval(pollingTimerIdRef.current)
				pollingTimerIdRef.current = null
			}
		}
	}, [video, duration, progressTargets, videoTitle, videoURL])

	const videoOnReady = (event: PlayerEvent): void => {
		event.target.mute()
		setPlayer(event.target)
		setDuration(event.target.getDuration())
		setVideoTitle(event.target.videoTitle)
		setVideoURL(event.target.playerInfo.videoUrl)
		if (hasRequestedPlayback) playVideo()
	}

	const playVideo = (): void => {
		setHasRequestedPlayback(true)
		if (!isReady()) return
		player?.seekTo(playAt)
		player?.unMute()
	}

	const startHandler = (): void => {
		if (initialPlay) {
			pushToDataLayer({
				event: 'video_start',
				video_title: videoTitle,
				video_url: videoURL,
			})
			setInitialPlay(false)
		}
	}

	const endVideoEventHandler = (): void => {
		pushToDataLayer({
			event: 'video_complete',
			video_percent: '100%',
			video_title: videoTitle,
			video_url: videoURL,
			video_duration: duration,
		})
		endVideoHandler()
	}

	const isReady = (): boolean => {
		return player !== undefined
	}

	const opts = {
		height: '100%',
		width: '100%',
		playerVars: {
			autoplay: 0,
			rel: 0,
			controls: 1,
			modestbranding: 1,
			disablekb: isNotSkippable,
			fs: !isNotSkippable,
			playsinline: isNotSkippable,
		},
		host: privacyMode
			? 'https://www.youtube-nocookie.com'
			: 'https://www.youtube.com',
	}

	return (
		<div
			className={clsx(
				'relative h-0 w-full max-w-screen-xl overflow-hidden',
				className
			)}
			style={{paddingBottom: `${aspectPercentage}%`}}
		>
			<YouTube
				className="absolute left-0 top-0 h-full w-full"
				onEnd={endVideoEventHandler}
				onPause={(videoEvent: Video) => {
					pauseHandler(videoEvent.target.getCurrentTime() !== 0)
					isPlaying(false)
				}}
				onPlay={(videoEvent: YouTubeEvent) => {
					isPlaying(true)
					startHandler()
					setVideo(videoEvent)
				}}
				onReady={videoOnReady}
				opts={opts}
				videoId={videoId}
			/>
			{isNotSkippable ? (
				<div
					className="absolute left-0 right-0 h-6"
					style={{bottom: '35px'}}
				/>
			) : null}
		</div>
	)
}
