import {
	useEffect,
	FC,
	PropsWithChildren,
	BaseSyntheticEvent,
	type JSX,
} from 'react'
import {useForm, FormProvider} from 'react-hook-form'

export const ErrorMessage: FC<{message: string}> = ({message}): JSX.Element => (
	<span className="flex items-center py-1 text-red-500">
		<svg
			width="20"
			height="20"
			viewBox="0 0 27 27"
			className="inline-block fill-current"
		>
			<path d="M13.3,26.8c-3.6,0-7-1.4-9.5-4c-2.5-2.5-4-6-4-9.5c0-3.6,1.4-7,4-9.5c2.5-2.5,6-4,9.5-4c3.6,0,7,1.4,9.5,4c2.5,2.5,4,6,4,9.5c0,3.6-1.4,7-4,9.5C20.3,25.4,16.8,26.8,13.3,26.8z M13.6,17.8c-0.7,0-1.4,0.2-1.9,0.6c-0.4,0.4-0.7,.1-0.7,1.7c0,0.6,0.2,1.2,0.7,1.7c0.5,0.4,1.2,0.6,1.9,0.6c0.7,0,1.3-0.2,1.8-0.6c0.5-0.4,0.7-1.1,0.7-1.7c0-0.6-0.2-1.2-0.6-1.7C14.9,18,14.2,17.8,13.6,17.8L13.6,17.8z M11,4.6L11,4.6l0.6,11.4h4l0.6-11.4H11z" />
		</svg>
		<span className="pl-2 !text-xs text-pet-primary">{message}</span>
	</span>
)

export interface FormProps {
	action: string
	className?: string
	ajaxSubmission?: boolean
	onSubmit?: (data: any) => void
	setFormField?: any
}

const Form: FC<PropsWithChildren<FormProps>> = ({
	action,
	className = '',
	children,
	ajaxSubmission = false,
	onSubmit = () => {},
	setFormField = null,
}) => {
	if (!children) return null

	const methods = useForm({
		mode: 'onBlur',
	})

	useEffect(() => {
		if (setFormField) {
			methods.setValue(
				Object.keys(setFormField)[0]!,
				Object.values(setFormField)[0]
			)
		}
	}, [setFormField])

	const submitForm = (data: unknown, e: BaseSyntheticEvent | undefined) => {
		onSubmit(data)
		if (!ajaxSubmission && e) e.target.submit()
	}
	return (
		<div className={className}>
			<FormProvider {...methods}>
				<form
					action={action}
					onSubmit={methods.handleSubmit(submitForm)}
					noValidate
				>
					{children}
				</form>
			</FormProvider>
		</div>
	)
}

export default Form
