import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {clsx} from 'clsx'
import {spacing} from '@/_new-code/products/flexible-web-toolkit/styles'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {CardImage} from '@/_new-code/products/flexible-web-toolkit/components/card/card-image'
import {CardFooter} from '@/_new-code/products/flexible-web-toolkit/components/card/card-footer'
import {Card as ClCard} from '@/_new-code/products/flexible-web-toolkit/components/card/index'
import type {CtaButtonContentItem} from '../cta-button'
import {ReusableCTAButtonComponent} from '../cta-button'
import type {RichTextContentItem} from '../rich-text'
import {RichTextBlock} from '../rich-text'

export type RichCardContentItem = IContentItem<{
	title: Elements.TextElement
	image: Elements.AssetsElement
	richText: Elements.LinkedItemsElement<RichTextContentItem>
	button: Elements.LinkedItemsElement<CtaButtonContentItem>
	snippetSpacingSpacing: Elements.MultipleChoiceElement
}>

export const RichCardBlock: Block<RichCardContentItem> = ({
	block,
	...context
}) => {
	const spacingChoice =
		spacing[
			block.elements.snippetSpacingSpacing[0]
				?.codename as keyof typeof spacing
		]
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- not unnecessary, snippetSpacingSpacing may be null
	const componentSpacing = !spacingChoice ? spacing.none : spacingChoice

	const cardImage = block.elements.image[0]

	return (
		<ClCard
			className={clsx('h-full flex-none p-4', componentSpacing)}
			title={
				<span data-kontent-element-codename="title">
					{block.elements.title}
				</span>
			}
		>
			{cardImage ? (
				<CardImage
					alt={cardImage.description}
					data-kontent-element-codename="image"
					src={cardImage.url}
				/>
			) : null}
			{block.elements.richText[0] ? (
				<RichTextBlock
					block={block.elements.richText[0]}
					{...context}
				/>
			) : null}
			{block.elements.button[0] ? (
				<CardFooter>
					<ReusableCTAButtonComponent
						block={block.elements.button[0]}
						className="w-full"
						{...context}
					/>
				</CardFooter>
			) : null}
		</ClCard>
	)
}
