import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {Anchor} from '@/_new-code/components/anchor'

export type AnchorContentItem = IContentItem<{
	anchorId: Elements.TextElement
	scrollMarginTopDesktop: Elements.TextElement
	scrollMarginTopMobile: Elements.TextElement
}>

export const AnchorBlock: Block<AnchorContentItem> = ({block}) => {
	return (
		<Anchor
			anchorLink={block.elements.anchorId}
			data-kontent-element-codename="anchor_id"
			scrollMarginTop={{
				desktop: block.elements.scrollMarginTopDesktop || '8rem',
				mobile: block.elements.scrollMarginTopMobile || '4rem',
			}}
		/>
	)
}
