import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import Image from 'next/image'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {RichTextRenderer} from '@/_new-code/products/flexible-web-toolkit/components/rich-text-renderer'
import {loadImageFromKontentAI} from '@/imageLoader'
import type {CtaButtonContentItem} from '../cta-button'
import {ReusableCTAButtonComponent} from '../cta-button'
import {FeatureBlock} from './feature-block'

export type ModuleBlockContentItem = IContentItem<{
	title: Elements.TextElement
	description: Elements.RichTextElement
	image: Elements.AssetsElement
	reversed: Elements.MultipleChoiceElement
	button: Elements.LinkedItemsElement<CtaButtonContentItem>
}>

export const ModuleBlockBlock: Block<ModuleBlockContentItem> = ({
	block,
	...context
}) => {
	const image = block.elements.image[0]

	return (
		<FeatureBlock
			image={
				image ? (
					<Image
						alt=""
						data-kontent-element-codename="image"
						height={510}
						loader={loadImageFromKontentAI}
						src={image.url}
						width={510}
					/>
				) : null
			}
			reversed={block.elements.reversed[0]?.codename === 'yes'}
		>
			<h3 data-kontent-element-codename="title">
				{block.elements.title}
			</h3>
			<RichTextRenderer
				className="py-6"
				data-kontent-element-codename="description"
				element={block.elements.description}
				{...context}
			/>
			{block.elements.button.map((button) => (
				<ReusableCTAButtonComponent
					block={button}
					key={button.system.id}
					{...context}
				/>
			))}
		</FeatureBlock>
	)
}
