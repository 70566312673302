import Link from 'next/link'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {useRouter} from 'next/router'
import {clsx} from 'clsx'
import {addLocalePrefix} from '@/_new-code/utilities/add-locale-prefix'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {CardImage} from '@/_new-code/products/flexible-web-toolkit/components/card/card-image'
import {CardFooter} from '@/_new-code/products/flexible-web-toolkit/components/card/card-footer'
import {Card} from '@/_new-code/products/flexible-web-toolkit/components/card'
import {ReusableCTAButtonComponent} from '../cta-button'
import type {CtaButtonContentItem} from '../cta-button'

export type EnhancedCardContentItem = IContentItem<{
	title: Elements.TextElement
	image: Elements.AssetsElement
	imageOnLeft: Elements.MultipleChoiceElement
	content: Elements.RichTextElement
	button: Elements.LinkedItemsElement<CtaButtonContentItem>
	hasTitleLink: Elements.MultipleChoiceElement
	titleUrl: Elements.TextElement
	makeTheImageAClickableLink: Elements.MultipleChoiceElement
	removeBorders: Elements.MultipleChoiceElement
	openInNewTab: Elements.MultipleChoiceElement
}>

export const EnhancedCardBlock: Block<EnhancedCardContentItem> = ({
	block: {
		elements: {
			button,
			content,
			hasTitleLink,
			image,
			imageOnLeft,
			makeTheImageAClickableLink,
			openInNewTab,
			removeBorders,
			title,
			titleUrl,
		},
		system: {codename},
	},
	...context
}) => {
	const {locale = ''} = useRouter()
	return (
		<Card
			className={clsx(
				removeBorders[0]?.codename === 'yes' ? undefined : 'p-4',
				`h-full flex-none text-black`
			)}
			data-kontent-element-codename="enhanced_card"
			imageOnLeft={imageOnLeft[0]?.codename === 'yes'}
			removeBorders={removeBorders[0]?.codename === 'yes'}
			title={
				<span data-kontent-element-codename="card_title">
					{hasTitleLink[0]?.codename === 'yes' && titleUrl !== '' ? (
						<Link
							className="pb-2 underline"
							href={titleUrl || ''}
							rel="noreferrer"
							target={
								openInNewTab[0]?.codename === 'yes'
									? '_blank'
									: '_self'
							}
						>
							{}

							{title}
						</Link>
					) : (
						title
					)}
				</span>
			}
		>
			{image[0] ? (
				<CardImage
					alt={image[0].description}
					className={clsx(
						'h-48',
						removeBorders[0]?.codename === 'yes'
							? 'object-cover'
							: '',
						codename.startsWith('brand')
							? 'object-cover'
							: 'object-contain'
					)}
					data-kontent-element-codename="image"
					imageUrl={
						makeTheImageAClickableLink[0]?.codename === 'yes' &&
						button[0]
							? addLocalePrefix(button[0].elements.url, locale)
							: ''
					}
					src={image[0].url}
				/>
			) : null}
			<div
				className="content m-0 w-full"
				dangerouslySetInnerHTML={{__html: content.value}}
				data-kontent-element-codename="text"
			/>
			<CardFooter>
				<>
					{button.map((b, index, arr) => (
						<ReusableCTAButtonComponent
							block={b}
							className={clsx(
								`mb-2`,
								arr.length === 1 ? 'w-full' : null,
								index < arr.length - 1 && arr.length !== 1
									? 'mr-4'
									: null,
								removeBorders[0]?.codename === 'yes'
									? 'w-full'
									: null
							)}
							key={b.system.id}
							{...context}
						/>
					))}
				</>
			</CardFooter>
		</Card>
	)
}
