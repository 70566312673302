import {Icon as ClIcon} from '@elanco/component-library-v2'
import Link from 'next/link'
import {useRouter} from 'next/router'
import Image from 'next/image'
import type {JSX} from 'react'
import {loadImageFromKontentAI} from '@/imageLoader'
import {pushToDataLayer} from '@/utils/analytics'
import {logout} from '@/_new-code/products/auth/auth-provider'
import type {
	AuthNavItemLinkContentItem,
	NavItemLinkContentItem,
} from '@/_new-code/products/flexible-web-toolkit/components/header/models'
import type {Tersed} from '@/_new-code/services/kontent-ai/types'
import {Nav} from '../navigation'

export interface HeaderState {
	navFixed: boolean
	navVisible: boolean
}

interface LinkAuthProps {
	kProps: Tersed<NavItemLinkContentItem>
	isTopLevel?: boolean
}

const LinkAuth = ({kProps, isTopLevel = false}: LinkAuthProps): JSX.Element => {
	const router = useRouter()
	const isLogout = kProps.elements.logoutUser[0]?.codename === 'yes'

	const handleOnClick = (): void => {
		const headerDL = (
			kenticoProps: Tersed<NavItemLinkContentItem>
		): void => {
			const eventName = isTopLevel ? 'header_nav_click' : 'menu_nav_click'

			pushToDataLayer({
				event: eventName,
				element_text: kenticoProps.elements.text,
				element_id: kenticoProps.system.id,
			})

			if (kenticoProps.elements.openInNewTab[0]?.codename === 'yes') {
				window.open(kenticoProps.elements.url, '_blank')
			} else {
				void router.push(kenticoProps.elements.url)
			}
		}

		if (isLogout) {
			logout(router, kProps.elements.url || '/')
			return
		}

		headerDL(kProps)
	}
	const widthClass = isTopLevel ? 'md:w-full w-11/12' : 'w-full'
	const tag = (
		// eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions -- TODO: haha lol
		<a
			className={`${widthClass} ${
				kProps.elements.icon.length
					? 'items-center justify-between'
					: ''
			} ${!kProps.elements.url ? 'cursor-default' : ''}`}
			data-kontent-element-codename="text"
			data-kontent-item-id={kProps.system.id}
			onClick={handleOnClick}
			style={{
				display: kProps.elements.icon.length ? 'flex' : 'inline-block',
				paddingTop: '12px',
				paddingBottom: '12px',
			}}
		>
			{kProps.elements.text}
			{Boolean(kProps.elements.icon.length) &&
				kProps.elements.icon.map((icon) => {
					const iconUrl = icon.elements.icon[0]?.url
					if (!iconUrl) return null

					return (
						<ClIcon key={iconUrl} noBorder size="xs">
							<div className="relative w-full">
								<Image
									alt={
										icon.elements.icon[0]?.description ?? ''
									}
									className="w-full object-contain"
									data-kontent-element-codename="icon"
									height={17}
									loader={loadImageFromKontentAI}
									src={iconUrl}
									width={50}
								/>
							</div>
						</ClIcon>
					)
				})}
		</a>
	)

	return (
		<div>
			{kProps.elements.url ? (
				<Link
					href={kProps.elements.url}
					legacyBehavior
					prefetch={false}
				>
					{tag}
				</Link>
			) : (
				<span>{tag}</span>
			)}
		</div>
	)
}
export const getNavLinks = (
	links: Tersed<NavItemLinkContentItem>[],
	authLinks: Tersed<
		AuthNavItemLinkContentItem | NavItemLinkContentItem
	>[] = []
): JSX.Element[] =>
	links.concat(authLinks).map((firstLevelLink, i) => (
		<li
			className="flex flex-col lg:flex-row"
			data-open-by-default={
				firstLevelLink.elements.openByDefault[0]?.codename === 'yes'
			}
			key={firstLevelLink.system.id}
			style={{
				paddingLeft: !i ? '0' : undefined,
				paddingTop: '0',
				paddingBottom: '0',
			}}
		>
			{/* Cries in clib rewriting Link to <a> */}
			{LinkAuth({
				kProps: firstLevelLink,
				isTopLevel: true,
			})}

			{firstLevelLink.elements.sublinks.length > 0 && (
				<Nav.SubNav>
					{firstLevelLink.elements.sublinks.map((secondLevelLink) => {
						const hasSecondLevelIcon = Boolean(
							secondLevelLink.elements.icon.length
						)
						return (
							<li
								className={
									hasSecondLevelIcon
										? 'space-between flex flex-row'
										: undefined
								}
								// data-open_subnav_on_link_click={
								// 	secondLevelLink.elements
								// 		.openSubnavOnLinkClick[0].codename ===
								// 	'yes'
								// }
								key={secondLevelLink.system.id}
							>
								{LinkAuth({
									kProps: secondLevelLink,
								})}

								{secondLevelLink.elements.sublinks.length >
									0 && (
									<Nav.SubNav>
										{secondLevelLink.elements.sublinks.map(
											(thirdLevelLink) => {
												const hasThirdLevelIcon =
													Boolean(
														thirdLevelLink.elements
															.icon.length
													)
												return (
													<li
														className={
															hasThirdLevelIcon
																? 'space-between flex flex-row'
																: undefined
														}
														// data-open_subnav_on_link_click={
														// 	thirdLevelLink
														// 		?.open_subnav_on_link_click
														// 		?.boolean
														// }
														key={
															thirdLevelLink
																.system.id
														}
													>
														{LinkAuth({
															kProps: thirdLevelLink,
														})}
														{Boolean(
															thirdLevelLink
																.elements
																.sublinks.length
														) && (
															<Nav.SubNav>
																{thirdLevelLink.elements.sublinks.map(
																	(
																		fourthLevelLink
																	) => {
																		return (
																			<li
																				// data-open_subnav_on_link_click={
																				// 	fourthLevelLink
																				// 		?.open_subnav_on_link_click
																				// 		?.boolean
																				// }
																				key={
																					fourthLevelLink
																						.system
																						.id
																				}
																			>
																				{LinkAuth(
																					{
																						kProps: fourthLevelLink,
																					}
																				)}
																			</li>
																		)
																	}
																)}
															</Nav.SubNav>
														)}
													</li>
												)
											}
										)}
									</Nav.SubNav>
								)}
							</li>
						)
					})}
				</Nav.SubNav>
			)}
		</li>
	))

interface LinkWrappedImageProps {
	src: string
	alt?: string
	href?: string
	kItemId?: string
	kCodename?: string
	className?: string
}

export const LinkWrappedImage = ({
	src,
	alt = '',
	href,
	kItemId,
	kCodename,
	className,
}: LinkWrappedImageProps): JSX.Element => {
	// eslint-disable-next-line react/no-unstable-nested-components -- TODO: move out
	const Inner = (): JSX.Element => (
		<Image
			alt={alt}
			className={`h-8 ${className ?? ''}`}
			data-kontent-element-codename={kCodename ?? 'image'}
			height={32}
			loader={loadImageFromKontentAI}
			src={src}
			width={32}
		/>
	)

	if (!href) {
		return (
			// eslint-disable-next-line jsx-a11y/anchor-is-valid -- TODO: use a button
			<a aria-label={alt} href="#">
				<Inner />
			</a>
		)
	}

	return (
		<a data-kontent-item-id={kItemId ?? ''} href={href}>
			<Inner />
		</a>
	)
}
