import type {JSX} from 'react'
import type {QuestionType} from '@/_new-code/products/campaign-forms/cdc-screen-set-wrapper/models'
import type {CampaignInterestHandler} from './campaign-interest-handler'
import {CampaignQuestion} from './campaign-question'

interface CampaignQuestionsProps {
	id: string
	campaignQuestions: QuestionType[]
	handler: CampaignInterestHandler
}

export const CampaignQuestions = ({
	id,
	campaignQuestions,
	handler,
}: CampaignQuestionsProps): JSX.Element => {
	return (
		<div className="gigya-composite-control" id={id}>
			{campaignQuestions.map((question) => (
				<CampaignQuestion
					handler={handler}
					key={question.system.id}
					question={question}
				/>
			))}
		</div>
	)
}
