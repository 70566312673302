import {FC, ReactNode, useEffect} from 'react'
import {Controller, useFormContext} from 'react-hook-form'
import ReactSelect, {OptionProps} from 'react-select'
import {ErrorMessage} from '../Form'
import Image from 'next/image'
import {loadImageFromKontentAI} from '@/imageLoader'

interface OptionsType {
	value: string
	label: string
	icon?: string
	additionalText?: string
}

interface SearchableSelectFieldProps {
	label: ReactNode
	name: string
	className?: string
	isOptional?: boolean
	requiredErrorMsg?: string
	defaultValue?: string
	onChange?: (value: string) => void
	disabled?: boolean
	options: OptionsType[]
}

const SearchableSelectField: FC<SearchableSelectFieldProps> = ({
	defaultValue = '',
	className = '',
	options,
	label,
	name,
	isOptional = false,
	requiredErrorMsg = '',
	onChange = () => {},
	disabled = false,
}) => {
	const requiredMsg = requiredErrorMsg || `${label} is required`
	const {control, trigger, formState} = useFormContext()

	useEffect(() => {
		let ignore = false

		async function validation() {
			if (defaultValue && !ignore) {
				await trigger(name)
			}
		}

		validation()

		return () => {
			ignore = true
		}
	}, [defaultValue])

	const IconOption = (props: OptionProps<OptionsType>) => {
		const {data, innerProps} = props
		return (
			<div {...innerProps} className="flex items-center py-2">
				{data.icon && (
					<div className="mr-4 h-10 w-10 flex-shrink-0">
						<Image
							src={data.icon}
							alt={`${data.label} icon`}
							width={40}
							height={40}
							loader={loadImageFromKontentAI}
						/>
					</div>
				)}
				<div className="flex-col">
					<p>{data.label} </p>
					{data.additionalText && (
						<p className="pt-1 text-xs">{data.additionalText}</p>
					)}
				</div>
			</div>
		)
	}

	const customFilter = (option: any, searchText: string) => {
		if (
			option.data.label
				.toLowerCase()
				.includes(searchText.toLowerCase()) ||
			option.data?.additionalText
				?.toLowerCase()
				?.includes(searchText.toLowerCase())
		) {
			return true
		}
		return false
	}

	return (
		<div className={`relative mb-4 ${className}`}>
			<label htmlFor={name} className="w-full text-xs text-pet-primary">
				<span>
					{label}
					{!isOptional && <span className="text-red-600">*</span>}
				</span>
				<div className="relative mt-2">
					<Controller
						render={({field}) => (
							<ReactSelect
								{...field}
								placeholder="Start typing to filter"
								components={{Option: IconOption}} // Use the custom Option component here
								options={options}
								filterOption={customFilter}
								id={name}
								name={name}
								classNames={{
									control: () =>
										`!rounded-md !bg-white !opacity-1 !text-sm !font-normal !text-pet-primary !border !py-[4px] !mb-1 ${
											formState.errors[name]
												? '!border-[#ea0101]'
												: '!border-[#a0aec0]'
										}`,
									singleValue: () =>
										'!text-sm !font-normal !text-pet-primary',
									placeholder: () => '!text-sm !font-normal',
									input: () => '!text-sm !p-0 !m-0',
									indicatorsContainer: () => '!p-0',
									indicatorSeparator: () => '!w-0',
									dropdownIndicator: () =>
										'!p-2 !text-mid-grey',
								}}
								onChange={(e: {
									value: string
									label: string
								}) => {
									if (!e) return
									field.onChange(e.value)
									onChange(e.value ?? '') // Handle null safely
								}}
								value={options.find(
									(i) => i.value === field.value
								)}
								isDisabled={disabled}
							/>
						)}
						name={name}
						defaultValue={defaultValue}
						rules={{required: !isOptional}}
						control={control}
					/>
					{formState.errors[name] && (
						<ErrorMessage message={requiredMsg} />
					)}
				</div>
			</label>
		</div>
	)
}

export default SearchableSelectField
