import type {Reminder} from '@/_new-code/services/reminders/models'
import {groupReminders} from '@/utils/YPAY/reminders'

const hideProducts = [
	'GAL_Dog_Product',
	'SER_Dog_Product',
	'SER_Cat_Product',
	'BEX_Cat_Product',
	'AXD_Cat_Product',
]

export const getMonthlyReminders = (reminders: Reminder[]): Reminder[] => {
	const filteredReminders = reminders.filter(
		({marketingBrand}) => !hideProducts.includes(marketingBrand?.id ?? '')
	)
	return groupReminders(filteredReminders)
}
