import type {ReactNode} from 'react'
import React, {useState, useEffect, useRef, Children} from 'react'
import Slider from 'react-slick'
import styles from './ImageGallery.module.css'

interface ImageGalleryProps {
	children: ReactNode
	className?: string
}

const settings = {
	dots: false,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	adaptiveHeight: true,
	arrows: false,
}

const navSettings = {
	dots: false,
	infinite: true,
	speed: 500,
	slidesToScroll: 1,
}

export const ImageGallery: React.FC<ImageGalleryProps> = ({
	children,
	className,
}) => {
	const [nav1, setNav1] = useState<Slider | null>(null)
	const [nav2, setNav2] = useState<Slider | null>(null)
	const slider1 = useRef<Slider>(null)
	const slider2 = useRef<Slider>(null)

	useEffect(() => {
		if (slider1.current && slider2.current) {
			setNav1(slider1.current)
			setNav2(slider2.current)
		}
	}, [])

	const totalChildLength = Children.count(children)
	const maxChildrenToDisplay = 3
	const childLength = Math.min(maxChildrenToDisplay, totalChildLength)
	const hasPagination = totalChildLength > maxChildrenToDisplay
	const childrenArray = Children.toArray(children)

	return (
		<div
			className={`relative mb-2 ${styles.ImageGallery} ${
				hasPagination ? styles.ImageGalleryWithPagination : ''
			} ${className}`}
		>
			<Slider
				className="mx-auto -mb-[5px] max-w-[800px] overflow-hidden rounded-t-lg shadow-primary"
				{...settings}
				asNavFor={nav2 || undefined}
				ref={slider1}
			>
				{childrenArray}
			</Slider>
			<Slider
				asNavFor={nav1 || undefined}
				className={`${styles.Thumbnails} relative z-10 mx-auto max-w-[800px] rounded-b-lg bg-white p-4 shadow-primary`}
				ref={slider2}
				{...navSettings}
				focusOnSelect
				slidesToShow={childLength}
				swipeToSlide
			>
				{childrenArray.map((x, i) => (
					<div key={i}>
						<button
							className="thumbnail-container mx-[5px] cursor-pointer overflow-hidden rounded-lg border-2 border-white shadow-primary"
							title={`thumbnail-${i}`}
							type="button"
						>
							{x}
						</button>
					</div>
				))}
			</Slider>
		</div>
	)
}
