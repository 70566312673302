import type {ReactElement} from 'react'
import {cloneElement, Children} from 'react'
import {clsx} from 'clsx'

interface StatsIconsProps {
	children?: ReactElement<{className: string}>[]
	className?: string
	col?: number
	grayBG?: boolean
	hasNoFlexWrap?: boolean
}

export const StatsIcons: React.FC<StatsIconsProps> = ({
	children = null,
	className = '',
	col = 0,
	grayBG = true,
	hasNoFlexWrap = false,
}) => {
	const columns: Record<number, string> = {
		1: '',
		2: 'md:w-1/2',
		3: 'md:w-1/3',
		4: 'md:w-1/2 lg:w-1/4',
	}

	const childLength = Array.isArray(children) ? children.length : 0

	const gridCols = col === 0 ? childLength : col

	const icons = Array.isArray(children)
		? Children.map(children, (child) => {
				return (
					<div className={`p-2 ${columns[gridCols]}`}>
						{cloneElement(child, {
							className: `${
								grayBG ? 'bg-gray-100 ' : ''
							}rounded-lg text-center h-full p-8`,
						})}
					</div>
				)
			})
		: null

	return (
		<div className={clsx(className)}>
			<div
				className={clsx(
					hasNoFlexWrap ? 'flex-nowrap' : 'flex-wrap',
					'-mx-2 justify-center md:flex'
				)}
			>
				{icons}
			</div>
		</div>
	)
}
