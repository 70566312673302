import type {JSX, PropsWithChildren} from 'react'
import React from 'react'
import {clsx} from 'clsx'
import styles from './bullet.module.css'

interface BulletsProps {
	className?: string
	twoColumn?: boolean
	threeColumn?: boolean
}

export const Bullet = ({
	children,
	className = '',
	twoColumn = false,
	threeColumn = false,
}: PropsWithChildren<BulletsProps>): JSX.Element => {
	const bulletClasses = clsx(
		styles.bullets,
		"font-semibold [&>li]:relative [&>li]:mb-4 [&>li]:py-1 [&>li]:pl-12 [&>li]:before:absolute [&>li]:before:left-2.5 [&>li]:before:top-2.5 [&>li]:before:block [&>li]:before:h-6 [&>li]:before:w-6 [&>li]:before:scale-[200%] [&>li]:before:bg-no-repeat [&>li]:before:content-['']",
		twoColumn &&
			'sm:flex sm:flex-wrap sm:justify-between sm:[&>li]:w-[48%]',
		threeColumn &&
			"sm:flex sm:flex-wrap sm:justify-between lg:after:w-3/10 lg:after:content-[''] sm:[&>li]:w-[48%] lg:[&>li]:w-3/10",
		className
	)

	return <ul className={bulletClasses}>{children}</ul>
}
