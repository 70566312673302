import {Button} from '@elanco/component-library-v2'
import Link from 'next/link'
import Image from 'next/image'
import {pushToDataLayer} from '@/utils/analytics'
import {loadImageFromKontentAI} from '@/imageLoader'
import type {ExtendedBlock} from '@/_new-code/services/kontent-ai/types'
import type {PetNameFormContentItem} from '@/_new-code/products/pet-name-finder/pet-name-form'
import type {
	useNameGenerator,
	useTraits,
} from '@/_new-code/products/pet-name-finder/pet-name-form/hooks'
import {DisplayPetNames} from '../pet-view-all-names/pet-names-list'
import {CardWrapper} from './card-wrapper'
import type {TraitsByType} from './pet-trait-card'
import {PetTraitCardBlock} from './pet-trait-card'

export const NamesResultBlock: ExtendedBlock<
	PetNameFormContentItem,
	{
		otherNames: ReturnType<typeof useNameGenerator>['otherNames']
		resetResults: ReturnType<typeof useNameGenerator>['resetResults']
		shuffle: ReturnType<typeof useNameGenerator>['shuffle']
		topNames: ReturnType<typeof useNameGenerator>['topNames']
		selectedTraitsByGroup: ReturnType<
			typeof useTraits
		>['selectedTraitsByGroup']
	}
> = ({
	block,
	otherNames,
	resetResults,
	selectedTraitsByGroup,
	shuffle,
	topNames,
	...context
}) => {
	const winnerName = topNames[0]
	const otherWinners = topNames.slice(1)

	function removeEmptyProperties(obj: TraitsByType): TraitsByType {
		return Object.fromEntries(
			Object.entries(obj).filter(([_, v]) => v !== null)
		)
	}

	const selectedTraitKeys = Object.keys(
		removeEmptyProperties(selectedTraitsByGroup)
	)

	const lastIndex = selectedTraitKeys.length - 1
	let nameDescription = ''
	selectedTraitKeys.reverse().forEach((group, index) => {
		const descriptor = selectedTraitsByGroup[group]?.elements.displayName
		if (!descriptor) {
			return
		}
		if (index === lastIndex || index === 0) {
			nameDescription += ` ${descriptor}`
		} else {
			nameDescription += `, ${descriptor}`
		}
	})
	nameDescription += '.'
	nameDescription = nameDescription.trim()

	return (
		<>
			<CardWrapper title={block.elements.topMatchesTitle}>
				<div
					className="mt-4 flex flex-col md:flex-row"
					style={{gap: '5rem'}}
				>
					{!winnerName && (
						<span className="text-red-500">
							{block.elements.noResultsErrorText}
						</span>
					)}

					{winnerName ? (
						<>
							<div className="relative text-center">
								{block.elements.topNameBackground[0] ? (
									<Image
										alt={
											block.elements.topNameBackground[0]
												.description ?? ''
										}
										height={240}
										loader={loadImageFromKontentAI}
										src={
											block.elements.topNameBackground[0]
												.url
										}
										width={320}
									/>
								) : null}

								{block.elements.topNameIcon[0] ? (
									<div className="absolute left-1/2 top-[-20%] h-20 w-20 -translate-x-1/2 transform">
										<Image
											alt={
												block.elements.topNameIcon[0]
													.description ?? ''
											}
											className="object-cover"
											height={80}
											loader={loadImageFromKontentAI}
											src={
												block.elements.topNameIcon[0]
													?.url
											}
											width={80}
										/>
									</div>
								) : null}

								<h3
									className="absolute left-[50%] top-[-10%] text-3xl font-bold text-white"
									style={{
										transform: 'translate(-50%, 0)',
									}}
								>
									1
								</h3>
								<div
									className="absolute left-[50%] top-[20%] w-full text-white"
									style={{
										transform: 'translate(-50%, 0)',
									}}
								>
									<h3
										className="mb-4 text-4xl font-bold"
										style={{wordBreak: 'break-all'}}
									>
										{winnerName}
									</h3>

									<span className="text-xl font-bold">
										{nameDescription}
									</span>
								</div>
							</div>
							<div>
								<h3 className="text-center md:text-left">
									{block.elements.otherTopNamesTitle}
								</h3>
								<ul className="mt-8 columns-2 text-center">
									{otherWinners.map((name, index) => (
										<div className="mb-6 flex" key={name}>
											<div
												className="text-bold flex items-center justify-center rounded-full bg-orange-500 text-lg text-white"
												style={{
													width: '1.5em',
													height: '1.5em',
													borderRadius: '50%',
													marginRight: '10px',
												}}
											>
												{index + 2}
											</div>
											<span className="text-lg">
												{name}
											</span>
										</div>
									))}
								</ul>
							</div>
						</>
					) : null}
				</div>
			</CardWrapper>
			<CardWrapper title={block.elements.selectedAttributesTitle}>
				{Object.keys(selectedTraitsByGroup).map((group) => {
					const groupBlock = selectedTraitsByGroup[group]

					if (!groupBlock) return null

					return (
						<PetTraitCardBlock
							block={groupBlock}
							isActive
							key={groupBlock.elements.displayName}
							// eslint-disable-next-line @typescript-eslint/no-empty-function -- intentional no-op
							onClick={() => {}}
							unselected
							{...context}
						/>
					)
				})}
			</CardWrapper>
			<div className="text-center">
				<div
					className="flex justify-center"
					style={{marginTop: 64, marginBottom: '2rem', gap: '1.5rem'}}
				>
					<Button
						data-kontent-element-codename="button_text"
						onClick={() => {
							pushToDataLayer({
								event: 'cta_click',
								cta_name: block.elements.shuffleButtonText,
								cta_category: 'CTA_Button',
							})
							shuffle()
						}}
						variant="inverted_pet_primary_green"
					>
						{block.elements.shuffleButtonText}
					</Button>

					<Button
						data-kontent-element-codename="button_text"
						onClick={() => {
							pushToDataLayer({
								event: 'cta_click',
								cta_name:
									block.elements.changeAttributesButtonText,
								cta_category: 'CTA_Button',
							})
							resetResults()
						}}
						variant="pet_primary_green"
					>
						{block.elements.changeAttributesButtonText}
					</Button>
				</div>
				<Link
					className="font-bold text-pet-green underline"
					href={block.elements.viewAllLink[0]?.elements.url ?? ''}
					passHref
				>
					{}

					{block.elements.viewAllLink[0]?.elements.text}
				</Link>
			</div>
			<div className="mt-8 w-full">
				<h1 className="text-center">
					{block.elements.otherNamesTitle}
				</h1>
				<div className="container-narrow my-10">
					<DisplayPetNames
						moreThanFortyNames={false}
						petResultsList={otherNames}
					/>
				</div>
			</div>
		</>
	)
}
