import type {QueryFunctionContext} from 'react-query'
import http from '@/utils/axios'
import type {
	ParasiteCasesQueryKey,
	TParasiteCaseResponse,
} from '@/_new-code/services/parasite-traker-cases/models'

export const useFetchParasiteCases = async (
	context: QueryFunctionContext<ParasiteCasesQueryKey>
): Promise<TParasiteCaseResponse> => {
	const [, params] = context.queryKey
	const {parasiteType, parasiteStartDate} = params
	const endpoint = `/api/parasite-tracker-cases?parasiteType=${parasiteType}&parasiteStartDate=${parasiteStartDate}`
	const {data} = await http<TParasiteCaseResponse>(endpoint)
	return data
}
