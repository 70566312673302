import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {Header} from '@/_new-code/products/flexible-web-toolkit/components/block-header'
import {HeaderImage} from '@/_new-code/products/flexible-web-toolkit/components/block-header/image'

export type HeaderBlockWithCopyContentItem = IContentItem<{
	heading: Elements.TextElement
	subheading: Elements.TextElement
	image: Elements.AssetsElement
}>

export const HeaderBlockWithCopyBlock: Block<
	HeaderBlockWithCopyContentItem
> = ({
	block: {
		elements: {
			heading,
			subheading,
			image: [image],
		},
	},
}) => {
	return (
		<Header noTheme>
			<h1 data-kontent-element-codename="heading">{heading}</h1>
			<p className="pt-6" data-kontent-element-codename="subheading">
				{subheading}
			</p>
			{image ? (
				<HeaderImage alt={image.description} src={image.url} />
			) : null}
		</Header>
	)
}
