import Link from 'next/link'
import {Button} from '@/_new-code/products/flexible-web-toolkit/components/button/button'
import type {ExtendedBlock, Tersed} from '@/_new-code/services/kontent-ai/types'
import type {PetNameFormContentItem} from '@/_new-code/products/pet-name-finder/pet-name-form'
import type {
	PetTraitContentItem,
	PetTraitGroupContentItem,
} from '@/_new-code/products/pet-name-finder/models'
import {PetTraitGroupCardBlock} from './pet-trait-group-card'
import type {TraitsByType} from './pet-trait-card'

export const NamesFormBlock: ExtendedBlock<
	PetNameFormContentItem,
	{
		activeTraitGroups: Tersed<PetTraitGroupContentItem>[]
		isButtonDisabled: boolean
		reset: () => void
		searchNames: () => Promise<void>
		selectedTraitsByGroup: TraitsByType
		toggleTrait: (
			traitName: Tersed<PetTraitContentItem>,
			groupTitle: string
		) => void
	}
> = ({
	block: {
		elements: {resetButtonText, submitButtonText, viewAllLink},
	},
	activeTraitGroups,
	selectedTraitsByGroup,
	toggleTrait,
	isButtonDisabled,
	reset,
	searchNames,
	...context
}) => {
	const traitGroupBlocks = activeTraitGroups.map((group) => (
		<PetTraitGroupCardBlock
			block={{
				elements: {
					authenticationGateComponentUserRoles: [],
				},
				system: {
					codename: '',
					id: '',
					type: '',
					name: '',
				},
			}}
			key={group.system.id}
			selectedTraitsByGroup={selectedTraitsByGroup}
			toggleTrait={toggleTrait}
			traitGroup={group}
			{...context}
		/>
	))

	return (
		<div className="flex w-full flex-col items-center justify-center">
			{traitGroupBlocks}
			<div
				className="flex"
				style={{marginTop: 64, marginBottom: '2rem', gap: '1.5rem'}}
			>
				<Button
					data-kontent-element-codename="button_text"
					onClick={() => {
						reset()
					}}
					variant="inverted_pet_primary_green"
				>
					{resetButtonText}
				</Button>

				<Button
					data-kontent-element-codename="button_text"
					disabled={isButtonDisabled}
					onClick={() => {
						void searchNames()
					}}
					variant="pet_primary_green"
				>
					{submitButtonText}
				</Button>
			</div>
			{viewAllLink[0] ? (
				<Link
					className="text-pet-green"
					href={viewAllLink[0].elements.url}
					passHref
					style={{
						fontWeight: 700,
						textDecoration: 'underline',
					}}
				>
					{}

					{viewAllLink[0].elements.text}
				</Link>
			) : null}
		</div>
	)
}
