import {Icon as CIcon} from '@elanco/component-library-v2'
import Image from 'next/image'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {clsx} from 'clsx'
import {defaultMargins} from '@/_new-code/products/flexible-web-toolkit/styles'
import {loadImageFromKontentAI} from '@/imageLoader'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import type {IconWithTextContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/icon-with-text'
import {StatsIcons} from '@/_new-code/products/flexible-web-toolkit/components/stats-icon'
import {customBrandChoices, customBrands} from '../helpers'
import {RichTextRenderer} from '../../components/rich-text-renderer'

export type LargeBenefitsBlockContentItem = IContentItem<{
	icons: Elements.LinkedItemsElement<IconWithTextContentItem>
	columns: Elements.MultipleChoiceElement
	borderOption: Elements.MultipleChoiceElement
}>

export const LargeBenefitsBlockBlock: Block<LargeBenefitsBlockContentItem> = ({
	block,
	...context
}) => {
	return (
		<div className={clsx('container-wide text-blue-900', defaultMargins)}>
			<StatsIcons
				className="px-2"
				col={Number(block.elements.columns[0]?.name ?? 2)}
			>
				{block.elements.icons.map(
					({
						elements: {
							icon,
							boldTitle,
							brand,
							description,
							iconSize,
							title,
						},
						system: {id},
					}) => (
						<div data-kontent-item-id={id} key={id}>
							<CIcon
								// "Unterse" multiple choice option for component library
								boldTitle={{
									boolean: boldTitle[0]?.codename === 'yes',
									choice: boldTitle[0]?.codename,
									choices: boldTitle.map(
										({codename}) => codename
									),
									raw: boldTitle,
								}}
								brand={
									customBrandChoices.includes(
										brand[0]?.codename ?? ''
									)
										? 'none'
										: brand[0]?.codename || 'none'
								}
								iconCustomClasses={
									customBrandChoices.includes(
										brand[0]?.codename ?? ''
									)
										? customBrands[brand[0]?.codename ?? '']
												?.border
										: ''
								}
								noBorder={
									!(
										block.elements.borderOption[0]
											?.codename === 'yes'
									)
								}
								size={iconSize[0]?.codename ?? 'lg'}
								title={
									<span data-kontent-element-codename="title">
										{title}
									</span>
								}
							>
								<div className="relative h-full w-full">
									{icon[0] ? (
										<Image
											alt={icon[0].description ?? ''}
											data-kontent-element-codename="icon"
											fill
											loader={loadImageFromKontentAI}
											src={icon[0].url}
										/>
									) : null}
								</div>
							</CIcon>
							<RichTextRenderer
								element={description}
								{...context}
								data-kontent-element-codename="description"
							/>
						</div>
					)
				)}
			</StatsIcons>
		</div>
	)
}
