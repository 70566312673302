import {cloneElement, type JSX} from 'react'

interface LinkListProps {
	children: JSX.Element[] | null
	className?: string
	horizontal?: boolean
	isMobile?: boolean
	title?: React.ReactElement<{className?: string}> | null
}

export const LinkList = ({
	children = null,
	className = '',
	horizontal = false,
	isMobile = false,
	title = null,
}: LinkListProps): JSX.Element => {
	return (
		<div className={className}>
			{title
				? cloneElement(title, {
						className: `${title.props.className || ''} subhead`,
					})
				: null}
			<ul
				className={`text-sm  ${title && 'mt-3'} ${
					horizontal ? 'md:flex' : ''
				} ${isMobile && 'grid grid-cols-2'}`}
				style={{gap: '6px'}} // Tailwind classes doesn't work (ie: gap-2 gap-y-2 ...)
			>
				{children?.map((link, index) => (
					<li
						className={horizontal ? 'mr-6' : 'mb-2'}
						key={link.key ?? index}
					>
						{link}
					</li>
				))}
			</ul>
		</div>
	)
}
