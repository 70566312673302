import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {clsx} from 'clsx'
import Link from 'next/link'
import {useState, memo} from 'react'
import {useRouter} from 'next/router'
import {addLocalePrefix} from '@/_new-code/utilities/add-locale-prefix'
import {EllipseButton} from '@/_new-code/products/flexible-web-toolkit/components/button/ellipse-button'
import {ButtonIcons} from '@/_new-code/components/button-icons/button-icon'
import type {
	BlockWithClassName,
	ExtendedBlock,
} from '@/_new-code/services/kontent-ai/types'
import {RichTextRenderer} from '@/_new-code/products/flexible-web-toolkit/components/rich-text-renderer'
import {backgroundThemes} from '@/_new-code/products/flexible-web-toolkit/colors/themes'

export type NotificationBannerContentItem = IContentItem<{
	content: Elements.RichTextElement
	snippetLayoutBackgroundColorColor: Elements.MultipleChoiceElement
	icon: Elements.MultipleChoiceElement
	position: Elements.MultipleChoiceElement
	bannerRedirect: Elements.TextElement
	openNewTab: Elements.MultipleChoiceElement
	canClose: Elements.MultipleChoiceElement
}>

// eslint-disable-next-line react/display-name -- Display names are not needed for internal memoized components
const NotificationBanner = memo<
	BlockWithClassName<NotificationBannerContentItem>
>(
	({
		block: {
			elements: {
				content,
				icon: [icon],
				snippetLayoutBackgroundColorColor,
				bannerRedirect,
				openNewTab,
				canClose,
			},
			system: {id},
		},
		className,
		...context
	}) => {
		const [visible, setVisible] = useState(true)
		const iconCodename = icon?.name
		const bgColor = snippetLayoutBackgroundColorColor[0]?.codename ?? ''
		const backgroundClassname = backgroundThemes.get(bgColor)
		const {locale} = useRouter()

		const freshTab =
			openNewTab[0]?.codename === 'yes'
				? {target: '_blank', rel: 'noopener noreferrer'}
				: {}

		const style = clsx(
			className,
			visible ? null : 'hidden',
			backgroundClassname,
			'flex items-center p-4 pr-2 md:pr-4'
		)

		const inner = (
			<>
				<div
					className={clsx(iconCodename ? 'mr-8 md:mr-4' : null)}
					data-kontent-element-codename="icon"
				>
					{iconCodename
						? ButtonIcons[iconCodename as keyof typeof ButtonIcons]
								.icon
						: null}
				</div>
				<RichTextRenderer
					className="rich-content content--remove-bottom-margin mx-0 flex-grow break-words text-sm md:mx-4"
					data-kontent-element-codename="content"
					element={content}
					{...context}
				/>
				{canClose[0]?.codename !== 'no' ? (
					<EllipseButton
						as="button"
						className="place-self-start p-4 md:place-self-center"
						icon={ButtonIcons.Close}
						onClick={(event) => {
							event.preventDefault()
							setVisible(false)
						}}
						size="sm"
						type="button"
					/>
				) : null}
			</>
		)

		if (bannerRedirect) {
			return (
				<Link
					className={style}
					data-kontent-item-id={id}
					href={addLocalePrefix(bannerRedirect, locale ?? '')}
					{...freshTab}
					key={id}
				>
					{inner}
				</Link>
			)
		}
		return (
			<div className={style} data-kontent-item-id={id} key={id}>
				{inner}
			</div>
		)
	}
)

type BannerPosition = 'top' | 'bottom'

// eslint-disable-next-line react/display-name -- Display names are not needed for internal memoized components
export const NotificationBannerBlock = memo<
	ExtendedBlock<
		IContentItem,
		{
			className?: string
			/** Filter notification banners to only be the ones that should appear at a given position. */
			position: BannerPosition
		}
	>
>(({globalConfig, page, className, position, ...context}) => {
	const notificationBanners = [
		...globalConfig.elements.notificationBanners,
		...page.elements.snippetPageSeoNotificationBanners,
	]

	const filteredBanners = notificationBanners.filter((banner) => {
		const bannerPosition = (banner.elements.position[0]?.codename ??
			'top') as BannerPosition
		return bannerPosition === position
	})

	return (
		<>
			{filteredBanners.map((banner) => (
				<NotificationBanner
					{...context}
					block={banner}
					className={className}
					globalConfig={globalConfig}
					key={banner.system.id}
					page={page}
				/>
			))}
		</>
	)
})
