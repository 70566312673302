import type {ReactElement, PropsWithChildren} from 'react'
import React from 'react'

interface HeaderCardProps extends Required<PropsWithChildren> {
	title: string | ReactElement
	className?: string
	backgroundColor?: string
}

export const HeaderCard = ({
	title = '',
	children = null,
	className = '',
	backgroundColor = 'bg-white',
}: HeaderCardProps): ReactElement => {
	return (
		<div
			className={`p-6 md:rounded-lg md:p-8 ${className} ${backgroundColor}`}
		>
			<h1>{title}</h1>
			{children}
		</div>
	)
}
