import type {QueryFunctionContext} from 'react-query'
import type {
	ListerItemsOutput,
	ListerItemsSuccessOutput,
} from '@/pages/api/v2/lister-items'
import http from '@/utils/axios'
import type {Tersed} from '@/_new-code/services/kontent-ai/types'
import type {
	ListerItemsError,
	ListerItemsQueryKey,
} from '@/_new-code/products/flexible-web-toolkit/blocks/module-coupon-lister/types'

export async function fetchListerItems<T extends Tersed>({
	queryKey,
	pageParam,
}: QueryFunctionContext<ListerItemsQueryKey>): Promise<
	ListerItemsSuccessOutput<T>
> {
	const [, params] = queryKey
	const {
		codename,
		locale,
		excludeId,
		filters,
		hideElementCodename,
		limit,
		orderBy,
		skip,
	} = params

	const searchParams = new URLSearchParams({
		codename,
		locale,
	})

	if (excludeId) {
		searchParams.set('excludeId', excludeId)
	}

	if (filters) {
		searchParams.set('filters', filters)
	}

	if (hideElementCodename) {
		searchParams.set('hideElementCodename', hideElementCodename)
	}

	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- null check
	if (limit !== null) {
		searchParams.set('limit', String(limit))
	}
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- null check
	if (skip !== null) {
		if (pageParam !== undefined && !Number.isNaN(pageParam) && limit) {
			const toFetch = (skip + (pageParam + 1)) * limit
			searchParams.set('skip', String(toFetch))
		} else {
			searchParams.set('skip', String(skip))
		}
	}

	if (orderBy) {
		searchParams.set('orderBy', orderBy)
	}

	const {data} = await http<ListerItemsOutput<T>>(
		`/api/v2/lister-items?${searchParams.toString()}`
	)

	if (!data.success) {
		// eslint-disable-next-line @typescript-eslint/no-throw-literal -- np other way
		throw new Error(data.message) as ListerItemsError
	}

	return data
}
