import type {
	SpeciesBreedResponse,
	SpeciesBreed,
} from '@/_new-code/models/consumer-engagement-models'
import http from '@/utils/axios'

export const fetchPetBreeds = async (
	species: string,
	locale: string
): Promise<SpeciesBreed[]> => {
	const endpoint = `/api/species/${species}/breeds?locale=${locale}`
	const {data: response} = await http<SpeciesBreedResponse>(endpoint)

	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- optional is needed
	return response?.data
}
