import {clsx} from 'clsx'
import type {JSX} from 'react'
import {ButtonIcons} from '@/_new-code/components/button-icons/button-icon'
import {Button} from '@/_new-code/products/flexible-web-toolkit/components/button/button'
import {pushToDataLayer} from '@/utils/analytics'
import type {FilterBarChildProps} from './index'

export const DesktopView = (props: FilterBarChildProps): JSX.Element => {
	const {
		globalState,
		filterBarKey,
		filterBarOptions,
		renderFilterOptions,
		toggleAdditionalFilters,
		checkShowAdditionalFilters,
		additionalFilterTitle,
		resetButtonTitle,
		resetAllFilters,
		className,
	} = props

	return (
		<div className={className}>
			<div
				className={clsx(
					'mx-auto flex max-w-screen-xl',
					filterBarKey === 'productsFilterOptions'
						? 'mb-8 flex-row justify-between px-3'
						: 'flex-col items-center justify-center'
				)}
			>
				<div
					className={clsx(
						filterBarKey === 'productsFilterOptions'
							? 'w-11/12 pr-12'
							: 'w-full',
						filterBarOptions.length < 3 && 'w-9/12'
					)}
				>
					<div
						className={clsx(
							'gap-y-3',
							filterBarKey === 'productsFilterOptions'
								? 'flex flex-wrap gap-x-12 '
								: 'grid grid-cols-2'
						)}
					>
						{globalState?.[filterBarKey]
							? filterBarOptions.map(renderFilterOptions)
							: null}
					</div>
				</div>
				<div
					className={
						filterBarKey === 'productsFilterOptions'
							? 'py-5'
							: 'pt-8'
					}
				>
					<div className="flex items-center justify-center">
						{checkShowAdditionalFilters ? (
							<Button
								as="button"
								className="mr-2 whitespace-nowrap bg-white px-7 py-2"
								onClick={toggleAdditionalFilters}
								title={additionalFilterTitle}
								variant="inverted"
							>
								{additionalFilterTitle}
							</Button>
						) : null}
						<Button
							as="button"
							className="truncate bg-white py-2"
							icon={ButtonIcons.Load}
							onClick={() => {
								resetAllFilters()
								pushToDataLayer({
									event: 'filter_clear',
									filter_name: 'reset',
								})
							}}
							title={resetButtonTitle}
							variant="inverted"
						>
							{resetButtonTitle}
						</Button>
					</div>
				</div>
			</div>
		</div>
	)
}
