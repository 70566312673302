import FadeLoader from 'react-spinners/FadeLoader'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {Button as CButton} from '@/_new-code/products/flexible-web-toolkit/components/button/button'
import type {PetTraitContentItem} from '@/_new-code/products/pet-name-finder/models'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import type {CtaButtonContentItem} from '../../flexible-web-toolkit/blocks/cta-button'
import {PetTraitCardBlock} from '../pet-name-form/pet-trait-card'
import {DisplayPetNames} from './pet-names-list'
import {useNameFilter} from './hooks'

export type PetViewAllNamesContentItem = IContentItem<{
	speciesText: Elements.TextElement
	dogTrait: Elements.LinkedItemsElement<PetTraitContentItem>
	catTrait: Elements.LinkedItemsElement<PetTraitContentItem>
	bothTrait: Elements.LinkedItemsElement<PetTraitContentItem>
	loadMoreText: Elements.TextElement
	petNameGenerator: Elements.LinkedItemsElement<CtaButtonContentItem>
}>

export const PetViewAllNamesBlock: Block<PetViewAllNamesContentItem> = ({
	block,
	...context
}) => {
	const {
		names,
		filterPetNames,
		filterBySpecie,
		loadMoreNames,
		loading,
		loadMore,
		showLoadMoreText,
		selected,
		displayAllNames,
	} = useNameFilter(block.elements.speciesText)

	return (
		<div
			className="mt-8"
			data-kontent-element-codename="pet_view_all_names"
		>
			<div className="mx-6 flex justify-center gap-4">
				{block.elements.bothTrait[0] ? (
					<PetTraitCardBlock
						block={block.elements.bothTrait[0]}
						isActive={
							!selected ||
							selected ===
								block.elements.bothTrait[0].elements.displayName
						}
						onClick={(trait) => {
							loadMoreNames(trait.elements.displayName)
						}}
						unselected={false}
						{...context}
					/>
				) : null}
				{block.elements.dogTrait[0] ? (
					<PetTraitCardBlock
						block={block.elements.dogTrait[0]}
						isActive={
							selected ===
							block.elements.dogTrait[0].elements.displayName
						}
						onClick={(trait) => {
							filterBySpecie(trait.elements.displayName)
						}}
						unselected={false}
						{...context}
					/>
				) : null}
				{block.elements.catTrait[0] ? (
					<PetTraitCardBlock
						block={block.elements.catTrait[0]}
						isActive={
							selected ===
							block.elements.catTrait[0].elements.displayName
						}
						onClick={(trait) => {
							filterBySpecie(trait.elements.displayName)
						}}
						unselected={false}
						{...context}
					/>
				) : null}
			</div>
			{/* Filter By Letter */}
			<div className="mb-4 mt-4">
				<hr />
				<div className="flex justify-center pb-2 pt-2 font-bold">
					<button
						className="mr-6 cursor-pointer"
						onClick={() => {
							filterPetNames('A', 'D')
						}}
						type="button"
					>
						<h4>A-D</h4>
					</button>
					<button
						className="mr-6 cursor-pointer"
						onClick={() => {
							filterPetNames('E', 'H')
						}}
						type="button"
					>
						<h4>E-H</h4>
					</button>
					<button
						className="mr-6 cursor-pointer"
						onClick={() => {
							filterPetNames('I', 'L')
						}}
						type="button"
					>
						<h4>I-L</h4>
					</button>
					<button
						className="mr-6 cursor-pointer"
						onClick={() => {
							filterPetNames('M', 'P')
						}}
						type="button"
					>
						<h4>M-P</h4>
					</button>
					<button
						className="mr-6 cursor-pointer"
						onClick={() => {
							filterPetNames('Q', 'T')
						}}
						type="button"
					>
						<h4>Q-T</h4>
					</button>
					<button
						className="mr-6 cursor-pointer"
						onClick={() => {
							filterPetNames('U', 'Z')
						}}
						type="button"
					>
						<h4>U-Z</h4>
					</button>
				</div>
				<hr />
			</div>
			{loading ? (
				<div className="flex w-full justify-center py-6">
					<FadeLoader
						color="#02253e"
						height={15}
						loading
						margin={2}
						radius={2}
						width={5}
					/>
				</div>
			) : null}
			{/* Display filtered pet names */}
			<div>
				{!loading && (
					<>
						<div className="container-narrow mx-auto my-10">
							<DisplayPetNames
								loadMore={loadMore}
								moreThanFortyNames={names.length > 40}
								petResultsList={names}
							/>
						</div>
						<div className="mt-8">
							{names.length > 40 && showLoadMoreText ? (
								<div className="flex cursor-pointer justify-center">
									<button
										className="font-bold text-pet-green"
										onClick={() => {
											displayAllNames()
										}}
										type="button"
									>
										{block.elements.loadMoreText}
									</button>
								</div>
							) : null}
						</div>
					</>
				)}
				<div className="mb-10 mt-10 flex justify-center">
					{block.elements.petNameGenerator[0] ? (
						<CButton
							className="font-bold text-white"
							href={
								block.elements.petNameGenerator[0].elements.url
							}
							variant="pet_primary_green"
						>
							{block.elements.petNameGenerator[0].elements.text}
						</CButton>
					) : null}
				</div>
			</div>
		</div>
	)
}
