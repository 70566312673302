import type {
	BrandConfigContentItem,
	Tersed,
	PageConfigContentItem,
} from '@/_new-code/services/kontent-ai/types'
import type {ContainerClassName} from '@/_new-code/products/flexible-web-toolkit/layout/layout-common-types'

export function getBrandColor(
	config: Tersed<PageConfigContentItem>
): string | null {
	if (!('color' in config.elements)) return null

	const brandConfig = config as Tersed<BrandConfigContentItem>

	return brandConfig.elements.color[0]?.codename ?? null
}

export const getContainerClass = (
	container: string,
	defaultContainer: ContainerClassName = 'container-wide'
): ContainerClassName => {
	switch (container) {
		case 'page': {
			return 'w-full'
		}
		case 'container': {
			return 'container'
		}
		case 'wide': {
			return 'container-wide'
		}
		case 'narrow': {
			return 'container-narrow'
		}
		default: {
			return defaultContainer
		}
	}
}
interface CustomBrand {
	background: string
	border: string
}
// eslint-disable-next-line @typescript-eslint/consistent-indexed-object-style -- preferring index signature than a record
interface CustomBrandObject {
	[k: string]: CustomBrand
}

// Verbose object, creating these dynamically would be purged by Tailwind
/* Brands which have colors that are not a part of the Component Library's original set */

export const customBrands: CustomBrandObject = {
	elura: {
		background: 'bg-elura',
		border: 'border-elura',
	},
	entyce: {
		background: 'bg-entyce',
		border: 'border-entyce',
	},
	comfortis: {
		background: 'bg-comfortis',
		border: 'border-comfortis',
	},
	advocate: {
		background: 'bg-advocate',
		border: 'border-advocate',
	},
	experior_green: {
		background: 'bg-experior_green',
		border: 'border-experior_green',
	},
	zenrelia_primary: {
		background: 'bg-zenrelia_primary',
		border: 'border-zenrelia_primary',
	},
	zenrelia_secondary: {
		background: 'bg-zenrelia_secondary',
		border: 'border-zenrelia_secondary',
	},
	zenrelia_contrast: {
		background: 'bg-zenrelia_contrast',
		border: 'border-zenrelia_contrast',
	},
	zenrelia_accent: {
		background: 'bg-zenrelia_accent',
		border: 'border-zenrelia_accent',
	},
	zenrelia_sku1: {
		background: 'bg-zenrelia_sku1',
		border: 'border-zenrelia_sku1',
	},
	zenrelia_sku2: {
		background: 'bg-zenrelia_sku2',
		border: 'border-zenrelia_sku2',
	},
	zenrelia_sku3: {
		background: 'bg-zenrelia_sku3',
		border: 'border-zenrelia_sku3',
	},
	zenrelia_sku4: {
		background: 'bg-zenrelia_sku4',
		border: 'border-zenrelia_sku4',
	},
	credelio_quattro_guard_dog: {
		background: 'bg-credelio_quattro_guard_dog',
		border: 'border-credelio_quattro_guard_dog',
	},
	credelio_quattro_hunter_green: {
		background: 'bg-credelio_quattro_hunter_green',
		border: 'border-credelio_quattro_hunter_green',
	},
	credelio_quattro_grass_green: {
		background: 'bg-credelio_quattro_grass_green',
		border: 'border-credelio_quattro_grass_green',
	},
	credelio_quattro_tennis_ball_green: {
		background: 'bg-credelio_quattro_tennis_ball_green',
		border: 'border-credelio_quattro_tennis_ball_green',
	},
	credelio_quattro_park_green: {
		background: 'bg-credelio_quattro_park_green',
		border: 'border-credelio_quattro_park_green',
	},
	credelio_quattro_milk_bone: {
		background: 'bg-credelio_quattro_milk_bone',
		border: 'border-credelio_quattro_milk_bone',
	},

	credelio_quattro_golden: {
		background: 'bg-credelio_quattro_golden',
		border: 'border-credelio_quattro_golden',
	},
	credelio_quattro_frisbee: {
		background: 'bg-credelio_quattro_frisbee',
		border: 'border-credelio_quattro_frisbee',
	},
	credelio_quattro_sunny_day: {
		background: 'bg-credelio_quattro_sunny_day',
		border: 'border-credelio_quattro_sunny_day',
	},
	credelio_quattro_sky_blue: {
		background: 'bg-credelio_quattro_sky_blue',
		border: 'border-credelio_quattro_sky_blue',
	},
}

export const customBrandChoices: string[] = Object.keys(customBrands)
