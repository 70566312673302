import type {JSX} from 'react'
import {MultiSelectCheckboxes} from '@/_new-code/products/campaign-forms/cdc-screen-set-wrapper/campaign-questions/multi-select-checkboxes'
import {RENDER_MODE_CHECKBOX} from '@/_new-code/products/campaign-forms/cdc-screen-set-wrapper/model-as-code/multiple-selection-question.mac'
import type {
	SharedQuestionProps,
	MultipleSelectionContentItem,
} from '@/_new-code/products/campaign-forms/cdc-screen-set-wrapper/models'
import type {Tersed} from '@/_new-code/services/kontent-ai/types'

interface MultiSelectQuestionProps extends SharedQuestionProps {
	question: Tersed<MultipleSelectionContentItem>
}

export const MultiSelectQuestion = ({
	question,
	selectedOptionsCallback,
	injectedElementsCallback,
	errorsCallback,
	validationEvent,
}: MultiSelectQuestionProps): JSX.Element => {
	if (question.elements.selections.length === 0) {
		return <div />
	}

	if (question.elements.renderMode[0]?.codename === RENDER_MODE_CHECKBOX) {
		return (
			<MultiSelectCheckboxes
				errorsCallback={errorsCallback}
				injectedElementsCallback={injectedElementsCallback}
				question={question}
				selectedOptionsCallback={selectedOptionsCallback}
				validationEvent={validationEvent}
			/>
		)
	}

	return <div />
}
