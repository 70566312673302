import axios from 'axios'
import * as httpModule from 'http'
import * as httpsModule from 'https'

const httpAgent = new httpModule.Agent({
	keepAlive: true,
	maxSockets: 100,
	maxFreeSockets: 10,
	timeout: 60000,
})

const httpsAgent = new httpsModule.Agent({
	keepAlive: true,
	maxSockets: 100,
	maxFreeSockets: 10,
	timeout: 60000,
})

const http = axios.create({
	timeout: 10000,
	headers: {'Content-Type': 'application/json'},
	httpAgent: httpAgent,
	httpsAgent: httpsAgent,
})

export default http
