import {useEffect, useState, type JSX} from 'react'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import type {Block} from '@/_new-code/services/kontent-ai/types'

export type CdcGigyaPopupContentItem = IContentItem<{
	screensetId: Elements.TextElement
	screenId: Elements.TextElement
	popupDelay: Elements.NumberElement
	popupSuppress: Elements.NumberElement
}>

export const CdcGigyaPopupBlock: Block<CdcGigyaPopupContentItem> = ({
	block,
}): JSX.Element => {
	// don't change the key on re-render
	const [loaded, setLoaded] = useState<boolean>(false)
	const setGigyaLoaded = (): void => {
		setLoaded(true)
	}

	function setCookie(name: string, val: string, days: number): void {
		const date = new Date()
		const value = val

		// Set it expire in 7 days
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)

		// Set it
		document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/`
	}

	function getCookie(name: string): string | undefined {
		const value = `; ${document.cookie}`
		const parts = value.split(`; ${name}=`)

		if (parts.length === 2) {
			return parts.pop()?.split(';').shift()
		}
		return undefined
	}

	useEffect(() => {
		// needed as we need to call onLoad, which we can't do in a <script>
		function loadScript(): void {
			const statusIndicator = document.getElementById('CDC-status')
			if (statusIndicator) {
				if ((statusIndicator as HTMLInputElement).value === 'Ready') {
					setGigyaLoaded()
				} else {
					const observer = new MutationObserver(() => {
						setGigyaLoaded()
					})
					observer.observe(statusIndicator, {attributes: true})
				}
			}
		}
		if (typeof window === 'undefined' || !loaded) {
			// loadScript sets loaded
			// runs on window, once
			loadScript()
			return
		}
		const cookieName = `CDCPopup_${block.system.codename}`
		const cookie = getCookie(cookieName)
		if (!cookie) {
			setTimeout(
				() => {
					setCookie(
						cookieName,
						'true',
						block.elements.popupSuppress ?? 0
					)
					// @ts-expect-error gigya is loaded by an external script
					gigya.accounts.showScreenSet({
						screenSet: block.elements.screensetId,
						startScreen: block.elements.screenId,
						// not including a containerID makes the screenset a popup
						// containerID: block.cdc_key,
					})
				},
				block.elements.popupDelay ?? 0 * 1000
			)
		}
	}, [
		block.elements.popupDelay,
		block.elements.popupSuppress,
		block.elements.screenId,
		block.elements.screensetId,
		block.system.codename,
		loaded,
	])

	return (
		<div
			data-kontent-element-codename="cdc_gigya"
			id={block.system.codename}
		/>
	)
}
