import type {Dispatch, SetStateAction} from 'react'
import {ParasiteSidepanel as Sidebar} from '@elanco/component-library-v2'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import type {LinkContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/link'
import type {FormSelectorContentItem} from '@/_new-code/products/disease-map/form-selector'
import {FormSelectorBlock} from '@/_new-code/products/disease-map/form-selector'
import type {CtaButtonContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/cta-button'
import type {ExtendedBlock} from '@/_new-code/services/kontent-ai/types'
import {Tabs as FormTabs} from '@/_new-code/products/flexible-web-toolkit/components/tabs'
import {Tab as FormTab} from '@/_new-code/products/flexible-web-toolkit/components/tabs/tab'

type ParasiteTrackerSidebarContentItem = IContentItem<{
	title: Elements.TextElement
	modalCta: Elements.LinkedItemsElement<CtaButtonContentItem>
	themeHexcode: Elements.TextElement
	secondaryCta: Elements.LinkedItemsElement<CtaButtonContentItem>
	textLink: Elements.LinkedItemsElement<LinkContentItem>
	reportedText1: Elements.TextElement
	reportedText2: Elements.TextElement
	defaultScoreText: Elements.TextElement
	noScoreText: Elements.TextElement
	lowScoreText: Elements.TextElement
	mediumScoreText: Elements.TextElement
	highScoreText: Elements.TextElement
	defaultSummaryText: Elements.RichTextElement
	noneSummaryText: Elements.RichTextElement
	lowSummaryText: Elements.RichTextElement
	mediumSummaryText: Elements.RichTextElement
	highSummaryText: Elements.RichTextElement
	noCasesSummaryText: Elements.RichTextElement
	casesSummaryText: Elements.RichTextElement
	submissionForm: Elements.LinkedItemsElement<FormSelectorContentItem>
	formTitle: Elements.TextElement
	formSubtitle: Elements.TextElement
	showDataAs: Elements.MultipleChoiceElement
}>

export const ParasiteTrackerSidebar: ExtendedBlock<
	ParasiteTrackerSidebarContentItem,
	{
		showSummaryText?: boolean
		setShowSummaryText: Dispatch<SetStateAction<boolean | undefined>>
		reportedCases: string
		setReportedCases: Dispatch<SetStateAction<string>>
		locationName: string
		setLocationName: Dispatch<SetStateAction<string>>
		radius: number | null
	}
> = ({
	block,
	radius,
	locationName,
	reportedCases,
	showSummaryText,
	...context
}) => {
	const getScoreText = (numOfCases: number): string => {
		if (numOfCases >= 1 && numOfCases < 5)
			return block.elements.lowScoreText
		if (numOfCases >= 5 && numOfCases < 9)
			return block.elements.mediumScoreText
		if (numOfCases >= 9) return block.elements.highScoreText
		return block.elements.noScoreText
	}

	function getSummaryText(numberOfCases: number): Elements.RichTextElement {
		if (numberOfCases === 0) return block.elements.noneSummaryText
		if (numberOfCases < 10) return block.elements.lowSummaryText
		if (numberOfCases < 25) return block.elements.mediumSummaryText
		return block.elements.highSummaryText
	}

	function addLocationName(input: string): string {
		return input.replaceAll('{{location}}', locationName)
	}

	function addRadius(input: string): string {
		return input.replaceAll('{{radius}}', radius ? radius.toString() : '')
	}

	return (
		<div>
			<Sidebar
				modalCta={
					// Hack as component library expects a different form of CTA Button
					block.elements.modalCta[0]
						? {
								variant: {
									choice: block.elements.modalCta[0].elements
										.variant[0]?.codename,
								},
								text: block.elements.modalCta[0].elements.text,
							}
						: null
				}
				reportedCases={
					block.elements.showDataAs[0]?.codename === 'score_text'
						? getScoreText(Number(reportedCases))
						: reportedCases
				}
				reportedTextAbove={addLocationName(
					block.elements.reportedText1
				)}
				reportedTextBelow={addRadius(
					addLocationName(block.elements.reportedText2)
				)}
				secondaryCta={
					// Hack as component library expects a different form of CTA Button
					block.elements.secondaryCta[0]
						? {
								url: block.elements.secondaryCta[0].elements
									.url,
								openLinkInNewTab: {
									boolean:
										block.elements.secondaryCta[0].elements
											.openLinkInNewTab[0]?.codename ===
										'yes',
								},
								variant:
									block.elements.secondaryCta[0].elements
										.variant[0]?.codename,
								text: block.elements.secondaryCta[0].elements
									.text,
							}
						: null
				}
				summaryText={addLocationName(
					showSummaryText
						? getSummaryText(Number(reportedCases)).value
						: block.elements.defaultSummaryText.value
				)}
				textLink={
					// Hack as component library expects a different form of Link
					block.elements.textLink[0]
						? {
								url: block.elements.textLink[0].elements.url,
								openInNewTab:
									block.elements.textLink[0].elements
										.openInNewTab[0]?.codename === 'yes',
								text: block.elements.textLink[0].elements.text,
							}
						: null
				}
				theme={block.elements.themeHexcode || '#2772ce'}
				title={block.elements.title}
			>
				<>
					<h2
						className="mb-5 text-center text-2xl"
						data-kontent-element-codename="form_title"
					>
						{block.elements.formTitle}
					</h2>
					<p
						className="mb-5 text-center text-sm italic"
						data-kontent-element-codename="form_subtitle"
					>
						{block.elements.formSubtitle}
					</p>
					{/* eslint-disable-next-line no-nested-ternary -- this is fine */}
					{block.elements.submissionForm[0] ? (
						block.elements.submissionForm.length > 1 ? (
							<FormTabs
								className="rjsf-form-tabs"
								name="ParasiteTrackerSidebar"
								tabClasses="rjsf-form-tab flex-grow flex-1"
							>
								{block.elements.submissionForm.map((form) => (
									<FormTab
										hideCloseBtn
										key={form.system.id}
										title={form.elements.tabTitle}
									>
										<FormSelectorBlock
											block={form}
											{...context}
										/>
									</FormTab>
								))}
							</FormTabs>
						) : (
							<FormSelectorBlock
								block={block.elements.submissionForm[0]}
								{...context}
							/>
						)
					) : null}
				</>
			</Sidebar>
		</div>
	)
}
