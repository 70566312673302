import type {JSX} from 'react'
import {EllipseButton} from '@/_new-code/products/flexible-web-toolkit/components/button/ellipse-button'
import {ButtonIcons} from '@/_new-code/components/button-icons/button-icon'

interface ShowSearchButtonProps {
	showSearch: boolean
	toggleSearchPopup: () => void
	searchAccessibilityText: string
}

export const ShowSearchButton = ({
	showSearch,
	toggleSearchPopup,
	searchAccessibilityText,
}: ShowSearchButtonProps): JSX.Element => (
	<>
		{showSearch ? (
			<EllipseButton
				as="button"
				className="ml-4 border border-white"
				icon={ButtonIcons.Search}
				onClick={toggleSearchPopup}
				size="sm"
				title={searchAccessibilityText}
				variant="primary"
			/>
		) : undefined}
	</>
)
