import {useEffect} from 'react'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {clsx} from 'clsx'
import {Button} from '@/_new-code/products/flexible-web-toolkit/components/button/button'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {defaultMargins} from '@/_new-code/products/flexible-web-toolkit/styles'
import {pushToDataLayer} from '@/utils/analytics'
import {SmallPromotionalModule} from '@/_new-code/products/flexible-web-toolkit/components/small-promotional-module'

export type PromoModuleContentItem = IContentItem<{
	titleText: Elements.TextElement
	horizontalAlignment: Elements.MultipleChoiceElement
	headerText: Elements.TextElement
	buttonsLink: Elements.TextElement
	termsConditions: Elements.TextElement
	termsLink: Elements.TextElement
	endDate: Elements.TextElement
}>

export const PromoModuleBlock: Block<PromoModuleContentItem> = ({block}) => {
	// TODO: This is a really bad idea for a whole load of reasons - we should probably do something about it
	useEffect(() => {
		const container = document.querySelector('.container-narrow')
		if (container) {
			container.classList.remove('container-narrow')
		}
	}, [])

	let position = ''
	switch (block.elements.horizontalAlignment[0]?.codename) {
		case 'left':
			position = 'justify-start'
			break
		case 'center':
			position = 'justify-center'
			break
		case 'right':
			position = 'justify-end'
			break
		case 'full_width':
			position = ''
			break
		default:
			throw new RangeError(
				'horizontal_alignment received an invalid option'
			)
	}

	return (
		<div
			className={clsx(
				`container-wide flex ${position} ${defaultMargins}`
			)}
		>
			<SmallPromotionalModule
				className={position === '' ? 'w-full' : 'w-4/5'}
				endDate={block.elements.endDate}
				promoButton={
					<Button
						href={block.elements.buttonsLink}
						onClick={() => {
							pushToDataLayer({
								event: 'cta_click',
								cta_name: 'Save and buy now',
								cta_category: 'CTA_Button',
							})
						}}
					>
						Save and buy now
					</Button>
				}
				termsLink={block.elements.termsLink}
				termsText={block.elements.termsConditions}
				text={block.elements.headerText}
				title={block.elements.titleText}
			/>
		</div>
	)
}
