import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {HeaderCard} from '@/_new-code/products/flexible-web-toolkit/components/header-card'
import {useTrustArcCookieValue} from '@/utils/analytics'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {FullscreenVideo} from '@/_new-code/products/flexible-web-toolkit/components/full-screen-video'

export type YoutubeVideoHeaderBlockContentItem = IContentItem<{
	title: Elements.TextElement
	description: Elements.TextElement
	videoId: Elements.TextElement
	thumbnailUrl: Elements.AssetsElement
	thumbnailAltText: Elements.TextElement
	backgroundColor: Elements.TextElement
}>

export const YoutubeVideoHeaderBlockBlock: Block<
	YoutubeVideoHeaderBlockContentItem
> = ({block}) => {
	const trustArcCookie = useTrustArcCookieValue()
	const hideVideo =
		trustArcCookie === '1:' || trustArcCookie === '0:' ? 'hide' : ''

	return (
		<div>
			<FullscreenVideo
				backgroundColor={block.elements.backgroundColor}
				posterFrameImageAltText={block.elements.thumbnailAltText}
				posterFrameImageUrl={block.elements.thumbnailUrl[0]?.url ?? ''}
				thumbnailImage={hideVideo !== 'hide'}
				videoId={hideVideo === 'hide' ? '' : block.elements.videoId}
			>
				<HeaderCard
					backgroundColor="bg-transparent"
					className="mt-6 md:mt-0"
					title={block.elements.title}
				>
					<p
						className="mt-5"
						data-kontent-element-codename="description"
					>
						{block.elements.description}
					</p>
				</HeaderCard>
			</FullscreenVideo>
		</div>
	)
}
