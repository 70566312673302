import Slider from 'react-slick'
import type {ReactElement, JSX} from 'react'
import {useState, useEffect, cloneElement, useRef} from 'react'
import {clsx} from 'clsx'
import {pushToDataLayer} from '@/utils/analytics'
import {useWindowSize} from '@/_new-code/utilities/hooks/use-window-size'

interface CarouselChildProps {
	title?: string
	className?: string
}

interface CarouselCTABlockProps {
	title: string
	ofLabel?: string
	children: ReactElement<CarouselChildProps>[]
	subtitle: string
	className?: string
	sliderNavText?: string
}

export const CarouselCTABlock = ({
	title = '',
	ofLabel = 'of',
	children,
	subtitle = '',
	className = '',
	sliderNavText = 'slider nav',
}: CarouselCTABlockProps): JSX.Element => {
	const [slideIndex, setSlideIndex] = useState(0)
	const [replaceDotsWithRange, setReplaceDotsWithRange] = useState(false)
	const size = useWindowSize()
	const sliderRef = useRef<Slider | null>(null)

	useEffect(() => {
		setReplaceDotsWithRange(size.isMobile || children.length > 8)
	}, [size, children.length])

	const settings = {
		dots: !replaceDotsWithRange,
		speed: 500,
		infinite: true,
		slidesToShow: 5,
		adaptiveHeight: false,
		dotsClass: 'slick-dots',
		beforeChange: (current: number, next: number) => {
			const isNextButton =
				next - current === 1 ||
				next - current === -(children.length - 1)
			const isPreviousButton =
				next - current === -1 || next - current === children.length - 1
			if (isNextButton) {
				pushToDataLayer({
					event: 'carousel_click',
					carousel_name: children[(current + 1) % children.length]
						?.props.title
						? children[(current + 1) % children.length]?.props.title
						: sliderNavText,
					carousel_action: 'Click Right',
				})
			} else if (isPreviousButton) {
				pushToDataLayer({
					event: 'carousel_click',
					carousel_name: children[
						(current - 1 + children.length) % children.length
					]?.props.title
						? children[
								(current - 1 + children.length) %
									children.length
							]?.props.title
						: sliderNavText,
					carousel_action: 'Click Left',
				})
			}
			setSlideIndex(next)
		},
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 4,
				},
			},
			{
				breakpoint: 500,
				settings: {
					slidesToShow: 2,
				},
			},
		],
	}

	return (
		<div className={className}>
			<div className="h-36 bg-elanco-blue p-5 text-white">
				<h1 className="text-center text-2xl font-bold leading-[1.28]">
					{title}
				</h1>
				<p className="text-center">{subtitle}</p>
			</div>

			<div
				className={clsx(
					'card-carousel relative -top-16 mx-auto',
					replaceDotsWithRange ? 'custom-nav' : null
				)}
			>
				<Slider className="flex-slider" ref={sliderRef} {...settings}>
					{children.map((child) => (
						<div className="h-full w-32 p-2" key={child.key}>
							{cloneElement(child, {
								className: clsx(
									child.props.className,
									'h-full'
								),
							})}
						</div>
					))}
				</Slider>
				{replaceDotsWithRange ? (
					<div className="range-bar">
						<input
							max={children.length - 1}
							min={0}
							onChange={(e) =>
								sliderRef.current?.slickGoTo(
									Number(e.target.value)
								)
							}
							title={sliderNavText}
							type="range"
							value={slideIndex}
						/>
						<span>
							{slideIndex + 1} {ofLabel} {children.length}
						</span>
					</div>
				) : null}
			</div>
		</div>
	)
}
