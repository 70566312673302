import type {JSX} from 'react'

interface Tag {
	codename: string
	name: string
}

interface MetaTagsProps {
	className?: string
	headerText?: string
	tags: (Tag | string)[]
	noBackground?: boolean
	noBorder?: boolean
	as?: 'a' | 'button' | 'p'
	titleAboveTags?: boolean
	titleBorder?: boolean
	variant?: string
	tagUrlPrefix?: string
}

export const MetaTags = ({
	className = '',
	headerText = '',
	tags = [],
	noBackground = false,
	noBorder = false,
	as: Tag = 'p',
	titleAboveTags = false,
	titleBorder = false,
	variant = '',
	tagUrlPrefix = '',
}: MetaTagsProps): JSX.Element => {
	let titleModifier = ''
	if (titleAboveTags) {
		titleModifier = titleBorder ? 'pb-4 mb-4 border-b' : 'mb-4'
	} else {
		titleModifier = titleBorder ? 'pt-4 mt-4 border-t' : 'pb-2 mt-3'
	}

	const toSeoUrl = (url: string): string => {
		const urlString = url
		return urlString
			.toString() // Convert to string
			.normalize('NFD') // Change diacritics
			.replace(/_/g, '-') // Change underscore to dashes
			.replace(/&/g, '-and-') // Replace ampersand
			.replace(/[^a-z0-9-]/g, '') // Remove anything that is not a letter, number or dash
			.replace(/-+/g, '-') // Remove duplicate dashes
			.replace(/^-*/, '') // Remove starting dashes
			.replace(/-*$/, '') // Remove trailing dashes
	}

	const isArrayOfStrings: boolean = tags.every(
		(tag) => typeof tag === 'string'
	)

	return (
		<div
			className={`${className} ${
				titleAboveTags ? ' flex flex-col-reverse' : ''
			}`}
		>
			<div className="-mx-1 flex flex-wrap">
				{tags.map((tag, i) => {
					const tagName = isArrayOfStrings ? tag : (tag as Tag).name
					const tagCodename = isArrayOfStrings
						? tag
						: (tag as Tag).codename

					return (
						<Tag
							className={`m-1 ${
								noBackground
									? ''
									: `border-2 px-2 ${
											variant
												? `text-${variant} border-${variant}`
												: 'border-primary text-primary'
										} rounded-md font-semibold`
							} ${noBorder ? 'border-none pl-0 underline' : ''}`}
							href={(() => {
								if (Tag === 'a') {
									return tagUrlPrefix.length
										? `/${tagUrlPrefix}/${toSeoUrl(
												tagCodename as string
											)}`
										: undefined
								}
								return undefined
							})()}
							key={
								typeof tagCodename === 'string'
									? tagCodename
									: (tag as Tag).codename
							}
						>
							{typeof tagName === 'string' ? tagName : ''}
							{noBackground && i < tags.length - 1 ? ',' : null}
						</Tag>
					)
				})}
			</div>
			{headerText ? (
				<h1 className={titleModifier}>{headerText}</h1>
			) : null}
		</div>
	)
}
