import type {CampaignData} from '@elanco/gateway-utils'
import type {Dispatch, SetStateAction} from 'react'
import type {ValidationEvent} from '@/_new-code/products/campaign-forms/cdc-screen-set-wrapper/models'
import http from '@/utils/axios'

export class CampaignInterestHandler {
	selectedOptionsCallbacks: (() => Record<string, string[]> | null)[] = []

	injectedElementCallbacks: (() => string[])[] = []

	errorsCallbacks: (() => boolean)[] = []

	selectedOptions: Record<string, string[]>[] = []

	injectedElements: string[] = []

	validationEvents: Dispatch<SetStateAction<ValidationEvent>>[] = []

	registerQuestion(
		selectedOptionCallback: () => Record<string, string[]> | null,
		injectedElementCallback: () => string[],
		errorsCallback: () => boolean,
		validate: Dispatch<SetStateAction<ValidationEvent>>
	): void {
		this.selectedOptionsCallbacks.push(selectedOptionCallback)
		this.injectedElementCallbacks.push(injectedElementCallback)
		this.errorsCallbacks.push(errorsCallback)
		this.validationEvents.push(validate)
	}

	validate(): void {
		const event: ValidationEvent = {validateNow: true}
		this.validationEvents.forEach((validationEvent) => {
			validationEvent(event)
		})
	}

	getSelectedOptions(): Record<string, string[]>[] {
		this.selectedOptions = []
		this.selectedOptionsCallbacks.forEach((callback) => {
			const selection = callback()
			if (selection) {
				this.selectedOptions.push(selection)
			}
		})

		return this.selectedOptions
	}

	getInjectedElements(): string[] {
		this.injectedElements = []
		this.injectedElementCallbacks.forEach((callback) => {
			this.injectedElements = [...this.injectedElements, ...callback()]
		})

		return this.injectedElements
	}

	isValid(): boolean {
		return this.errorsCallbacks.every((hasErrors) => !hasErrors())
	}

	async send(data: CampaignData, userId: string | null): Promise<boolean> {
		const campaignData: Record<string, string[]> = {}
		const hasInjectedQuestions = this.getInjectedElements().length > 0
		if (hasInjectedQuestions) {
			this.getSelectedOptions().forEach((selection) => {
				const {id: _id, ...interests} = selection
				for (const [key, value] of Object.entries(interests)) {
					campaignData[key] = value
				}
			})
		} else {
			campaignData['N/A'] = ['N/A']
		}

		const requestBody: CampaignData = {
			ContactId: userId ?? data.ContactId,
			campaignId: data.campaignId,
			medium: data.medium,
			source: data.source,
			typeOfCampaign: 'B2B',
			data: campaignData,
		}

		if (requestBody.ContactId && requestBody.campaignId) {
			const campaignURL = '/api/campaign/interest'
			try {
				const response = await http.post(campaignURL, requestBody, {
					timeout: 20000,
				})
				return response.status === 200
			} catch (error) {
				return false
			}
		}
		return false
	}
}
