import type {JSX, MouseEventHandler, ReactElement, ReactNode} from 'react'
import React, {createRef, Children, cloneElement} from 'react'
import styles from './breadcrumbs.module.css'

interface BreadcrumbsProps {
	children: ReactElement<{children: ReactNode}>[]
	className?: string
}

export const Breadcrumbs = ({
	children,
	className = '',
}: BreadcrumbsProps): JSX.Element => {
	const $el = createRef<HTMLDivElement>()
	let isDown = false
	let startX: number
	let scrollLeft: number
	const onMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
		if (!$el.current) return

		isDown = true
		startX = e.pageX - $el.current.offsetLeft
		scrollLeft = $el.current.scrollLeft
	}
	const onMouseLeave: MouseEventHandler<HTMLDivElement> = () => {
		isDown = false
	}

	const onMouseUp: MouseEventHandler<HTMLDivElement> = () => {
		isDown = false
	}
	const onLinkClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
		if ($el.current && startX !== e.pageX - $el.current.offsetLeft) {
			e.preventDefault()
			return false
		}

		return true
	}

	const onMouseMove: MouseEventHandler<HTMLDivElement> = (e) => {
		if (!isDown || !$el.current) return
		e.preventDefault()
		const x = e.pageX - $el.current.offsetLeft
		const walk = (x - startX) * 3 // scroll-fast
		$el.current.scrollLeft = scrollLeft - walk
	}

	const childrenArr = Children.map(children, (link, index) => {
		const newLink = cloneElement(
			link as ReactElement<{children: ReactNode}>,
			{
				children: <span itemProp="title">{link.props.children}</span>,
				key: link.key,
				itemProp: 'url',
				'aria-current': children.length
					? index === children.length - 1
					: true,
				className: 'mr-2',
				onClick: onLinkClick,
			} as Partial<React.HTMLAttributes<HTMLElement>>
		)
		return (
			<li
				className={`whitespace-nowrap ${styles.crumb} fist:hidden shrink-0 self-center text-xs before:mr-2 before:inline-block before:h-[9px] before:w-[5px] before:align-middle before:content-[""] last:pr-4 [&>a]:hover:text-primary-dark`}
				key={`${newLink.key}l`}
			>
				{newLink}
			</li>
		)
	})
	return (
		<nav
			aria-label="Breadcrumb"
			className={`${className} ${styles.wrapper} relative after:absolute after:right-0 after:top-0 after:block after:h-full after:w-10 after:content-[""]`}
		>
			<div
				className="no-scrollbar flex h-8 flex-nowrap overflow-x-auto"
				onMouseDown={onMouseDown}
				onMouseLeave={onMouseLeave}
				onMouseMove={onMouseMove}
				onMouseUp={onMouseUp}
				ref={$el}
				role="presentation"
			>
				<ol className="flex items-center">{childrenArr}</ol>
			</div>
		</nav>
	)
}
