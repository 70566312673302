import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import type {ReactElement} from 'react'
import {useEffect, useState, useRef} from 'react'
import Slider from 'react-slick'
import Image from 'next/image'
import type {VideoBlockContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/videos/video-block'
import type {WistiaContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/videos/wistia'
import type {ImageContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/image'
import type {Block, Tersed} from '@/_new-code/services/kontent-ai/types'
import styles from './InteractiveList.module.css'
import './SlickSlider.module.css'

type InteractiveListItemContentItem = IContentItem<{
	itemTitle: Elements.TextElement
	imageOverlay: Elements.MultipleChoiceElement
	videoImage: Elements.LinkedItemsElement<
		VideoBlockContentItem | WistiaContentItem | ImageContentItem
	>
}>

export type InteractiveListContentItem = IContentItem<{
	title: Elements.TextElement
	headingDescription: Elements.TextElement
	footerDescription: Elements.TextElement
	interactiveListItems: Elements.LinkedItemsElement<InteractiveListItemContentItem>
}>

interface ListItem {
	item_title: string
	videoComponent?: ReactElement
	imageUrl?: string
	altText?: string | null | undefined
	height?: number
	width?: number
	overLay?: boolean
}

export const InteractiveListBlock: Block<InteractiveListContentItem> = ({
	block,
	BlockMapper,
	...context
}) => {
	const getMediaCard = (
		item: Tersed<VideoBlockContentItem> | Tersed<WistiaContentItem>
	): ReactElement => {
		return (
			<div>
				<BlockMapper
					blocks={[item]}
					className="*:w-full"
					{...context}
				/>
			</div>
		)
	}

	const list = block.elements.interactiveListItems.map((item) => {
		if (
			item.elements.videoImage[0] &&
			(item.elements.videoImage[0]?.system.type === 'video_block' ||
				item.elements.videoImage[0]?.system.type === 'wistia')
		) {
			return {
				item_title: item.elements.itemTitle,
				videoComponent: getMediaCard(
					item.elements.videoImage[0] as
						| Tersed<VideoBlockContentItem>
						| Tersed<WistiaContentItem>
				),
			}
		}

		const videoImage = item.elements.videoImage[0] as
			| Tersed<ImageContentItem>
			| undefined

		return {
			imageUrl: videoImage?.elements.image[0]?.url,
			altText: videoImage?.elements.image[0]?.description,
			height: 334,
			width: 600,
			item_title: item.elements.itemTitle,
			overLay: item.elements.imageOverlay[0]?.codename === 'yes',
		}
	})

	const [nav1, setNav1] = useState<Slider | null>(null)
	const [nav2, setNav2] = useState<Slider | null>(null)
	const slider1 = useRef<Slider>(null)
	const slider2 = useRef<Slider>(null)

	useEffect(() => {
		if (slider1.current && slider2.current) {
			setNav1(slider1.current)
			setNav2(slider2.current)
		}
	}, [])

	const childLength: number = list.length

	const {headingDescription, footerDescription, title} = block.elements

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
	}

	const navSettings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToScroll: 1,
		vertical: true,
		autoHeight: true,
	}

	return (
		<div className="pt-4 md:pb-8 md:pt-8 lg:pt-16">
			<div
				className="container-wide"
				data-kontent-element-codename="interactive_list"
			>
				<div className="py-8">
					{title ? <h2>{title}</h2> : null}
					{headingDescription ? <p>{headingDescription}</p> : null}
					<div className="my-6 md:flex">
						<div className="md:w-1/2 md:pr-6">
							<div className="md:mr-2">
								<Slider
									asNavFor={nav1 || undefined}
									className={`mb-4 bg-gray-100 md:mb-[88px] ${styles.NavCarousel}`}
									ref={slider2}
									{...navSettings}
									focusOnSelect
									slidesToShow={childLength}
									swipeToSlide
								>
									{list.map((x: ListItem) => {
										return (
											<div key={x.item_title}>
												<button type="button">
													{x.item_title}
												</button>
											</div>
										)
									})}
								</Slider>
							</div>
						</div>
						<div className="relative md:w-1/2">
							<Slider
								className={`${styles.MainSlider}`}
								{...settings}
								asNavFor={nav2 || undefined}
								ref={slider1}
							>
								{list.map((x: ListItem) => {
									return (
										<div
											className="relative"
											key={x.item_title}
										>
											{x.videoComponent ? (
												x.videoComponent
											) : (
												<Image
													alt={x.altText ?? ''}
													height={x.height}
													src={x.imageUrl ?? ''}
													width={x.width}
												/>
											)}
											{!x.videoComponent && x.overLay ? (
												<div className="absolute inset-x-0 bottom-0 mx-auto mb-2 w-11/12 rounded bg-black bg-opacity-[0.34] py-4 text-center text-white backdrop-blur-[21.6703px]">
													{x.item_title}
												</div>
											) : null}
										</div>
									)
								})}
							</Slider>
						</div>
					</div>
					{footerDescription ? <p>{footerDescription}</p> : null}
				</div>
			</div>
		</div>
	)
}
