/** This list is currently still in the Design System but will be migrated over */
const backgroundThemesRaw = {
	none: '',
	black: 'bg-black text-white fill-white text-white-links',
	midnight_blue: 'bg-midnight-blue text-white fill-white text-white-links',
	salmonella_360: 'bg-salmonella_360 text-black fill-black text-black-links',
	zenrelia_primary:
		'bg-zenrelia_primary text-white fill-white text-white-links',
	zenrelia_secondary:
		'bg-zenrelia_secondary text-white fill-white text-white-links',
	zenrelia_contrast:
		'bg-zenrelia_contrast text-white fill-white text-white-links',
	zenrelia_accent:
		'bg-zenrelia_accent text-black fill-black text-black-links',
	zenrelia_sku1: 'bg-zenrelia_sku1 text-white fill-white text-white-links',
	zenrelia_sku2: 'bg-zenrelia_sku2 text-white fill-white text-white-links',
	zenrelia_sku3: 'bg-zenrelia_sku3 text-white fill-white text-white-links',
	zenrelia_sku4: 'bg-zenrelia_sku4 text-black fill-black text-black-links',
	claro_neptra: 'bg-claro_neptra text-white fill-white text-white-links',
	advocate_orange:
		'bg-advocate_orange text-black fill-black text-black-links',
	elanco_blue: 'bg-elanco-blue text-white fill-white text-white-links',
	elanco_beige: 'bg-elanco-beige text-black fill-black text-black-links',
	pet_green: 'bg-pet-green text-white fill-white text-white-links',
	vet_purple: 'bg-vet-purple text-white fill-white text-white-links',
	white: 'bg-white text-black fill-black text-black-links',
	elanco_green: 'bg-elanco-green text-black fill-black text-black-links',
	light_blue: 'bg-light-blue text-black fill-black text-black-links',
	light_gray: 'bg-light_gray text-black fill-black text-black-links',
	mid_beige: 'bg-mid-beige text-black fill-black text-black-links',
	pet_green_light:
		'bg-pet-green-light text-black fill-black text-black-links',
	purple_light: 'bg-purple-light text-black fill-black text-black-links',
	kexxtone: 'bg-kexxtone text-black fill-black text-black-links',
	zolvix_orange: 'bg-zolvix_orange text-black fill-black text-black-links',
	bexacat_orange: 'bg-bexacat_orange text-black fill-black text-black-links',
	parvo: 'bg-parvo text-white fill-white text-white-links',
	droncit_red: 'bg-droncit_red text-white fill-white text-white-links',
	varenzin_red: 'bg-varenzin_red text-white fill-white text-white-links',
	bexacat: 'bg-bexacat text-black fill-black text-black-links',
	adtab_yellow: 'bg-adtab_yellow text-black fill-black text-black-links',
	adtab_black: 'bg-adtab_black text-white fill-white text-white-links',
	adtab_white: 'bg-adtab_white text-black fill-black text-black-links',
	gallipro_fit_yellow:
		'bg-gallipro_fit_yellow text-black fill-black text-black-links',
	gallipro_fit_orange:
		'bg-gallipro_fit_orange text-black fill-black text-black-links',
	gallipro_fit_green:
		'bg-gallipro_fit_green text-black fill-black text-black-links',
	gallipro_fit_darkblue:
		'bg-gallipro_fit_darkblue text-white fill-white text-white-links',
	neptra_green: 'bg-neptra_green text-black fill-black text-black-links',
	seresto: 'bg-seresto text-white fill-white text-white-links',
	clik_light_blue:
		'bg-clik_light_blue text-white fill-white text-white-links',
	clik_dark_blue: 'bg-clik_dark_blue text-white fill-white text-white-links',
	clik_yellow: 'bg-clik_yellow text-black fill-black text-black-links',
	tracer: 'bg-tracer text-white fill-white text-white-links',
	bovaer: 'bg-bovaer text-white fill-white text-white-links',
	pradalex: 'bg-pradalex text-white fill-white text-white-links',
	avipro_grey: 'bg-avipro_grey text-white fill-white text-white-links',
	avipro_red: 'bg-avipro_red text-white fill-white text-white-links',
	aviblue_light_blue:
		'bg-aviblue_light_blue text-white fill-white text-white-links',
	aviblue_mid_blue:
		'bg-aviblue_mid_blue text-white fill-white text-white-links',
	aviblue_dark_blue:
		'bg-aviblue_dark_blue text-white fill-white text-white-links',
	coliprotec_teal:
		'bg-coliprotec_teal text-white fill-white text-white-links',
	flubenvet: 'bg-flubenvet text-white fill-white text-white-links',
	hemicell_red: 'bg-hemicell_red text-white fill-white text-white-links',
	htsi: 'bg-htsi text-white fill-white text-white-links',
	maxiban_: 'bg-maxiban_ text-white fill-white text-white-links',
	galliprant_yellow:
		'bg-galliprant_yellow text-black fill-black text-black-links',
	onsior: 'bg-onsior text-white fill-white text-white-links',
	onsior_cool_gray_10:
		'bg-onsior_cool_gray_10 text-white fill-white text-white-links',
	onsior_tangerine:
		'bg-onsior_tangerine text-white fill-white text-white-links',
	onsior_dark_gray:
		'bg-onsior_dark_gray text-white fill-white text-white-links',
	onsior_cool_gray_6c:
		'bg-onsior_cool_gray_6c text-white fill-white text-white-links',
	onsior_dark_red:
		'bg-onsior_dark_red text-white fill-white text-white-links',
	onsior_vivid_orange:
		'bg-onsior_vivid_orange text-white fill-white text-white-links',
	onsior_dark_violet:
		'bg-onsior_dark_violet text-white fill-white text-white-links',
	onsior_lime_green:
		'bg-onsior_lime_green text-white fill-white text-white-links',
	onsior_dark_blue:
		'bg-onsior_dark_blue text-white fill-white text-white-links',
	onsior_rhodamine_red:
		'bg-onsior_rhodamine_red text-white fill-white text-white-links',
	onsior_advantix_light:
		'bg-onsior_advantix_light text-white fill-white text-white-links',
	agita_10wg: 'bg-agita_10wg text-white fill-white text-white-links',
	baseline: 'bg-baseline text-white fill-white text-white-links',
	baytril_100: 'bg-baytril_100 text-white fill-white text-white-links',
	evosure: 'bg-evosure text-white fill-white text-white-links',
	feedaid: 'bg-feedaid text-white fill-white text-white-links',
	full_value_pork_primary_blue:
		'bg-full_value_pork_primary_blue text-white fill-white text-white-links',
	full_value_pork_secondary_blue:
		'bg-full_value_pork_secondary_blue text-white fill-white text-white-links',
	lipinate: 'bg-lipinate text-white fill-white text-white-links',
	pili_shield_plus_c:
		'bg-pili_shield_plus_c text-white fill-white text-white-links',
	proteck: 'bg-proteck text-white fill-white text-white-links',
	rhini_shield: 'bg-rhini_shield text-white fill-white text-white-links',
	swine_awake: 'bg-swine_awake text-white fill-white text-white-links',
	tempo_one_percent_dust:
		'bg-tempo_one_percent_dust text-white fill-white text-white-links',
	credelio_quattro_guard_dog:
		'bg-credelio_quattro_guard_dog text-white fill-white text-white-links',
	credelio_quattro_hunter_green:
		'bg-credelio_quattro_hunter_green text-white fill-white text-white-links',
	credelio_quattro_grass_green:
		'bg-credelio_quattro_grass_green text-white fill-white text-white-links',
	credelio_quattro_tennis_ball_green:
		'bg-credelio_quattro_tennis_ball_green text-white fill-white text-white-links',
	credelio_quattro_park_green:
		'bg-credelio_quattro_park_green text-black fill-black text-black-links',
	credelio_quattro_milk_bone:
		'bg-credelio_quattro_milk_bone text-black fill-black text-black-links',
	credelio_quattro_golden:
		'bg-credelio_quattro_golden text-white fill-white text-white-links',
	credelio_quattro_frisbee:
		'bg-credelio_quattro_frisbee text-white fill-white text-white-links',
	credelio_quattro_sunny_day:
		'bg-credelio_quattro_sunny_day text-white fill-white text-white-links',
	credelio_quattro_sky_blue:
		'bg-credelio_quattro_sky_blue text-white fill-white text-white-links',
	pet_protect_red: 'bg-pet_protect_red text-white text-white-links',
	pet_protect_green: 'bg-pet_protect_green text-white text-white-links',
	pet_protect_blue: 'bg-pet_protect_blue text-white text-white-links',
	pet_protect_skin: 'bg-pet_protect_skin text-white text-white-links',
	pet_protect_calm: 'bg-pet_protect_calm text-white text-white-links',
	pet_protect_allergy: 'bg-pet_protect_allergy text-white text-white-links',
	civ: 'bg-civ text-white text-white-links',
	elmaro_navy_blue: 'bg-elmaro_navy_blue text-white text-white-links',
	elmaro_pink: 'bg-elmaro_pink text-white text-white-links',
	elmaro_yellow: 'bg-elmaro_yellow text-white text-white-links',
	galliprant_red: 'bg-galliprant_red text-white text-white-links',
	bexacat_dark_grey: 'bg-bexacat_dark_grey text-white text-white-links',
	varenzin_blue: 'bg-varenzin_blue text-white text-white-links',
	varenzin_purple: 'bg-varenzin_purple text-white text-white-links',
}

export const backgroundThemes = new Map(Object.entries(backgroundThemesRaw))
