import type {ReactNode, JSX} from 'react'
import {Children} from 'react'

interface IconsSectionProps {
	title?: string
	body?: ReactNode
	children?: ReactNode
	className?: string
}

export const IconsSection = ({
	title = '',
	body = null,
	children = null,
	className = '',
}: IconsSectionProps): JSX.Element => {
	return (
		<div className={`flex flex-col md:flex ${className}`}>
			<div className="content text-center">
				{title ? <h2>{title}</h2> : null}
				{body ? <p>{body}</p> : null}
			</div>
			{children}
		</div>
	)
}

interface IconsProps {
	children: ReactNode
	col?: 1 | 2 | 3 | 4
}

const Icons = ({children = null, col = 1}: IconsProps): JSX.Element => {
	const columns = {
		1: 'sm:w-full',
		2: 'sm:w-1/2',
		3: 'sm:w-1/2 md:w-1/3',
		4: 'sm:w-1/2 lg:w-1/4',
	}

	const childrenArr = Children.toArray(children) as JSX.Element[]
	const gridCols = columns[col] || columns[1]

	return (
		<ul className="mt-6 overflow-hidden sm:flex sm:flex-wrap sm:justify-around">
			{childrenArr.map((icon, index) => (
				<li
					className={`my-4 px-2 text-center lg:my-0 ${gridCols}`}
					key={icon.key || index}
				>
					{icon}
				</li>
			))}
		</ul>
	)
}
IconsSection.Icons = Icons
