import Head from 'next/head'
import {useRouter} from 'next/router'
import type {JSX, ReactElement} from 'react'
import parse from 'html-react-parser'
import type {
	GlobalConfigContentItem,
	AdvancedPageTemplateContentItem,
	Tersed,
} from '@/_new-code/services/kontent-ai/types'
import {getCanonicalUrl, validHrefLangs} from '@/utils/helpers'

export const PageSEO = ({
	globalConfig,
	page,
	isOnTaxonomyTermPage,
	reqUrl,
	reqHost,
}: {
	globalConfig: Tersed<GlobalConfigContentItem>
	page: Tersed<AdvancedPageTemplateContentItem>
	isOnTaxonomyTermPage: boolean
	reqUrl: string | undefined
	reqHost: string | undefined
}): JSX.Element => {
	const {locale = '', asPath} = useRouter()

	const robotsIndex =
		page.elements.snippetPageSeoExternalNoindex[0]?.codename === 'yes'
			? 'noindex'
			: 'index,max-image-preview:large,max-snippet:-1,max-video-preview:-1'
	const robotsFollow =
		page.elements.snippetPageSeoPageNofollow[0]?.codename === 'yes'
			? 'nofollow'
			: 'follow'

	const pageTitle = (() => {
		const title = page.elements.pageText || page.elements.pageTitle

		if (title && globalConfig.elements.siteName) {
			return `${title} | ${globalConfig.elements.siteName}`
		}

		if (globalConfig.elements.siteName) {
			return `${title}${globalConfig.elements.siteName}`
		}

		return title
	})()
	const baseUrl = `https://${globalConfig.elements.siteHostname}/${locale}`
	const pageURL = `${baseUrl}${
		page.elements.pageUrl === '/' ? '' : page.elements.pageUrl
	}`

	const image = page.elements.snippetPageSeoPageImage[0]?.url
	const contentType = page.system.type
	const hrefLangs =
		validHrefLangs(page.elements.snippetPageSeoPageHreflangs) &&
		(parse(page.elements.snippetPageSeoPageHreflangs) as ReactElement[])

	let canonicalUrl
	if (locale === 'global') {
		if (typeof window !== 'undefined') {
			canonicalUrl = window.location.href
		} else {
			const currentUrl = `https://${reqHost}${reqUrl}`
			canonicalUrl = currentUrl
		}
	} else {
		canonicalUrl = getCanonicalUrl(
			globalConfig.elements.siteHostname,
			locale,
			asPath,
			page.elements.snippetPageSeoPageCanonicalUrl
		)
	}

	return (
		<Head>
			<link href="//fonts.googleapis.com" rel="dns-prefetch" />
			<link href="//googletagmanager.com" rel="dns-prefetch" />
			<link href="//consent.trustarc.com" rel="dns-prefetch" />
			<link href="//maps.googleapis.com" rel="dns-prefetch" />
			<link href="//google-analytics.com" rel="dns-prefetch" />
			<link href="//connect.facebook.net" rel="dns-prefetch" />
			<link href="//facebook.com" rel="dns-prefetch" />
			<link href="//cdn.taboola.com" rel="dns-prefetch" />
			<link href="//cdns.gigya.com" rel="dns-prefetch" />
			<link href="//cdns.eu1.gigya.com" rel="dns-prefetch" />
			<link href="//accounts.eu1.gigya.com" rel="dns-prefetch" />
			<link href="//googleads.g.doubleclick.net" rel="dns-prefetch" />
			<link href="//assets.elanco.com" rel="dns-prefetch" />
			{!isOnTaxonomyTermPage && (
				<link href={canonicalUrl} rel="canonical" />
			)}
			{isOnTaxonomyTermPage ? (
				<meta content="noindex,nofollow" name="robots" />
			) : (
				<meta
					content={[robotsIndex, robotsFollow].join(',')}
					name="robots"
				/>
			)}
			<title>{pageTitle}</title>
			<meta content={pageTitle} property="og:title" />
			<meta content={pageURL} property="og:url" />
			{page.elements.snippetPageSeoPageDescription ? (
				<>
					<meta
						content={page.elements.snippetPageSeoPageDescription}
						name="description"
					/>
					<meta
						content={page.elements.snippetPageSeoPageDescription}
						property="og:description"
					/>
				</>
			) : null}
			{image ? <meta content={image} name="image" /> : null}
			{contentType ? (
				<meta content={contentType} property="og:type" />
			) : null}
			{image ? <meta content={image} property="og:image" /> : null}
			{hrefLangs}
		</Head>
	)
}
